import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { 
    clearCreateClubSubscriptionSuccess, clearUpdateClubSubscriptionSuccess, updateClubSubscriptionFailure, updateClubSubscriptionLoading, updateClubSubscriptionSuccess, getClubSubscriptionsFailure, getClubSubscriptionsLoading, getClubSubscriptionsSuccess, postCreateClubSubscriptionFailure, postCreateClubSubscriptionLoading, postCreateClubSubscriptionSuccess 
} from "../action";
import { UPDATE_CLUB_SUBSCRIPTION_REQUEST, GET_CLUB_SUBSCRIPTION_REQUEST, POST_CREATE_CLUB_SUBSCRIPTION_REQUEST } from "../types";

const ajaxDBCalls = {
    postCreateBootcamp: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `clubsubscription/create`,
            data: payload
        })

        return response
    },
    getClubSubscriptions: async () => {
        const reponse = await axios({
            method: "GET",
            url: "clubsubscription/"
        })
        return reponse
    },
    updateClubSubscription: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `clubsubscription/${payload.clubSubscriptionId}`,
            data: payload.subscriptionData
        })

        return response
    },
}

// WORKERS
// create club subscription
function* createClubSubscription(payload) {
    try {
        yield put(postCreateClubSubscriptionLoading(true));
        const response = yield call(ajaxDBCalls.postCreateBootcamp, payload);
        yield put(postCreateClubSubscriptionSuccess(response.data));
        yield put(postCreateClubSubscriptionLoading(false));
        yield delay();
        yield put(clearCreateClubSubscriptionSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(postCreateClubSubscriptionFailure(errorMessage));
        yield put(postCreateClubSubscriptionLoading(false));
        yield delay();
        yield put(postCreateClubSubscriptionFailure(""));
    }
}

// get club subscriptions
function* getClubSubscriptions() {
    try {
        yield put(getClubSubscriptionsLoading(true));

        const response = yield call(ajaxDBCalls.getClubSubscriptions)

        yield put(getClubSubscriptionsSuccess(response.data))
        yield put(getClubSubscriptionsLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getClubSubscriptionsFailure(errorMessage))
        yield put(getClubSubscriptionsLoading(false))
        yield delay();
        yield put(getClubSubscriptionsFailure(""))
    }
}

// delete club subscription
function* updateClubSubscription(payload) {
    try {
        yield put(updateClubSubscriptionLoading(true));
        const response = yield call(ajaxDBCalls.updateClubSubscription, payload);
        yield put(updateClubSubscriptionSuccess(response.data));
        yield put(updateClubSubscriptionLoading(false));
        yield delay();
        yield put(clearUpdateClubSubscriptionSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(updateClubSubscriptionFailure(errorMessage));
        yield put(updateClubSubscriptionLoading(false));
        yield delay();
        yield put(updateClubSubscriptionFailure(""));
    }
}

// WATCHERS
function* createClubSubscriptionWatcher() {
    yield takeLatest(POST_CREATE_CLUB_SUBSCRIPTION_REQUEST, createClubSubscription)
}
function* getClubSubscriptionsWatcher() {
    yield takeLatest(GET_CLUB_SUBSCRIPTION_REQUEST, getClubSubscriptions)
}
function* deleteClubSubscriptionWatcher() {
    yield takeLatest(UPDATE_CLUB_SUBSCRIPTION_REQUEST, updateClubSubscription)
}

// events saga
export default function* clubSubscriptionsSaga() {
    yield spawn(createClubSubscriptionWatcher);
    yield spawn(getClubSubscriptionsWatcher);
    yield spawn(deleteClubSubscriptionWatcher);
}