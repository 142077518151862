import { BOOK_SPACE_FAIL, BOOK_SPACE_LOADING, BOOK_SPACE_REQUEST, BOOK_SPACE_SUCCESS, CLEAR_BOOK_SPACE_SUCCESS, CLEAR_CREATE_SPACE_SUCCESS, CLEAR_UNBOOK_SPACE_SUCCESS, CLEAR_UPDATE_SPACE_SUCCESS, CREATE_SPACE_FAIL, CREATE_SPACE_LOADING, CREATE_SPACE_REQUEST, CREATE_SPACE_SUCCESS, GET_SINGLE_SPACE_FAIL, GET_SINGLE_SPACE_LOADING, GET_SINGLE_SPACE_REQUEST, GET_SINGLE_SPACE_SUCCESS, GET_SPACES_FAIL, GET_SPACES_LOADING, GET_SPACES_REQUEST, GET_SPACES_SUCCESS, UNBOOK_SPACE_FAIL, UNBOOK_SPACE_LOADING, UNBOOK_SPACE_REQUEST, UNBOOK_SPACE_SUCCESS, UPDATE_SPACE_FAIL, UPDATE_SPACE_LOADING, UPDATE_SPACE_REQUEST, UPDATE_SPACE_SUCCESS } from "../types";

// get spaces
export const getSpacesRequest = () => {
    return {
        type: GET_SPACES_REQUEST
    }
}

export const getSpacesSuccess = (prop) => {
    return {
        type: GET_SPACES_SUCCESS,
        payload: prop,
    };
};
  
export const getSpacesFailure = (errors) => {
    return {
        type: GET_SPACES_FAIL,
        payload: { errors },
    };
};

export const getSpacesLoading = (loading) => {
    return {
        type: GET_SPACES_LOADING,
        payload: loading,
    };
};

// GET SINGLE SPACE
export const getSingleSpaceFailure = (errors) => {
    return {
        type: GET_SINGLE_SPACE_FAIL,
        payload: { errors }
    }
}

export const getSingleSpaceLoading = (loading) => {
    return {
        type: GET_SINGLE_SPACE_LOADING,
        payload: loading
    }
}

export const getSingleSpaceRequest = (prop) => {
    return {
        type: GET_SINGLE_SPACE_REQUEST,
        payload: prop
    }
}

export const getSingleSpaceSuccess = (prop) => {
    return {
        type: GET_SINGLE_SPACE_SUCCESS,
        payload: prop
    }
}

// UPDATE SPACE
export const updateSpaceRequest = (prop) => {
    return {
        type: UPDATE_SPACE_REQUEST,
        payload: prop
    }
}

export const updateSpaceLoading = (loading) => {
    return {
        type: UPDATE_SPACE_LOADING,
        payload: loading
    }
}

export const updateSpaceSuccess = (prop) => {
    return {
        type: UPDATE_SPACE_SUCCESS,
        payload: prop
    }
}


export const updateSpaceFailure = (errors) => {
    return {
        type: UPDATE_SPACE_FAIL,
        payload: { errors }
    }
}

export const clearUpdateSpaceSuccess = () => {
    return {
        type: CLEAR_UPDATE_SPACE_SUCCESS
    }
}

// CREATE SPACE
export const createSpaceRequest = (prop) => {
    return {
        type: CREATE_SPACE_REQUEST,
        payload: prop
    }
}

export const createSpaceLoading = (loading) => {
    return {
        type: CREATE_SPACE_LOADING,
        payload: loading
    }
}

export const createSpaceSuccess = (prop) => {
    return {
        type: CREATE_SPACE_SUCCESS,
        payload: prop
    }
}


export const createSpaceFailure = (errors) => {
    return {
        type: CREATE_SPACE_FAIL,
        payload: { errors }
    }
}

export const clearCreateSpaceSuccess = () => {
    return {
        type: CLEAR_CREATE_SPACE_SUCCESS
    }
}

// BOOK SPACE
export const bookSpaceRequest = (prop) => {
    return {
        type: BOOK_SPACE_REQUEST,
        payload: prop
    }
}

export const bookSpaceLoading = (loading) => {
    return {
        type: BOOK_SPACE_LOADING,
        payload: loading
    }
}

export const bookSpaceSuccess = (prop) => {
    return {
        type: BOOK_SPACE_SUCCESS,
        payload: prop
    }
}


export const bookSpaceFailure = (errors) => {
    return {
        type: BOOK_SPACE_FAIL,
        payload: { errors }
    }
}

export const clearBookSpaceSuccess = () => {
    return {
        type: CLEAR_BOOK_SPACE_SUCCESS
    }
}

// UNBOOK SPACE
export const unbookSpaceRequest = (prop) => {
    return {
        type: UNBOOK_SPACE_REQUEST,
        payload: prop
    }
}

export const unbookSpaceLoading = (loading) => {
    return {
        type: UNBOOK_SPACE_LOADING,
        payload: loading
    }
}

export const unbookSpaceSuccess = (prop) => {
    return {
        type: UNBOOK_SPACE_SUCCESS,
        payload: prop
    }
}


export const unbookSpaceFailure = (errors) => {
    return {
        type: UNBOOK_SPACE_FAIL,
        payload: { errors }
    }
}

export const clearUnbookSpaceSuccess = () => {
    return {
        type: CLEAR_UNBOOK_SPACE_SUCCESS
    }
}