import React from 'react'
import { useEffect } from 'react';
import { AiOutlinePlusCircle } from "react-icons/ai"
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyDataState from '../../Global/EmptyDataState';
import LoadingCircles from '../../Global/LoadingCircles';
import ClubPackageCard from './ClubPackageCard';

const AvailableClubMemberships = ({clubs}) => {

    // OBJECT INITIALIZATION
    // history object
    const history = useHistory()


    // STATES
    const { getClubsLoading } = useSelector(state=>{
        const { getClubsLoading } = state.loadingIndicator
        return {
            getClubsLoading
        }
    })

    return (
        <div className='flex flex-col gap-5'>

            <div className='flex items-center justify-between'>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    Available Club memberships
                </p>

                <button 
                onClick={()=>history.push("/dashboard/club-subscription/create/new-package")}
                className='font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2'>
                    <AiOutlinePlusCircle size={24} />
                    <span>Add new Package</span>
                </button>
            </div>

            <div className='bg-white rounded-[10px] p-5'>

                {
                    getClubsLoading?
                    <LoadingCircles />:
                    clubs && clubs.length!==0?
                    <div className="mt-4 grid grid-cols-3 gap-9 py-4 lg:grid-cols-[40%_40%] container justify-center mx-auto"> 

                        {
                            clubs.map((club)=>(
                                <ClubPackageCard
                                key={club._id}
                                path={club._id}
                                packageName={club.name}
                                packagePrice={club.amount}
                                business_consultancy={club.business_consultancy}
                                discounted_services={club.discounted_services}
                                foreign_tech_consultancy={club.foreign_tech_consultancy}
                                investment_database={club.investment_database}
                                investment_pitch={club.investment_pitch}
                                life_insurance={club.life_insurance}
                                member_swag={club.member_swag}
                                networking_event={club.networking_event}
                                news_letter={club.news_letter}
                                reserved_office_space={club.reserved_office_space} /> 
                            ))
                        }   
                    </div>:
                    <EmptyDataState />
                }
            </div>
        </div>
    )
}

export default AvailableClubMemberships