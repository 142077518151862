import { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useLocation } from 'react-router-dom'
import ProfileAvatar from '../../global/ProfileAvatar'
import CourseOutline from './CourseOutline'
import GeneralInformation from './GeneralInformation'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleCoursesRequest, putEditSingleCourseRequest } from '../../../../redux/action'
import LoadingCircles from '../../../Global/LoadingCircles'
import EmptyDataState from '../../../Global/EmptyDataState'
import BtnSm from '../../../Global/BtnSm'
import { toast } from 'react-toastify'

const BodySection = () => {

    // OBJECT INITIALIZATION
    const subTitles = ["front-end", "back-end"]
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    const courseId = location.pathname.split("/").pop();

    // STATES
    // single course state
    const { singleCourse, getSingleCourseLoading, putEditSingleCourseSuccess, putEditSingleCourseError, putEditSingleCourseLoading } = useSelector(state=>{
        const { getSingleCourseLoading, putEditSingleCourseLoading } = state.loadingIndicator
        const { singleCourse } = state.coursesReducer
        const {
            success: { putEditSingleCourse: putEditSingleCourseSuccess },
            errors: { putEditSingleCourse: putEditSingleCourseError },
        } = state.ajaxStatuses;
        return {
            getSingleCourseLoading,
            singleCourse,
            putEditSingleCourseSuccess,
            putEditSingleCourseError,
            putEditSingleCourseLoading
        }
    })
    // course details state
    const [courseDetails, setCourseDetails] = useState({})

    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleCoursesRequest(courseId))
    }, [])
    useEffect(()=>{

        // setCourseDetails(singleCourse)
        setCourseDetails({
            name: singleCourse.name,
            description: singleCourse.description,
            topics: singleCourse.topics,
            expected_outcome: singleCourse.expected_outcome,
            bonus_content: singleCourse.bonus_content
        })
    }, [singleCourse])

    useEffect(() => {
        if (putEditSingleCourseError) {
          toast.error(putEditSingleCourseError, {
            duration: 3000,
          });
        }
    }, [putEditSingleCourseError]);
    
    useEffect(() => {
        if (putEditSingleCourseSuccess) {
          toast.success(putEditSingleCourseSuccess, {
            duration: 3000,
          });
          history.replace(`/dashboard/course/${courseId}`)
        }
    }, [putEditSingleCourseSuccess]);
    

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-start'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Courses
                    </p>

                    <p 
                    onClick={()=>history.goBack()}
                    className='flex items-center justify-start gap-3 cursor-pointer
                    '>
                        <IoIosArrowBack 
                        className="text-[#8B54F7]"
                        size={18} />
                        <span className='font-normal text-base text-gray-500'>
                            Back
                        </span>
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>
                
                {/* container */}
                <div className='rounded-[10px] bg-white flex flex-col gap-16 py-9 px-6 pc:min-h-[80vh]'>

                    {
                        getSingleCourseLoading?
                        <LoadingCircles />:
                        Object.keys(singleCourse).length === 0?
                        <EmptyDataState />:
                        <>

                            <div className='flex flex-col gap-1'>
                                <p className='text-gray-700 font-semibold text-4xl'>
                                    Web App Development
                                </p>

                                <p className='text-gray-500 text-2xl font-semibold'>
                                    Front-End
                                </p>
                            </div>

                            <GeneralInformation
                            setCourseDetails={setCourseDetails}
                            courseDetails={courseDetails} />

                            <CourseOutline
                            setCourseDetails={setCourseDetails}
                            courseDetails={courseDetails} />

                            <div className='w-[40%] mx-auto flex flex-col'>
                                <BtnSm
                                loading={putEditSingleCourseLoading}
                                onClick={()=>{
                                    const expected_outcome = [...courseDetails.expected_outcome].map(({_id, ...rest})=>rest)
                                    const topics = [...courseDetails.topics].map(({_id, ...rest})=>rest)
                                    dispatch(putEditSingleCourseRequest({
                                        courseData: {
                                            ...courseDetails,
                                            expected_outcome,
                                            topics
                                        },
                                        courseId: courseId
                                    }))
                                }} 
                                title={"Submit"} />
                            </div>
                        </>
                    }

                </div>
            </section>
        </section>
    )
}

export default BodySection