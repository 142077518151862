import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import student_image_icon from "../../../assets/admindashboard/home/images/bootcamp-icon.png"
import { getBootcampsAdminRequest } from '../../../redux/action'
import EmptyDataState from '../../Global/EmptyDataState'
import LoadingCircles from '../../Global/LoadingCircles'
import TrackCards from './TrackCards'

const StudentDetailedInfo = () => {

        
    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()


    // STATES
    const { 
        bootcamps,
        getBootcampsAdminLoading
    } = useSelector(state=>{
        const { getBootcampsAdminLoading } = state.loadingIndicator
        const { bootcamps } = state.bootcampsReducer
        return {
            bootcamps,
            getBootcampsAdminLoading
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getBootcampsAdminRequest())
    }, [])

    return (
        <div>
            <div className='bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] flex flex-col px-7 pb-6 gap-8'>
                <div className='flex gap-4 py-7 pb-6 border-b border-[#E1E6EF]'>
                    <img src={student_image_icon} className="w-[36px] h-[36px]" alt="" />
                    <p className='font-semibold text-xl text-gray-600 opacity-[0.65]'>
                        Students
                    </p>
                </div>

                {
                    getBootcampsAdminLoading?
                    <LoadingCircles />:
                    bootcamps && bootcamps.length!==0?

                        //optics/information 
                        <div className='grid grid-cols-2 gap-6'>

                           { 
                                bootcamps.map((bootcamp, index)=>(
                                <TrackCards
                                key={bootcamp._id}
                                trackId={bootcamp.course_type === "regular"?1:2}
                                trackTitle={bootcamp.course_type === "regular"?"Regular":"Special"}
                                bootcampNumber={index}
                                bootcampTitle={bootcamp.name}
                                numberOfStudents={bootcamp.students.length} />
                                ))
                            }
                         </div>:
                    <div className='w-full flex items-center justify-center h-[30vh]'>
                        <EmptyDataState />
                    </div>
                }
            </div>
        </div>
    )
}

export default StudentDetailedInfo