import { useEffect, useState } from 'react'
import { BiUserCircle } from 'react-icons/bi'
import { realDateFormat } from '../../../helpers'
import BtnSm from '../../Global/BtnSm'
import { FormError } from '../../Global/Error'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import "../global/styles/custom-select-styling.css"
import AddUserBootcamp from './AddUserBootcamp'
import AddUserClubSub from './AddUserClubSub'
import AddUserEvent from './AddUserEvent'
import MyModal from '../../../layouts/MyModal'
import DeletePopup2 from '../global/DeletePopup2'
import { toast } from 'react-toastify'
import { clearDeleteUserSuccess, deleteUserRequest } from '../../../redux/action'

const UserInfoCard = ({userDetails, formik, updateUserLoading, userEmail, userId}) => {

    // OBJECT INITIALIZATION
    const dispatch = useDispatch()
    const history = useHistory()


    // STATES
    const [deleteModalState, setDeleteModalState] = useState(false)
     // single bootcamp state
    const { 
        deleteUserLoading,
        deleteUserSuccess,
        deleteUserError 
    } = useSelector(state=>{
        const { 
            deleteUserLoading
        } = state.loadingIndicator
        const {
            success: { 
                deleteUser: deleteUserSuccess
            },
            errors: { 
                deleteUser: deleteUserError
            },
        } = state.ajaxStatuses;
        return {
            deleteUserLoading,
            deleteUserError,
            deleteUserSuccess,
        }
    })


    // HANDLERS
    const toggleDeleteModalState = () => {
        setDeleteModalState(!deleteModalState)
    }
    const handleDeleteUser = () => {
        dispatch(deleteUserRequest({
            user_id: userId
        }))
    }


    // SIDE EFFECTS
    // listen for error messages
    useEffect(() => {
        if (deleteUserError) {
            toast.error(deleteUserError, {
                duration: 3000,
            });
        }
    }, [deleteUserError]);

    // listen for success message
    useEffect(() => {
        if (deleteUserSuccess) {
            setDeleteModalState(false)
            dispatch(clearDeleteUserSuccess())
            toast.success(deleteUserSuccess, {
                duration: 3000,
            });
            history.replace("/dashboard/users")
        }
    }, [deleteUserSuccess]);
    
    return (
        <>

            {/* avatar */}
            <div className='flex justify-start items-end gap-4'>
                {/* <img src={users_avatar} className="w-[140px] h-[140px]" alt="" /> */}
                <BiUserCircle size={130} />

                <p className='flex flex-col gap-1'>
                    <span className='font-semibold text-xl text-[#344054]'>
                        {`${userDetails.firstname} ${userDetails.lastname}`}
                    </span>
                    <span className='font-normal text-base text-[#667085]'>
                        {userDetails.email}
                    </span>
                </p>

                <p className='flex flex-col items-end gap-1 ml-auto'>
                    <span className='font-semibold text-sm text-[#1D2433A6]'>
                        Date created
                    </span>
                    <span className='font-normal text-sm text-[#1D2433A6]'>
                        {realDateFormat(userDetails.createdAt)}
                    </span>
                </p>
            </div>

            {/* forms */}
            <div className='flex gap-7 w-full'>

                {/* Personal Information Form */}
                <div className='flex flex-col gap-8 w-full'>

                    {/* first form */}
                    <form
                    className='flex flex-col gap-5 rounded-[10px] bg-[#F8F9FC] p-6 w-full'
                    onSubmit={(e)=>e.preventDefault()}>

                        {/* form caption */}
                        <p className='font-semibold text-xl text-[#3F444D]'>
                            Personal Information
                        </p>

                        {/* first name input container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    First name
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            {
                                ...formik.getFieldProps("firstname")
                            }
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                            placeholder='First name' />
                            {
                                formik.touched.firstname && formik.errors.firstname && <FormError message={formik.errors.firstname} />
                            }
                        </label>

                        {/* last name input container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Last name
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            {
                                ...formik.getFieldProps("lastname")
                            }
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                            placeholder='Last name' />
                            {
                                formik.touched.lastname && formik.errors.lastname && <FormError message={formik.errors.lastname} />
                            }
                        </label>

                        {/* phone number container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Phone number
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            {
                                ...formik.getFieldProps("mobile")
                            }
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                            placeholder='Mobile' />
                            {
                                formik.touched.mobile && formik.errors.mobile && <FormError message={formik.errors.mobile} />
                            }
                        </label>

                        {/* date of birth input container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Date of birth
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="date" 
                            {
                                ...formik.getFieldProps("date_of_birth")
                            }
                            className='w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                            placeholder='Date of Birth' />
                            {
                                formik.touched.date_of_birth && formik.errors.date_of_birth && <FormError message={formik.errors.date_of_birth} />
                            }
                        </label>

                        {/* occupation input container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Occupation
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            {
                                ...formik.getFieldProps("occupation")
                            }
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                            placeholder='Occupation' />
                            {
                                formik.touched.occupation && formik.errors.occupation && <FormError message={formik.errors.occupation} />
                            }
                        </label>

                        {/* reason input container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Reason
                                </span>
                            </div>

                            {/* input field */}
                            <textarea 
                            {
                                ...formik.getFieldProps("reason")
                            }
                            className='border border-[#d0d5dd] text-base font-normal h-[132px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2' 
                            placeholder='Reason' />
                            {
                                formik.touched.reason && formik.errors.reason && <FormError message={formik.errors.reason} />
                            }
                        </label>

                        {/* save changes button */}
                        <div className='flex w-full justify-end'>
                            <BtnSm 
                            loading={updateUserLoading}
                            onClick={formik.handleSubmit}
                            title={"Save user details"} />
                        </div>
                    </form>

                    <p 
                    onClick={toggleDeleteModalState}
                    className='cursor-pointer hover:opacity-70 font-normal text-base text-[#8B54F7] mt-1'>
                        Delete account permanently
                    </p>
                </div>

                {/* Other Information */}
                <div
                className='flex flex-col gap-7 w-full h-fit'>
                    <AddUserBootcamp
                    userEmail={userEmail} />

                    <AddUserClubSub
                    userId={userId} />

                    <AddUserEvent
                    userEmail={userEmail} />                  
                </div>
            </div>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
                <DeletePopup2
                onClick={handleDeleteUser}
                loadinState={deleteUserLoading}
                closeModal={toggleDeleteModalState} />
            </MyModal>
        </>
    )
}

export default UserInfoCard