import React from "react";
import { useHistory } from "react-router-dom";
import image from "../../../../assets/admindashboard/bootcamp/images/full-detail-image.png";
import BtnSm from "../../../Global/BtnSm";

const EditCourseJumbotron = ({
  title,
  subTitle,
  description,
  courseId,
  courseThumbnail,
}) => {
  // history object
  const history = useHistory();

  return (
    <div className="flex justify-between items-center">
      {/* text section */}
      <div className="w-[40%] flex flex-col gap-1">
        <p className="text-gray-700 font-semibold text-4xl">
          {title}
        </p>

        <p className="text-gray-500 font-semibold text-2xl">{subTitle}</p>

        <p className="text-gray-500 font-normal text-base">
          {description}
        </p>

        <div className="w-full flex flex-col items-stretch mt-4">
          <BtnSm
            onClick={() =>
              history.push(`/dashboard/course/edit/${courseId}`)
            }
            title={"Edit Course"}
          />
        </div>
      </div>

      {/* image section */}
      <div className="w-[40%]">
        <img src={image} className="w-full h-full object-cover" alt="" />
      </div>
    </div>
  );
};

export default EditCourseJumbotron;
