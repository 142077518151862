import React, { useEffect } from 'react'
import EventCard from './EventCard'
import { getRTDate, MONTHS, DAY } from "../../../helpers";
import { IoIosArrowForward } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEventsAdminRequest } from '../../../redux/action'
import { Skeleton } from '@mui/material'
import EmptyDataState from '../../Global/EmptyDataState'

const LatestEvents = () => {
    
    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()


    // STATES
    const { 
        events,
        getEventsLoading
    } = useSelector(state=>{
        const { getEventsLoading } = state.loadingIndicator
        const { events } = state.eventsReducer
        return {
            events,
            getEventsLoading
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getEventsAdminRequest())
    }, [])

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex items-center justify-between'>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    Latest Event
                </p>

                <p 
                onClick={()=>history.push("/dashboard/events")}
                className='flex gap-4 text-[#8B54F7] font-normal text-base items-center justify-start cursor-pointer'>
                    <span>View all</span>
                    <IoIosArrowForward size={18} />
                </p>
            </div>
            {
                getEventsLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "50vh",
                borderRadius: "10px",
                }}
                />:
                events && events.length!==0?
                <div className='grid grid-cols-3 gap-5'>
                    {
                        events.slice(0, 3).map(event=>(
                        <EventCard
                        to={event._id}
                        image={event.imgurl}
                        key={event._id}
                        venue={event.location}
                        title={event.name}
                        desc={event.description.substring(0,80).concat("...") || event.description}
                        month={MONTHS[getRTDate.month(event.date)]}
                        time={event.time}
                        day={getRTDate.date(event.date)}
                        weekday={DAY[getRTDate.dayNum(event.date)]} />
                        ))
                    }
                    </div>:
                    <div className='w-full flex items-center justify-center h-[40vh] border-2 border-gray-300 rounded-[10px]'>
                        <EmptyDataState />
                    </div>
            }
        </div>
    )
}

export default LatestEvents