import { Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EmptyDataState from "../../Global/EmptyDataState";
import EventCard from "./EventCard";
import { getRTDate, MONTHS, DAY } from "../../../helpers";

const AllUpcomingEvents = ({events}) => {

  // OBJECT INITIALIZATION
  // history object
  const history = useHistory()


  // STATES
  const { getEventsLoading } = useSelector(state=>{
      const { getEventsLoading } = state.loadingIndicator
      return {
          getEventsLoading
      }
  })

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-xl text-[#3F444D]">
          All upcoming events
        </p>

        <button
          onClick={() => history.push("/dashboard/events/create/new-event")}
          className="font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2"
        >
          <AiOutlinePlusCircle size={24} />
          <span>Add new events</span>
        </button>
      </div>

      {
        getEventsLoading?
        <Skeleton
        variant="rectangular"
        sx={{
          width: "100%",
          height: "50vh",
          borderRadius: "10px",
        }}
        />:
          events && events.length!==0?
            <div className="grid grid-cols-3 gap-9 py-4 lg:grid-cols-[40%_40%] container justify-center mx-auto">
              {
                events.map(event=>(
                <EventCard
                to={event._id}
                image={event.imgurl}
                key={event._id}
                venue={event.location}
                title={event.name}
                desc={event.description.substring(0,80).concat("...") || event.description}
                month={MONTHS[getRTDate.month(event.date)]}
                time={event.time}
                day={getRTDate.date(event.date)}
                weekday={DAY[getRTDate.dayNum(event.date)]} />
                ))
              }
            </div>:
        <EmptyDataState />
      }
    </div>
  );
};

export default AllUpcomingEvents;
