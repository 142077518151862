import { useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import CourseCard from "./CourseCard";
import image1 from "../../../assets/admindashboard/bootcamp/images/course-card-image.png";
import image2 from "../../../assets/admindashboard/bootcamp/images/course-card-image2.png";
import image3 from "../../../assets/admindashboard/bootcamp/images/course-card-image3.png";
import { useSelector } from "react-redux";
import EmptyDataState from "../../Global/EmptyDataState";
import LoadingCircles from "../../Global/LoadingCircles";

const AvailbleCourses = ({courses}) => {
  const subjects1 = ["HTML", "ReactJS", "CSS", "Tailwind  CSS", "Javascript"];
  const subjects2 = ["NODE. JS with (Mongo DB)"];
  const subjects3 = ["React Native"];

  const subTitles = ["front-end", "back-end"]

    
  const { getCoursesLoading } = useSelector(state=>{
    const { getCoursesLoading } = state.loadingIndicator
    return {
        getCoursesLoading
    }
})

  // history object
  const history = useHistory();
  
  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-xl text-[#3F444D]">
          Available Courses
        </p>

        <button
          onClick={() => history.push("/dashboard/course/create/new-course")}
          className="font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2"
        >
          <AiOutlinePlusCircle size={24} />
          <span>Add new course</span>
        </button>
      </div>

      <div className="bg-white rounded-[10px] p-5">
          
            {

                getCoursesLoading?
                <LoadingCircles />:
                courses.length===0?
                  <EmptyDataState />:
                    <div className="mt-4 grid grid-cols-3 gap-9 py-4 lg:grid-cols-[40%_40%] container justify-center mx-auto">
                      {
                        courses.map((course, index)=>(
                            <CourseCard
                            key={course._id}
                            courseImage={image1}
                            courseTitle={course.name?.split("(")[0] || course.name}
                            courseSubtitle={course.description.includes(" ")?
                              subTitles.includes(course.description.split(" ")[0]?.toLowerCase())? `(${course.description?.split(" ")[0]})`:course.description?.toLowerCase()?.includes("mobile")?"":`(${course.name?.split("(")[1]?.replace(")", "")})`:course.description}
                            subjects={course.topics}
                            fullDetailLink={course._id}
                          />
                        ))
                      }
                  </div>                
            }
      </div>
    </div>
  );
};

export default AvailbleCourses;
