import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { postCreateCourseRequest } from '../../../../redux/action'
import BtnSm from '../../../Global/BtnSm'
import ProfileAvatar from '../../global/ProfileAvatar'
import CourseOutline from './CourseOutline'
import GeneralInformation from './GeneralInformation'

const BodySection = () => {
    
    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()

    // STATES
    // empty data field state
    const [nothingToSubmit, setNothingToSubmit] = useState(true)
    // single course state
    const {
        postCreateCourseSuccess,
        postCreateCourseError,
        postCreateCourseLoading
    } = useSelector(state=>{
        const { postCreateCourseLoading } = state.loadingIndicator
        const {
            success: { postCreateCourse: postCreateCourseSuccess },
            errors: { postCreateCourse: postCreateCourseError },
        } = state.ajaxStatuses;
        return {
            postCreateCourseSuccess,
            postCreateCourseError,
            postCreateCourseLoading
        }
    })
    // course details state
    const [courseDetails, setCourseDetails] = useState({})


    // HANDLERS
    const handleSubmit = () => {

        // removing id
        const expected_outcome = [...courseDetails?.expected_outcome]?.map(({_id, ...rest})=>rest)

        const topics = [...courseDetails?.topics]?.map(({_id, ...rest})=>rest)

        const finalObj = {
            ...courseDetails,
            expected_outcome,
            topics
        }

        // dispatch function
        dispatch(postCreateCourseRequest({
            courseData: finalObj
        }))

        console.log(finalObj)
    }


    // SIDE EFFECTS
    useEffect(()=> {
        if(courseDetails && Object.keys(courseDetails).length>=4) {
            setNothingToSubmit(false)
        } else {
            setNothingToSubmit(true)
        }
    }, [courseDetails])

    useEffect(() => {
        if (postCreateCourseError) {
          toast.error(postCreateCourseError, {
            duration: 3000,
          });
        }
    }, [postCreateCourseError]);
    
    useEffect(() => {
        if (postCreateCourseSuccess) {
          toast.success(postCreateCourseSuccess, {
            duration: 3000,
          });
          history.push("/dashboard/course")
        }
    }, [postCreateCourseSuccess]);

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-start'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Courses
                    </p>

                    <p 
                    onClick={()=>history.goBack()}
                    className='flex items-center justify-start gap-3 cursor-pointer
                    '>
                        <IoIosArrowBack 
                        className="text-[#8B54F7]"
                        size={18} />
                        <span className='font-normal text-base text-gray-500'>
                            Back
                        </span>
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>
                
                {/* container */}
                <div className='rounded-[10px] bg-white flex flex-col gap-16 py-9 px-6'>

                    <GeneralInformation
                    setCourseDetails={setCourseDetails}
                    courseDetails={courseDetails} />

                    <CourseOutline
                    setCourseDetails={setCourseDetails}
                    courseDetails={courseDetails} />

                    <div className='w-[40%] mx-auto flex flex-col'>
                        <BtnSm
                        disabled={nothingToSubmit}
                        loading={postCreateCourseLoading}
                        onClick={handleSubmit} />
                    </div>

                </div>
            </section>
        </section>
    )
}

export default BodySection