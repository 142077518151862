import React, { useEffect, useState } from 'react'
import BtnSm from '../../Global/BtnSm'
import Select from 'react-select';
import "../global/styles/custom-select-styling.css"
import { getClubsAdminRequest, postCreateClubSubscriptionRequest } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

const AddUserClubSub = ({userId}) => {

    // OBJECTS INITIALIZATION
    const trackData = [
        { value: 'regular', label: 'Regular' },
        { value: 'pathway', label: 'Special' },
    ];
    // history object
    const history = useHistory();
    // dispatch object
    const dispatch = useDispatch()
    // club subscription data
    const [clubSubData, setClubSubData] = useState([])
    const [clubSubValue, setclubSubValue] = useState("")

    // STATES
    // redux states
    const { 
        getClubsLoading,
        clubs,
        postCreateClubSubscriptionSuccess,
        postCreateClubSubscriptionError,
        postCreateClubSubscriptionLoading
     } = useSelector(state=>{
        const { 
            getClubsLoading,
            postCreateClubSubscriptionLoading
        } = state.loadingIndicator
        const {
            success: { postCreateClubSubscription: postCreateClubSubscriptionSuccess },
            errors: { postCreateClubSubscription: postCreateClubSubscriptionError},
        } = state.ajaxStatuses
        const { clubs } = state.clubsReducer
        return {
            getClubsLoading,
            clubs,
            postCreateClubSubscriptionSuccess,
            postCreateClubSubscriptionError,
            postCreateClubSubscriptionLoading
        }
    })


    // HANDLERS
    const handleSubmit = (e) => {
        e.preventDefault()
        const clubPayload = {
            user_id: userId,
            club_id: clubSubValue
        }
        dispatch(postCreateClubSubscriptionRequest(clubPayload))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getClubsAdminRequest())
    }, [])
    useEffect(()=>{
        setClubSubData(clubs.map(({_id,name})=>({value:_id, label:name})))
    }, [clubs])

    // checking for error and success messages
    useEffect(() => {
        if (postCreateClubSubscriptionError) {
            toast.error(postCreateClubSubscriptionError, {
            duration: 3000,
            });
        }
    }, [postCreateClubSubscriptionError]);
    
    useEffect(() => {
        if (postCreateClubSubscriptionSuccess) {
            toast.success(postCreateClubSubscriptionSuccess, {
            duration: 3000,
            });
            history.replace("/dashboard/users")
        }
    }, [postCreateClubSubscriptionSuccess]);

    return (
        <form
        className='flex flex-col gap-5 rounded-[10px] bg-[#F8F9FC] p-6 w-full h-fit'
        onSubmit={handleSubmit}>

            {
                getClubsLoading?
                <div
                    style={{
                    width: "100%",
                    }}
                    className="p-6 flex gap-5 flex-col h-full justify-center items-center m-auto"
                    >
                    {" "}
                    <CircularProgress
                    size={35} 
                    style={{ color: "#7F56D9" }} />
                    {" "}
                </div>
                :
                (clubs.length === 0)?
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex items-center justify-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            Current club subscription
                        </span>
                    </div>
                    <Select
                    isDisabled={true}
                    className='custom-select-container'
                    placeholder="No data fetched, kindly refresh"
                    options={trackData} />
                </label>:
                <>
                    

                    {/* Current club subscription input container */}
                    <label className='flex flex-col gap-2'>

                        {/* label text */}
                        <div className='flex items-center justify-start'>
                            <span className='font-semibold text-sm text-[#344054]'>
                                Current club subscription
                            </span>
                        </div>

                        {/* input field */}
                        <Select
                        className='custom-select-container'
                        placeholder="Change user's club subscription"
                        value={clubSubData.filter((data)=>data.value===clubSubValue)}
                        onChange={(obj)=>setclubSubValue(obj.value)}
                        options={clubSubData} />
                    </label>

                    {/* save changes button */}
                    <div className='flex w-full justify-end'>
                        <BtnSm 
                        loading={postCreateClubSubscriptionLoading}
                        disabled={!clubSubValue?true:false}
                        title={"Save Changes"} />
                    </div>
                </>
            }
        </form>
    )
}

export default AddUserClubSub