import { combineReducers } from "redux";
import userData from "./auth";
import { persistReducer } from "redux-persist";
import loadingIndicator from "./loadingIndicator";
import ajaxStatuses from "./ajaxStatuses";
import storage from "redux-persist/lib/storage";
import usersReducer from "./users";
import bootcampsReducer from "./bootcamps";
import coursesReducer from "./courses";
import eventsReducer from "./events";
import clubsReducer from "./clubs";
import clubSubscriptionReducer from "./clubSubscriptions";
import spacesReducer from "./spaces";
import bootcampStudentsReducer from "./bootcampStudents";
import critiqueEventReducer from "./critiqueEvent";
import designBootcampReducer from "./designBootcamp";

const authPersistConfig = {
  key: "userData",
  storage,
  whitelist: ["isLoggedIn", "token", "user"],
};

export default combineReducers({
  userData: persistReducer(authPersistConfig, userData),
  loadingIndicator,
  ajaxStatuses,
  usersReducer,
  bootcampsReducer,
  coursesReducer,
  eventsReducer,
  clubsReducer,
  clubSubscriptionReducer,
  spacesReducer,
  bootcampStudentsReducer,
  critiqueEventReducer,
  designBootcampReducer
});
