import { GET_BOOTCAMPS_ADMIN_SUCCESS, GET_SINGLE_BOOTCAMPS_SUCCESS } from "../types"

const initialState = {
    bootcamps: [],
    singleBootcamp: {}
}

const bootcampsReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_BOOTCAMPS_ADMIN_SUCCESS:
      return { 
          ...state, 
          bootcamps: payload.bootcamp
      }

    case GET_SINGLE_BOOTCAMPS_SUCCESS:
      return { 
          ...state,
          singleBootcamp: payload.bootcamp
      }

  default:
    return state
  }
}

export default bootcampsReducer