import React from 'react'

const AvailabilityLabel = ({id}) => {
    return (
    <div className={`h-[32px] w-[77px] rounded-[500px] ${id===1?"bg-[#8B54F7] text-[#F8F5FF]":id===2?"bg-[#08875D] text-[#EDFDF8]":"Null"} text-xs font-semibold flex items-center justify-center`}>
            {id===1?"BOOKED":id===2?"AVAILABLE":"Null"}
        </div>
    )
}

export default AvailabilityLabel