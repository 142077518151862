import React from 'react'
import ProfileAvatar from '../global/ProfileAvatar'
import BtnSm from '../../Global/BtnSm'

const MailBody = () => {
    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        New Mail
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>

                {/* Form */}
                <div className='flex flex-col w-full p-5 rounded-[10px] bg-white'>

                    <form className="flex flex-col gap-5 w-full">

                        {/* to */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    To
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                            placeholder='Enter receipients email address here' />

                            {/* added description */}
                            <span className='font-normal text-sm text-[#667085]'>
                                To add more addresses, use a comma to seperate them.
                            </span>
                        </label>

                        {/* CC */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    CC
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' />
                        </label>

                        {/* BCC */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    BCC
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' />
                        </label>

                        {/* Subject */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Subject
                                </span>
                            </div>

                            {/* input field */}
                            <input 
                            type="text" 
                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                            placeholder='Type your email tittle here' />
                        </label>

                        {/* Main message */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-[#344054]'>
                                    Main message
                                </span>
                            </div>

                            {/* input field */}
                            <textarea
                            className='border border-[#d0d5dd] text-base font-normal h-[255px] resize-none py-3 rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                            placeholder='Type your message here' />
                        </label>

                        <div className='flex'>
                            <BtnSm
                            title="Send Mail" />
                        </div>
                        
                    </form>

                </div>

            </section>
        </section>
    )
}

export default MailBody