import React from 'react'
import EmptyDataState from '../../Global/EmptyDataState';

const ParticipantsTable = ({participantsList, searchedValue, isCritique}) => {

    // HANDLERS
    const filteredData = participantsList?.filter((participants) => {
        
        //if no input the return the original
        if (searchedValue === "") {
            return participants;
        }
    
        //return the item which contains the participants input
        else {
            return `${participants.firstname.toLowerCase()} ${participants.lastname.toLowerCase()}`?.includes(
                searchedValue.toLowerCase()
            );
        }
    });

    return (
        <div className="bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] px-8 pb-12 flex flex-col gap-12">

            {/* table head */}
            <div className="border-b border-[#E1E6EF] text-sm font-semibold text-[#1D2433A6] grid grid-cols-[27%_27%_21%_15%_10%] py-7 gap-x-3">
                <span>Name</span>
                <span>Email address</span>
                <span>Phone number</span>
                <span>
                    {
                        isCritique?
                        "Profession":"Session"
                    }
                </span>
            </div>

            {/* table body */}
            <div className="flex flex-col gap-12">
                {participantsList && participantsList?.length !== 0 ? (
                <>
                    {filteredData?.map((participants, index) => (
                    <div
                        key={participants?._id}
                        className="grid grid-cols-[27%_27%_21%_15%_10%] gap-x-3 text-[#1D2433A6] text-sm font-normal items-center"
                    >
                        {/* name */}
                        <div className="flex gap-3 items-center">
                        <span className="font-semibold text-sm">{index + 1}.</span>
                        <span>{`${participants?.firstname} ${participants?.lastname}`}</span>
                        </div>

                        {/* email address */}
                        <span>{participants?.email}</span>

                        {/* phone number */}
                        <span>{participants?.mobile}</span>

                        {/* session */}
                        
                        <span>
                            {
                                isCritique?
                                participants?.profession:
                                participants?.session || "Afternoon"
                            }
                        </span>
                    </div>
                    ))}
                </>
                ) : (
                <div className="w-full h-[354px] flex items-center justify-center">
                    <EmptyDataState />
                </div>
                )}
            </div>
        </div>
    )
}

export default ParticipantsTable