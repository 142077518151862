import React from 'react'
import SideNav from '../../../layouts/AdminDashboard/SideNav'
import BootcampsBody from '../../../components/AdminDashboard/Bootcamps/BootcampsBody'

const Bootcamps = () => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>

            {/* Side nav */}
            <SideNav />

            {/* Bootcamp Body */}
            <BootcampsBody />
        </div>
    )
}

export default Bootcamps