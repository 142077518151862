import React, { useState } from "react";
import sideNavLogo from "./../../assets/admindashboard/global/svg/aimove-logo.svg";
import ActiveLink from "../../components/AdminDashboard/SideNav/ActiveLink";
import { BiHomeAlt } from "react-icons/bi";
import { FiBookOpen, FiServer, FiUsers, FiSettings, FiCalendar, FiMail } from "react-icons/fi";
import { FaChalkboardTeacher, FaUsers } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import MobileNavBar from "./MobileNavBar";
import NavBtn from "../../components/Global/NavBtn";
import MyModal from "./../../layouts/MyModal";
import LogoutView from "../../components/AdminDashboard/SideNav/LogoutView";
import { useHistory } from "react-router-dom";
import { MdOutlineSimCard } from "react-icons/md";
import {FaRegAddressBook} from "react-icons/fa"

const SideNav = () => {
  // history object
  const history = useHistory();

  const [isNavOpen, setNavState] = useState(false);

  const transformMobile = !isNavOpen
    ? "mobile:translate-x-[-500rem]"
    : "mobile:translate-x-[0px]";

  // logout modal state
  const [logoutModalState, setLogoutModalState] = useState(false);

  const toggleLogoutModal = () => {
    setLogoutModalState(!logoutModalState);
  };

  return (
    <>
      {/* mobile nav bar */}
      <MobileNavBar toggleNav={() => setNavState(!isNavOpen)} />

      {/* sidenav drawer */}
      <div
      style={{
        transition: "transform 1.2s ease-in-out",
        transitionFunction: "cubic-bezier(.22,.68,0,1.71)",
      }}
      className={`bg-[#5221B5] fixed h-screen w-[20vw] mobile:z-[1000] mobile:min-h-screen mobile:w-full px-6 mobile:px-7 py-8 flex flex-col gap-10 mobile:gap-[4.1rem] ${transformMobile} top-[0] left-[0]`}>

        {/* logo */}
        <div className="container mx-auto flex justify-between items-center">
            <img src={sideNavLogo} className="mobile:w-[137px]" alt="" />

            {/* close btn mobile */}
            <NavBtn onClick={() => setNavState(!isNavOpen)}>
                <VscChromeClose color="#fff" size={32} />
            </NavBtn>
        </div>

        {/* sidenav items */}
        <ul className="container mx-auto flex flex-col gap-2 mobile:gap-4">
            <li>
                <ActiveLink to={"/dashboard/home"}>
                    <BiHomeAlt size={22} />
                    Home
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/users"}>
                    <FiUsers size={18} />
                    Users
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/bootcamps"}>
                    <FiServer size={18} />
                    Bootcamps
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/course"}>
                    <FiBookOpen size={18} />
                    Courses
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/club-members"}>
                    <FaUsers size={18} />
                    Club members
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/club-subscription"}>
                    <MdOutlineSimCard size={18} />
                    Club Subscription
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/events"}>
                    <FiCalendar size={18} />
                    Events
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/space-booking"}>
                    <FaChalkboardTeacher size={18} />
                    Space Booking
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/mail"}>
                    <FiMail size={18} />
                    Mails
                </ActiveLink>
            </li>

            <li>
                <ActiveLink to={"/dashboard/settings"}>
                    <FiSettings size={18} />
                    Settings
                </ActiveLink>
            </li>

            <li>
                <span
                onClick={toggleLogoutModal}
                className="text-white font-semibold mobile:font-normal mobile:text-base text-base lg:text-xs xl:text-xs flex justify-start items-center transition-all hover:text-[#5221B5] hover:bg-[#f9f5ff75] rounded-[6px] h-[40px] gap-[8px] mobile:gap-3 px-4 hover:no-underline"
                to={"/login"}
                >
                    <HiOutlineLogout size={18} />
                    Logout
                </span>
            </li>
        </ul>
      </div>

      {/* logout modal */}
      <MyModal
        modalIsOpen={logoutModalState}
        toggleModal={toggleLogoutModal}
        disableBtn={true}
      >
        <LogoutView closeModal={toggleLogoutModal} />
      </MyModal>
    </>
  );
};

export default SideNav;
