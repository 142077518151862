import React from 'react'

const OpticsCard = ({totalMembers, availableSubscription, currentlySubscribed}) => {
    return (
        <div className='bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] p-6 grid grid-cols-3 gap-x-9 w-full'>

            {/* number of students */}
            <div className='flex flex-col border-r border-[#E1E6EF]'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Total number of club members
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {totalMembers.toLocaleString('en-US')}
                </p>
            </div>

            {/* availble bootcamps */}
            <div className='flex flex-col border-r border-[#E1E6EF]'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Available subscription
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {availableSubscription.toLocaleString('en-US')}
                </p>
            </div>

            {/* availble bootcamps */}
            <div className='flex flex-col'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Users currently subscribed
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {currentlySubscribed.toLocaleString('en-US')}
                </p>
            </div>
        </div>
    )
}

export default OpticsCard