import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import MyModal from '../../../../layouts/MyModal'
import { deleteClubRequest, getSingleClubRequest } from '../../../../redux/action'
import BackBtn from '../../../Global/BackBtn'
import BtnSm from '../../../Global/BtnSm'
import EmptyDataState from '../../../Global/EmptyDataState'
import LoadingCircles from '../../../Global/LoadingCircles'
import PromoSticker from '../../Bootcamps/PromoSticker'
import DeletePopup2 from '../../global/DeletePopup2'
import ProfileAvatar from '../../global/ProfileAvatar'
import DynamicTag from '../DynamicTag'

const BodySection = () => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    // creating the path object variable
    const tempArrPath =  location.pathname.split("/");
    const clubId = tempArrPath[tempArrPath.length-1]


    // STATES
    // delete modal state
    const [deleteModalState, setDeleteModalState] = useState(false)
    // redux state
    const { 
        singleClub ,
        deleteClubLoading,
        deleteClubError,
        deleteClubSuccess,
        getSingleClubLoading
    } = useSelector(state=>{
        const { singleClub } = state.clubsReducer
        const { 
            deleteClubLoading ,
            getSingleClubLoading
        } = state.loadingIndicator
        const {
            success: { 
                deleteClub: deleteClubSuccess
            },
            errors: { 
                deleteClub: deleteClubError
            },
        } = state.ajaxStatuses;
        return {
            getSingleClubLoading,
            singleClub,
            deleteClubLoading,
            deleteClubError,
            deleteClubSuccess,
        }
    })


    // HANDLERS
    const toggleDeleteModalState = () => {
        setDeleteModalState(!deleteModalState)
    }
    const handleDeleteClub = () => {
        dispatch(deleteClubRequest(clubId))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleClubRequest(clubId))
    }, [])
    // listen for error or success messages
    useEffect(() => {
        if (deleteClubError) {
            toast.error(deleteClubError, {
                duration: 3000,
            });
        }
    }, [deleteClubError]);
    
    useEffect(() => {
        if (deleteClubSuccess) {
            setDeleteModalState(false)
            toast.success(deleteClubSuccess, {
                duration: 3000,
            });
            history.replace("/dashboard/club-subscription")
        }
    }, [deleteClubSuccess]);

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Club Subscriptions
                    </p>

                    <BackBtn />
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>
                
                {/* container */}
                <div className='bg-white rounded-[10px] py-10 px-5 flex flex-col gap-16'>

                    {
                        getSingleClubLoading?
                        <LoadingCircles />:
                        singleClub && Object.keys(singleClub).length!==0?
                        <>

                            {/* info heading */}
                            <div className='flex flex-col gap-4 w-[50%]'>

                                {/* package name */}
                                <p className='text-gray-800 font-semibold text-4xl'>
                                    {singleClub.name} Package
                                </p>

                                {/* promo price */}
                                <div className='flex items-center gap-3'>
                                    <PromoSticker />
                                    <p className='my-0 font-semibold text-xl text-gray-600'>
                                        NGN {singleClub.amount.toLocaleString('en-US')} <span className='text-base font-normal'>/yr</span>
                                    </p>
                                </div>

                                {/* number of members */}
                                <p className='font-normal text-2xl text-gray-600'>
                                    No. of members: <span className='font-extrabold'>340</span>
                                </p>

                                {/* edit package button */}
                                <div className='w-full flex flex-col items-stretch'>
                                    <BtnSm 
                                    onClick={()=>history.push(`/dashboard/club-subscription/edit/${singleClub._id}`)}
                                    title={"Edit Package"} />
                                </div>
                            </div>

                            {/* info details */}
                            <div className='[box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] py-6 px-5 gap-3 flex flex-col'>

                                <p className='text-[#3F444D] font-semibold text-xl'>
                                    Benefits
                                </p>

                                <div className='grid grid-cols-2 justify-start items-center gap-3 gap-y-3'>
                                    <DynamicTag
                                    status={singleClub.networking_event}
                                    title={"Networking Event"} />
                            
                                    <DynamicTag
                                    status={singleClub.member_swag}
                                    title={"Member Swag"} />
                            
                                    <DynamicTag
                                    status={singleClub.news_letter}
                                    title={"Newsletter"} />
                            
                                    <DynamicTag
                                    status={singleClub.reserved_office_space}
                                    title={"Reserved Office Space"} />
                            
                                    <DynamicTag
                                    status={singleClub.discounted_services}
                                    title={"Discounted Services"} />
                            
                                    <DynamicTag
                                    status={singleClub.investment_pitch}
                                    title={"Investment Pitch"} />
                            
                                    <DynamicTag
                                    status={singleClub.investment_database}
                                    title={"Investment Database"} />
                            
                                    <DynamicTag
                                    status={singleClub.business_consultancy}
                                    title={"Business Consultancy"} />
                            
                                    <DynamicTag
                                    status={singleClub.foreign_tech_consultancy}
                                    title={"Foreign Tech Consultancy"} />
                            
                                    <DynamicTag
                                    status={singleClub.life_insurance}
                                    title={"Life Insurance "} />
                                </div>
                            </div>

                            {/* delete club */}
                            <p 
                            onClick={toggleDeleteModalState}
                            className='text-[#E02D3C] text-base font-semibold w-full text-right cursor-pointer'>
                                {
                                    deleteClubLoading?
                                    "Deleting...":
                                    "Delete event?"
                                }
                            </p>
                        </>:
                    <EmptyDataState /> 
                    }
                </div>
            </section>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
               <DeletePopup2
               onClick={handleDeleteClub}
               loadinState={deleteClubLoading}
               closeModal={toggleDeleteModalState} />
            </MyModal>
        </section>
    )
}

export default BodySection