import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getSpacesRequest } from '../../../redux/action'
import ProfileAvatar from '../global/ProfileAvatar'
import AllAvailableSpaces from './AllAvailableSpaces'
import OpticsCard from './OpticsCard'

const SpaceBookingBody = () => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()


    // STATES
    const { spaces } = useSelector(state=>{
        const { spaces } = state.spacesReducer
        return {
            spaces
        }
    })

    
    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSpacesRequest())
    }, [])

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Space Booking
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>

                <OpticsCard
                spaces={spaces} />
                
                <AllAvailableSpaces
                spaces={spaces} />
            </section>
        </section>
    )
}

export default SpaceBookingBody