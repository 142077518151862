import { Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EmptyDataState from "../../Global/EmptyDataState";
import SpaceCard from "./SpaceCard";

const AllAvailableSpaces = ({spaces}) => {

    // OBJECT INITIALIZATION
    // history object
    const history = useHistory()


    // STATES
    const { getSpacesLoading } = useSelector(state=>{
        const { getSpacesLoading } = state.loadingIndicator
        return {
            getSpacesLoading
        }
    })

    return (
        <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
                <p className="font-semibold text-xl text-[#3F444D]">
                    All available spaces
                </p>

                <button
                onClick={() => history.push("/dashboard/space-booking/create/new-space")}
                className="font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2"
                >
                <AiOutlinePlusCircle size={24} />
                <span>Create new space</span>
                </button>
            </div>

                {
                    getSpacesLoading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                    }} />:
                    spaces && spaces.length!==0?

                    <div className="grid grid-cols-3 gap-9 py-4 lg:grid-cols-[40%_40%] container justify-center mx-auto">  
                        {
                            spaces.map((space)=>(
                                <SpaceCard
                                key={space._id}
                                availabilityId={space.is_Booked?1:2}
                                price={space.new_price}
                                path={space._id}
                                features={space.features}
                                spaceName={space.name} />
                            ))
                        }                        
                    </div>:
                    <EmptyDataState />
                }
        </div>
    );
};

export default AllAvailableSpaces;
