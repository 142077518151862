import React from "react";
import { Link } from "react-router-dom";
import image from "../../../assets/admindashboard/bootcamp/images/course-card-image.png";
import SingleSubjectText from "../global/SingleSubjectText";

const CourseCard = ({
  courseImage,
  courseTitle,
  courseSubtitle,
  subjects,
  fullDetailLink,
}) => {
  return (
    <div className="bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col gap-5 px-5 py-6">
      {/* image */}
      <div className="w-full">
        <img src={image} className={"83px rounded-[10px] w-full"} alt="" />
      </div>

      {/* detailed informations */}
      <div className="flex flex-col gap-8 h-full w-full">
        {/* title and subtitle */}
        <div className="flex flex-col">
          <p className="text-gray-600 text-xl font-semibold">{courseTitle}</p>

          <p className="text-gray-500 font-normal text-base">
            {courseSubtitle}
            <span className="text-transparent">{". "}</span>
          </p>
        </div>

        {/* subjects to learn */}
        <div
          className={`grid ${
            subjects.length < 2
              ? "grid-cols-[auto] justify-center"
              : "grid-cols-2 justify-between"
          } gap-1 gap-y-2 auto w-full`}
        >
          {subjects.map((item, index) => (
            <SingleSubjectText key={index} item={item.sub_topics.replace("CONTENT","")} />
          ))}
        </div>

        {/* view full details button */}
        <div className="mt-auto">
          <Link
            to={`/dashboard/course/${fullDetailLink}`}
            className="font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] hover:bg-gray-50 active:bg-[#f1f1f1] flex items-center justify-center hover:text-[#8b54f7] hover:no-underline"
          >
            View Full details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
