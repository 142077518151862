import React, { useEffect, useState } from 'react'
import BtnSm from '../../Global/BtnSm'
import Select from 'react-select';
import "../global/styles/custom-select-styling.css"
import { getClubsAdminRequest, getEventsAdminRequest, putAddUserToEventRequest } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

const AddUserEvent = ({userEmail}) => {

    // OBJECTS INITIALIZATION
    const trackData = [
        { value: 'regular', label: 'Regular' },
        { value: 'pathway', label: 'Special' },
    ];
    // history object
    const history = useHistory();
    // dispatch object
    const dispatch = useDispatch()
    // event data
    const [eventData, setEventData] = useState([])
    const [eventValue, setEventValue] = useState("")

    // STATES
    // redux states
    const { 
        events,
        getEventsLoading,
        putUserToEventSuccess,
        putUserToEventError,
        putUserToEventLoading        
     } = useSelector(state=>{
        const { 
            getEventsLoading,
            putUserToEventLoading
        } = state.loadingIndicator
        const {
            success: { putUserToEvent: putUserToEventSuccess },
            errors: { putUserToEvent: putUserToEventError},
        } = state.ajaxStatuses
        const { events } = state.eventsReducer
        return {
            events,
            getEventsLoading,
            putUserToEventSuccess,
            putUserToEventError,
            putUserToEventLoading
        }
    })


    // HANDLERS
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(putAddUserToEventRequest({
            eventId: eventValue,
            email: userEmail
        }))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getEventsAdminRequest())
    }, [])
    useEffect(()=>{
        setEventData(events.map(({_id,name})=>({value:_id, label:name})))
    }, [events])

    // checking for error and success messages
    useEffect(() => {
        if (putUserToEventError) {
            toast.error(putUserToEventError, {
            duration: 3000,
            });
        }
    }, [putUserToEventError]);
    
    useEffect(() => {
        if (putUserToEventSuccess) {
            toast.success(putUserToEventSuccess, {
            duration: 3000,
            });
            history.replace("/dashboard/users")
        }
    }, [putUserToEventSuccess]);

    return (
        <form
        className='flex flex-col gap-5 rounded-[10px] bg-[#F8F9FC] p-6 w-full h-fit'
        onSubmit={handleSubmit}>

            {
                getEventsLoading?
                <div
                    style={{
                    width: "100%",
                    }}
                    className="p-6 flex gap-5 flex-col h-full justify-center items-center m-auto"
                    >
                    {" "}
                    <CircularProgress
                    size={35} 
                    style={{ color: "#7F56D9" }} />
                    {" "}
                </div>
                :
                (events.length === 0)?
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex items-center justify-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            Event registered for
                        </span>
                    </div>
                    <Select
                    isDisabled={true}
                    className='custom-select-container'
                    placeholder="No data fetched, kindly refresh"
                    options={trackData} />
                </label>:
                <>
                    

                    {/* Current club subscription input container */}
                    <label className='flex flex-col gap-2'>

                        {/* label text */}
                        <div className='flex items-center justify-start'>
                            <span className='font-semibold text-sm text-[#344054]'>
                                Event registered for
                            </span>
                        </div>

                        {/* input field */}
                        <Select
                        className='custom-select-container'
                        placeholder="Add user to Event"
                        value={eventData.filter((data)=>data.value===eventValue)}
                        onChange={(obj)=>setEventValue(obj.value)}
                        options={eventData} />
                    </label>

                    {/* save changes button */}
                    <div className='flex w-full justify-end'>
                        <BtnSm 
                        loading={putUserToEventLoading}
                        disabled={!eventValue?true:false}
                        title={"Save Changes"} />
                    </div>
                </>
            }
        </form>
    )
}

export default AddUserEvent