import React, { useEffect, useState } from 'react'
import { HiOutlineSearch, HiUserCircle } from 'react-icons/hi'
import List from './List'

const ChangeUser = ({
    users,
    onSelectUser
}) => {

    // STATES
    const [usersList, setUsersList] = useState([])
    const [inputValue, setInputValue] = useState("")



    // HANDLERS
    const handleSearch = (e) => {
        setInputValue(e.target.value)
        // setUsersList(usersList.filter((item,index)=>{
        //     const tempName = `${item.firstname} ${item.lastname}`
        //     return tempName.toLowerCase().includes(inputValue.toLowerCase())
        // }))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        if(users){
            setUsersList(users)
        }
    }, [users])


    return (
        <div className='flex flex-col gap-6 p-3 bg-white w-[28vw] overflow-scroll h-[60vh]'>

            {/* search bat */}
            <div className='bg-white rounded-[4px] flex items-center gap-2 py-3 px-3 w-full border border-[#E1E6EF] '>
                <HiOutlineSearch
                color='#000' 
                size={24} />

                <input
                type="search" 
                value={inputValue}
                onChange={handleSearch}
                className='placeholder:font-normal placeholder:text-sm placeholder:text-[#202223] text-sm font-normal text-[#202223] outline-none w-full h-[40px]'
                placeholder='Search' />
            </div>

            {/* user list */}
            <List
            userList={usersList}
            onSelectUser={onSelectUser}
            inputValue={inputValue} />
        </div>
    )
}

export default ChangeUser