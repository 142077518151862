import React from 'react'

const UnbookUserBtn = ({disabled, onClick}) => {
    return (
        <button
        disabled={disabled}
        onClick={onClick}
        className="font-semibold text-sm 2xl:text-base text-[#E02D3C] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#E02D3C] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#fdf6f6] px-4 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-none w-full">
            <span>Unbook user</span>
        </button>
    )
}

export default UnbookUserBtn