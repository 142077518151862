import { CLEAR_DELETE_USER_SUCCESS, CLEAR_UPDATE_USER_SUCCESS, DELETE_USER_FAIL, DELETE_USER_LOADING, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, GET_SINGLE_USER_FAIL, GET_SINGLE_USER_LOADING, GET_SINGLE_USER_REQUEST, GET_SINGLE_USER_SUCCESS, GET_USERS_FAIL, GET_USERS_LOADING, GET_USERS_REQUEST, GET_USERS_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_LOADING, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS } from "../types"

// GET USERS
export const getUsersRequest = () => {
    return {
        type: GET_USERS_REQUEST
    }
}

export const getUsersLoading = (loading) => {
    return {
        type: GET_USERS_LOADING,
        payload: loading,
    }
}

export const getUsersSuccess = (prop) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: prop,
    }
}

export const getUsersFailure = (errors) => {
    return {
        type: GET_USERS_FAIL,
        payload: { errors }
    }
}

// GET SINGLE USER
export const getSingleUserRequest = (prop) => {
    return {
        type: GET_SINGLE_USER_REQUEST,
        payload: prop,
    }
}

export const getSingleUserLoading = (loading) => {
    return {
        type: GET_SINGLE_USER_LOADING,
        payload: loading,
    }
}

export const getSingleUserSuccess = (prop) => {
    return {
        type: GET_SINGLE_USER_SUCCESS,
        payload: prop,
    }
}

export const getSingleUserFailure = (errors) => {
    return {
        type: GET_SINGLE_USER_FAIL,
        payload: { errors }
    }
}

// UDPATE USER
export const updateUserRequest = (prop) => {
    return {
        type: UPDATE_USER_REQUEST,
        payload: prop
    }
}

export const updateUserLoading = (loading) => {
    return {
        type: UPDATE_USER_LOADING,
        payload: loading
    }
}

export const updateUserSuccess = (prop) => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: prop
    }
}


export const updateUserFailure = (errors) => {
    return {
        type: UPDATE_USER_FAIL,
        payload: { errors }
    }
}

export const clearUpdateUserSuccess = () => {
    return {
        type: CLEAR_UPDATE_USER_SUCCESS
    }
}

// DELETE USER
export const deleteUserRequest = (prop) => {
    return {
        type: DELETE_USER_REQUEST,
        payload: prop
    }
}

export const deleteUserLoading = (loading) => {
    return {
        type: DELETE_USER_LOADING,
        payload: loading
    }
}

export const deleteUserSuccess = (prop) => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: prop
    }
}


export const deleteUserFailure = (errors) => {
    return {
        type: DELETE_USER_FAIL,
        payload: { errors }
    }
}

export const clearDeleteUserSuccess = () => {
    return {
        type: CLEAR_DELETE_USER_SUCCESS
    }
}