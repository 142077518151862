import { CLEAR_CREATE_CLUB_SUCCESS, CLEAR_DELETE_CLUB_SUCCESS, CLEAR_UPDATE_CLUB_SUCCESS, DELETE_CLUB_FAIL, DELETE_CLUB_LOADING, DELETE_CLUB_REQUEST, DELETE_CLUB_SUCCESS, GET_CLUBS_ADMIN_FAIL, GET_CLUBS_ADMIN_LOADING, GET_CLUBS_ADMIN_REQUEST, GET_CLUBS_ADMIN_SUCCESS, GET_SINGLE_CLUB_FAIL, GET_SINGLE_CLUB_LOADING, GET_SINGLE_CLUB_REQUEST, GET_SINGLE_CLUB_SUCCESS, POST_CREATE_CLUB_FAIL, POST_CREATE_CLUB_LOADING, POST_CREATE_CLUB_REQUEST, POST_CREATE_CLUB_SUCCESS, UPDATE_CLUB_FAIL, UPDATE_CLUB_LOADING, UPDATE_CLUB_REQUEST, UPDATE_CLUB_SUCCESS } from "../types";

// get clubs for admin
export const getClubsAdminRequest = () => {
    return {
        type: GET_CLUBS_ADMIN_REQUEST
    }
}

export const getClubsAdminSuccess = (prop) => {
    return {
        type: GET_CLUBS_ADMIN_SUCCESS,
        payload: prop,
    };
};
  
export const getClubsAdminFailure = (errors) => {
    return {
        type: GET_CLUBS_ADMIN_FAIL,
        payload: { errors },
    };
};

export const getClubsAdminLoading = (loading) => {
    return {
        type: GET_CLUBS_ADMIN_LOADING,
        payload: loading,
    };
};

// GET SINGLE EVENT
export const getSingleClubFailure = (errors) => {
    return {
        type: GET_SINGLE_CLUB_FAIL,
        payload: { errors }
    }
}

export const getSingleClubLoading = (loading) => {
    return {
        type: GET_SINGLE_CLUB_LOADING,
        payload: loading
    }
}

export const getSingleClubRequest = (prop) => {
    return {
        type: GET_SINGLE_CLUB_REQUEST,
        payload: prop
    }
}

export const getSingleClubSuccess = (prop) => {
    return {
        type: GET_SINGLE_CLUB_SUCCESS,
        payload: prop
    }
}

// UPDATE CLUB
export const updateClubRequest = (prop) => {
    return {
        type: UPDATE_CLUB_REQUEST,
        payload: prop
    }
}

export const updateClubLoading = (loading) => {
    return {
        type: UPDATE_CLUB_LOADING,
        payload: loading
    }
}

export const updateClubSuccess = (prop) => {
    return {
        type: UPDATE_CLUB_SUCCESS,
        payload: prop
    }
}


export const updateClubFailure = (errors) => {
    return {
        type: UPDATE_CLUB_FAIL,
        payload: { errors }
    }
}

export const clearUpdateClubSuccess = () => {
    return {
        type: CLEAR_UPDATE_CLUB_SUCCESS
    }
}

// CREATE CLUB
export const postCreateClubRequest = (prop) => {
    return {
        type: POST_CREATE_CLUB_REQUEST,
        payload: prop
    }
}

export const postCreateClubLoading = (loading) => {
    return {
        type: POST_CREATE_CLUB_LOADING,
        payload: loading
    }
}

export const postCreateClubSuccess = (prop) => {
    return {
        type: POST_CREATE_CLUB_SUCCESS,
        payload: prop
    }
}


export const postCreateClubFailure = (errors) => {
    return {
        type: POST_CREATE_CLUB_FAIL,
        payload: { errors }
    }
}

export const clearCreateClubSuccess = () => {
    return {
        type: CLEAR_CREATE_CLUB_SUCCESS
    }
}

// DELETE CLUB
export const deleteClubRequest = (prop) => {
    return {
        type: DELETE_CLUB_REQUEST,
        payload: prop
    }
}

export const deleteClubLoading = (loading) => {
    return {
        type: DELETE_CLUB_LOADING,
        payload: loading
    }
}

export const deleteClubSuccess = (prop) => {
    return {
        type: DELETE_CLUB_SUCCESS,
        payload: prop
    }
}


export const deleteClubFailure = (errors) => {
    return {
        type: DELETE_CLUB_FAIL,
        payload: { errors }
    }
}

export const clearDeleteClubSuccess = () => {
    return {
        type: CLEAR_DELETE_CLUB_SUCCESS
    }
}