import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import Bootcamps from ".";
import ViewFullDetails from "./ViewFullDetails";
import EditBootcamp from "./EditBootcamp";
import CreateNewBootcamp from "./CreateNewBootcamp";

const BootcampsRouter = ({path}) => {
    
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={Bootcamps} />

            <Route 
            exact 
            path={`${path}/:id`}
            component={ViewFullDetails} />

            <Route 
            exact 
            path={`${path}/edit/:id`}
            component={EditBootcamp} />

            <Route 
            exact 
            path={`${path}/create/new-bootcamp`}
            component={CreateNewBootcamp} />

        </ Switch>
    )
}

export default BootcampsRouter