import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { 
  getStorage,
  sRef,
  uploadBytesResumable,
  getDownloadURL, 
} from "../../../firebase/fireConfig.js";
import { toast } from "react-toastify";

const FileUploadPopup = ({ closeModal, setImage }) => {
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    noClick: isFilePicked,
    onDrop: (acceptedFiles) => {
      setIsFilePicked(true);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    files.length > 0 && console.log(files);
    console.log(files.length);
  }, [files]);

  const fileUpload = async () => {
    setLoading(true);

    const storage = getStorage();

    const storageRef = sRef(storage, "image/" + files[0].name);

    console.log(files[0].name);

    const uploadTask = uploadBytesResumable(storageRef, files[0]);

    let imgUrl = "";
    let progress = "";
    let error;

    uploadTask.on(
      "state-change",
      (snap) => {
        progress = Math.floor((snap.bytesTransferred / snap.totalBytes) * 100);
        setProgress(progress);
      },
      (err) => {
        error = "Image not uploaded";
        closeModal()
        toast.error(error)
        setImage("")
        console.log(err)
        setLoading(false);
      },
      async () => {
        imgUrl = await getDownloadURL(uploadTask.snapshot.ref);

        console.log(imgUrl);

        setUploadUrl(imgUrl);

        if (imgUrl) {
          setLoading(false);
          closeModal()
          toast.success("image uploaded successfully!")
          setImage(imgUrl)
          console.log(imgUrl)
        }
      }
    );
  };

  return (
    <section className="w-full">
      <div
        {...getRootProps({
          className:
            "dropzone cursor-pointer p-6 flex flex-col gap-4 justify-center items-center",
        })}
      >
        <input {...getInputProps()} />

        {files.length <= 0 ? (
          <div className="p-6 flex flex-col gap-4 justify-center items-center">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 21.5L9.4519 14.0481C10.7211 12.7789 12.7789 12.7789 14.0481 14.0481L21.5 21.5M18.25 18.25L20.8269 15.6731C22.0961 14.4039 24.1539 14.4039 25.4231 15.6731L28 18.25M18.25 8.5H18.2663M5.25 28H24.75C26.5449 28 28 26.5449 28 24.75V5.25C28 3.45507 26.5449 2 24.75 2H5.25C3.45507 2 2 3.45507 2 5.25V24.75C2 26.5449 3.45507 28 5.25 28Z"
                stroke="#8B54F7"
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p className="font-normal text-base text-center flex flex-col gap-1">
              <span>
                <span className="text-[#8B54F7]">Upload a file</span> or drag
                and drop
              </span>

              <span className="text-[#6B7280] font-normal text-sm text-center">
                PNG, JPG, , GIF upto 5MB
              </span>
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-3 w-full mt-5">
            {/* selected image preview */}
            <img
              src={files[0].preview}
              className="[border:2px_dashed_#7F56D9] h-[30vh] w-[30vh] object-cover rounded-[50%]"
              alt="selected photograph"
            />

            {/* upload button */}
            <div className="flex flex-col gap-4 mt-6 items-stretch w-full">
              <button
                onClick={() => fileUpload()}
                className="h-[44px] self-stretch bg-[#7F56D9] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80"
              >
                {loading ? (
                  <CircularProgress style={{ color: "white" }} size={20} />
                ) : (
                  "Upload"
                )}
              </button>

              {/* cancel button */}
              <button
                onClick={closeModal}
                className="h-[44px] self-stretch border-[#E02D3C] bg-white border flex justify-center items-center font-semibold text-base text-[#E02D3C] px-7 lg:text-xs rounded-[8px] hover:opacity-80"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FileUploadPopup;
