import { CLEAR_DELETE_COURSE_SUCCESS, CLEAR_POST_CREATE_COURSE_SUCCESS, CLEAR_PUT_SINGLE_COURSE_SUCCESS, DELETE_COURSE_FAIL, DELETE_COURSE_LOADING, DELETE_COURSE_REQUEST, DELETE_COURSE_SUCCESS, GET_COURSES_FAIL, GET_COURSES_LOADING, GET_COURSES_REQUEST, GET_COURSES_SUCCESS, GET_SINGLE_COURSES_FAIL, GET_SINGLE_COURSES_LOADING, GET_SINGLE_COURSES_REQUEST, GET_SINGLE_COURSES_SUCCESS, POST_CREATE_COURSE_FAIL, POST_CREATE_COURSE_LOADING, POST_CREATE_COURSE_REQUEST, POST_CREATE_COURSE_SUCCESS, PUT_EDIT_SINGLE_COURSE_FAIL, PUT_EDIT_SINGLE_COURSE_LOADING, PUT_EDIT_SINGLE_COURSE_REQUEST, PUT_EDIT_SINGLE_COURSE_SUCCESS } from "../types"

// GET COURSES FOR ADMIN
export const getCoursesRequest = () => {
    return {
        type: GET_COURSES_REQUEST
    }
}

export const getCoursesLoading = (loading) => {
    return {
        type: GET_COURSES_LOADING,
        payload: loading,
    }
}

export const getCoursesSuccess = (prop) => {
    return {
        type: GET_COURSES_SUCCESS,
        payload: prop,
    }
}

export const getCoursesFailure = (errors) => {
    return {
        type: GET_COURSES_FAIL,
        payload: { errors }
    }
}

// GET SINGLE COURSE
export const getSingleCoursesRequest = (prop) => {
    return {
        type: GET_SINGLE_COURSES_REQUEST,
        payload: prop,
    };
};

export const getSingleCoursesLoading = (loading) => {
    return {
        type: GET_SINGLE_COURSES_LOADING,
        payload: loading,
    }
}

export const getSingleCoursesSuccess = (prop) => {
    return {
        type: GET_SINGLE_COURSES_SUCCESS,
        payload: prop,
    }
}

export const getSingleCoursesFailure = (errors) => {
    return {
        type: GET_SINGLE_COURSES_FAIL,
        payload: { errors }
    }
}

// PUT EDIT SINGLE COURSE
export const putEditSingleCourseRequest = (prop) => {
    return {
        type: PUT_EDIT_SINGLE_COURSE_REQUEST,
        payload: prop
    }
}

export const putEditSingleCourseLoading = (loading) => {
    return {
        type: PUT_EDIT_SINGLE_COURSE_LOADING,
        payload: loading
    }
}

export const putEditSingleCourseSuccess = (prop) => {
    return {
        type: PUT_EDIT_SINGLE_COURSE_SUCCESS,
        payload: prop
    }
}


export const putEditSingleCourseFailure = (errors) => {
    return {
        type: PUT_EDIT_SINGLE_COURSE_FAIL,
        payload: { errors }
    }
}

export const clearPutSingleCourseSuccess = () => {
    return {
        type: CLEAR_PUT_SINGLE_COURSE_SUCCESS
    }
}

// POST CREATE COURSE
export const postCreateCourseRequest = (prop) => {
    return {
        type: POST_CREATE_COURSE_REQUEST,
        payload: prop
    }
}

export const postCreateCourseLoading = (loading) => {
    return {
        type: POST_CREATE_COURSE_LOADING,
        payload: loading
    }
}

export const postCreateCourseSuccess = (prop) => {
    return {
        type: POST_CREATE_COURSE_SUCCESS,
        payload: prop
    }
}


export const postCreateCourseFailure = (errors) => {
    return {
        type: POST_CREATE_COURSE_FAIL,
        payload: { errors }
    }
}

export const clearCreateCourseSuccess = () => {
    return {
        type: CLEAR_POST_CREATE_COURSE_SUCCESS
    }
}

// DELETE CREATE BOOTCAMP
export const deleteCourseRequest = (prop) => {
    return {
        type: DELETE_COURSE_REQUEST,
        payload: prop
    }
}

export const deleteCourseLoading = (loading) => {
    return {
        type: DELETE_COURSE_LOADING,
        payload: loading
    }
}

export const deleteCourseSuccess = (prop) => {
    return {
        type: DELETE_COURSE_SUCCESS,
        payload: prop
    }
}


export const deleteCourseFailure = (errors) => {
    return {
        type: DELETE_COURSE_FAIL,
        payload: { errors }
    }
}

export const clearDeleteCourseSuccess = () => {
    return {
        type: CLEAR_DELETE_COURSE_SUCCESS
    }
}