import {
  GET_DATA_ANALYSIS_FORM_FAIL,
  GET_DATA_ANALYSIS_FORM_LOADING,
  GET_DATA_ANALYSIS_FORM_REQUEST,
  GET_DATA_ANALYSIS_FORM_SUCCESS,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_FAIL,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_LOADING,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_REQUEST,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS,
} from "../types";

// GET DIGITAL MARKETING STUDENT
export const getDesignBootcampParticipantsRequest = () => {
  return {
    type: GET_DESIGN_BOOTCAMP_PARTICIPANTS_REQUEST,
  };
};

export const getDesignBootcampParticipantsLoading = (loading) => {
  return {
    type: GET_DESIGN_BOOTCAMP_PARTICIPANTS_LOADING,
    payload: loading,
  };
};

export const getDesignBootcampParticipantsSuccess = (prop) => {
  return {
    type: GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS,
    payload: prop,
  };
};

export const getDesignBootcampParticipantsFailure = (errors) => {
  return {
    type: GET_DESIGN_BOOTCAMP_PARTICIPANTS_FAIL,
    payload: { errors },
  };
};

// GET DATA ANALYSIS BOOTCAMP
export const getDataAnalysisFormRequest = () => {
  return {
    type: GET_DATA_ANALYSIS_FORM_REQUEST,
  };
};

export const getDataAnalysisFormLoading = (loading) => {
  return {
    type: GET_DATA_ANALYSIS_FORM_LOADING,
    payload: loading,
  };
};

export const getDataAnalysisFormSuccess = (prop) => {
  return {
    type: GET_DATA_ANALYSIS_FORM_SUCCESS,
    payload: prop,
  };
};

export const getDataAnalysisFormFailure = (errors) => {
  return {
    type: GET_DATA_ANALYSIS_FORM_FAIL,
    payload: { errors },
  };
};
