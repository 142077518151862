import { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import ProfileAvatar from "../../global/ProfileAvatar";
import { useHistory, useLocation } from "react-router-dom";
import TrackLabel from "../../global/TrackLabel";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import PromoSticker from "../PromoSticker";
import BtnSm from "../../../Global/BtnSm";
import BootcampUsersTable from "./BootcampUsersTable";
import { useDispatch, useSelector } from 'react-redux'
import { deleteBootcampRequest, getSingleBootcampRequest } from '../../../../redux/action'
import LoadingCircles from '../../../Global/LoadingCircles'
import EmptyDataState from '../../../Global/EmptyDataState'
import { timeSpent } from "../../../../helpers";
import MyModal from "../../../../layouts/MyModal";
import DeletePopup2 from "../../global/DeletePopup2";
import { toast } from "react-toastify";

const BodySection = () => {

  // OBJECT INITIALIZATION
  const subTitles = ["front-end", "back-end"]
  // history object
  const history = useHistory();
  // dispatch object
  const dispatch = useDispatch()
  // location object
  const location = useLocation()
  // creating the path object variable
  const tempArrPath =  location.pathname.split("/");
  const path = tempArrPath.slice(tempArrPath.length-1)[0]
  const pathLen = path.length
  const bootcampNumber = path.slice(pathLen-1)
  const bootcampId = path.slice(0,pathLen-1)


  // STATES
  // single bootcamp state
  const { 
    singleBootcamp, 
    getSingleBootcampLoading,
    deleteBootcampLoading,
    deleteBootcampSuccess,
    deleteBootcampError 
  } = useSelector(state=>{
      const { 
        getSingleBootcampLoading ,
        deleteBootcampLoading
      } = state.loadingIndicator
      const { singleBootcamp } = state.bootcampsReducer
      const {
        success: { 
            deleteBootcamp: deleteBootcampSuccess
        },
        errors: { 
            deleteBootcamp: deleteBootcampError
        },
    } = state.ajaxStatuses;
      return {
          getSingleBootcampLoading,
          singleBootcamp,
          deleteBootcampLoading,
          deleteBootcampSuccess,
          deleteBootcampError
      }
  })
  const [deleteModalState, setDeleteModalState] = useState(false)


  // HANDLERS
  const toggleDeleteModalState = () => {
      setDeleteModalState(!deleteModalState)
  }
  const handleDeleteBootcamp = () => {
      dispatch(deleteBootcampRequest(bootcampId))
  }


  // SIDE EFFECTS
  useEffect(()=>{
      dispatch(getSingleBootcampRequest(bootcampId))
  }, [])

  // listen for error or success messages
  useEffect(() => {
      if (deleteBootcampError) {
          toast.error(deleteBootcampError, {
              duration: 3000,
          });
      }
  }, [deleteBootcampError]);

  useEffect(() => {
      if (deleteBootcampSuccess) {
          setDeleteModalState(false)
          toast.success(deleteBootcampSuccess, {
              duration: 3000,
          });
          history.replace("/dashboard/bootcamps")
      }
  }, [deleteBootcampSuccess]);

  return (
    <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12">
      {/* header */}
      <header className="container mx-auto mobile:px-4 flex justify-between items-start">
        <div className="flex flex-col gap-7">
          <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
            Bootcamps
          </p>

          <p
            onClick={() => history.goBack()}
            className="flex items-center justify-start gap-3 cursor-pointer
                    "
          >
            <IoIosArrowBack className="text-[#8B54F7]" size={18} />
            <span className="font-normal text-base text-gray-500">Back</span>
          </p>
        </div>

        {/* profile dp */}
        <div className="mobile:hidden">
          <ProfileAvatar />
        </div>
      </header>

      {/* body section */}
      <section className="container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10">

        {/* Bootcamp detais */}
        <div className="rounded-[10px] bg-white flex flex-col gap-10 py-9 px-6">

          {
            getSingleBootcampLoading?
            <LoadingCircles />:
            (singleBootcamp && Object.keys(singleBootcamp).length === 0)?
            <EmptyDataState />:
              <>

                {/* Heading information */}
                <div className="flex items-end justify-between">
                  
                  {/* first container */}
                  <div className="flex flex-col gap-4 w-[50%] items-start">

                    <TrackLabel 
                    trackId={singleBootcamp.course_type === "regular"?1:2} 
                    trackTitle={singleBootcamp.course_type === "regular"?"Regular":"Special"} />

                    <div className="flex flex-col gap-3">
                      <p className="text-gray-600 font-semibold text-4xl">
                        Bootcamp {bootcampNumber}
                      </p>
                      <span className="font-semibold text-2xl text-gray-500">
                        {singleBootcamp.name}
                      </span>
                      <span className="text-gray-500 text-xl font-normal">
                        {subTitles.includes(singleBootcamp.description?.split(" ")[0]?.toLowerCase())? `(${singleBootcamp.description?.split(" ")[0]})`:`(${singleBootcamp.name?.split("(")[1]}`}
                      </span>
                    </div>

                    {/* progress bar */}
                    <div className="flex items-center justify-start w-full mb-2">
                      <div className="w-[50%] ml-[-12px]">
                        <Progress.Line
                          percent={Math.floor((timeSpent(singleBootcamp.start_date) / (singleBootcamp.duration)) * 100)}
                          showInfo={false}
                          strokeColor="#7F56D9"
                        />
                      </div>

                      <span className="text-xs font-normal text-[#1D2433] opacity-[0.65] w-[50%] mb-1">
                        {singleBootcamp.duration - timeSpent(singleBootcamp.start_date)} month(s) remaining
                      </span>
                    </div>
                  </div>

                  {/* second container */}
                  <div className="flex flex-col gap-3 w-[50%] items-end mb-2">
                    <p className="font-normal text-base text-[#1D243366] line-through">
                      {singleBootcamp.old_price.toLocaleString('en-US')}
                    </p>

                    <div className="flex items-center gap-2">
                      <p className="text-gray-500 font-semibold text-xl">
                        {singleBootcamp.new_price.toLocaleString('en-US')}
                      </p>
                      <PromoSticker />
                    </div>

                    <div className="flex items-center gap-2 text-gray-500">
                      <span className="font-normal text-2xl">No. of students:</span>
                      <span className="font-extrabold text-2xl">{singleBootcamp.students.length}</span>
                    </div>

                    <div className="w-[80%] flex flex-col items-stretch mt-2">
                      <BtnSm
                        onClick={() =>
                          history.push(`/dashboard/bootcamps/edit/${singleBootcamp._id}${bootcampNumber}`)
                        }
                        title={"Edit Bootcamp 1"}
                      />
                    </div>
                  </div>
                </div>

                {/* Table Info */}
                <BootcampUsersTable
                bootcampId={bootcampId}
                students={singleBootcamp.students} />

                {/* delete course */}
                <p 
                onClick={toggleDeleteModalState}
                className='text-[#E02D3C] text-base font-semibold w-full text-right cursor-pointer'>
                    {
                        deleteBootcampLoading?
                        "Deleting...":
                        "Delete bootcamp?"
                    }
                </p> 
              </>
          }
        </div>
      </section>

      {/* delete course modal */}
      <MyModal
      modalIsOpen={deleteModalState}
      toggleModal={toggleDeleteModalState}
      disableBtn={false}>
          <DeletePopup2
          onClick={handleDeleteBootcamp}
          loadinState={deleteBootcampLoading}
          closeModal={toggleDeleteModalState} />
      </MyModal>
    </section>
  );
};

export default BodySection;
