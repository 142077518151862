import React from 'react'
import club_image_icon from "../../../assets/admindashboard/home/images/club-icon.png"

const ClubDetailedInfo = () => {
    return (
        <div>
            <div className='bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] flex flex-col px-7 pb-6 gap-8'>
                <div className='flex gap-4 py-7 pb-5 border-b border-[#E1E6EF]'>
                    <img src={club_image_icon} className="w-[36px] h-[36px]" alt="" />
                    <p className='font-semibold text-xl text-grap-600 opacity-[0.65]'>
                        Club members
                    </p>
                </div>

                {/* optics/information */}
                <div className='w-full'>
                    <div className='flex flex-col rounded-[8px] bg-[#F8F9FC] p-4 gap-4'>

                        {/* information */}
                        <div className='flex items-center justify-between'>
                            <p className='font-normal text-base text-gray-500'>
                                Basic:
                            </p>
                            <p className='text-sm font-semibold text-gray-600 opacity-[0.65]'>
                                150
                            </p>
                        </div>

                        <div className='flex items-center justify-between'>
                            <p className='font-normal text-base text-gray-500'>
                                Intermediate:
                            </p>
                            <p className='text-sm font-semibold text-gray-600 opacity-[0.65]'>
                                50
                            </p>
                        </div>

                        <div className='flex items-center justify-between'>
                            <p className='font-normal text-base text-gray-500'>
                                Advanced:
                            </p>
                            <p className='text-sm font-semibold text-gray-600 opacity-[0.65]'>
                                34
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClubDetailedInfo