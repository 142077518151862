import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import placeholder from "../../../assets/admindashboard/space-booking/images/placeholder-img.png"
import GrayCheck from '../global/GrayCheck'
import AvailabilityLabel from './AvailabilityLabel'

const SpaceCard = ({availabilityId, spaceName, features, price, path}) => {

    // history object
    const history = useHistory()

    return (
        <div className='flex flex-col gap-6 rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] bg-white p-3'>

            {/* image */}
            <div>
                <img src={placeholder} className="rounded-[10px] w-full h-[184px] object-cover" alt="" />
            </div>

            {/* description */}
            <div className='flex flex-col gap-3 h-full'>
                <div className='flex items-center justify-between'>
                    <p className='font-semibold text-xl text-[#1D2433]'>
                        {spaceName}
                    </p>

                    <AvailabilityLabel
                    id={availabilityId} />

                </div>

                <div className='flex flex-col gap-2'>

                    {
                        features.map((feature)=>(
                            <div 
                            key={feature._id}
                            className='flex items-center gap-3'>
                                <GrayCheck />
                                <span className='font-normal text-base text-[#3F444D]'>
                                    {feature.content}
                                </span>
                            </div>
                        ))
                    }

                </div>
                <div className="flex flex-col gap-3 h-full justify-end">
                    <div className='text-gray-600 flex items-end justify-center gap-1'>
                        <span className='font-semibold text-xl'>
                            NGN {price.toLocaleString('en-US')}
                        </span>
                        <span className='font-normal text-base'>
                            /day
                        </span>
                    </div>

                    <div className='flex flex-col w-full'>
                        <button
                        onClick={()=>history.push(`/dashboard/space-booking/edit/${path}`)}
                        className='font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2'>
                            Edit Space details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpaceCard