import { useState } from 'react'
import { GrClose } from "react-icons/gr"
import { FiDownload } from "react-icons/fi"
import { saveAs } from 'file-saver'

const ViewEventImage = ({ image, closeModal }) => {

    // show button state
    const [showBtns, setShowBtns] = useState(true)

    // show and hide button styles
    const showHideBtnStyle = showBtns? " [appearance:auto] [visibility:visible]":" [appearance:none] [visibility:hidden]"

    // download image function
    const downloadImage = () => {
      saveAs(image, "EventImage.png")
    }

    return (
      <div className='flex flex-col justify-center items-stretch bg-transparent h-full w-full'>
        <img 
        onClick={()=>setShowBtns(!showBtns)}
        src={image} 
        className="w-full h-screen md:object-cover pc:object-cover object-contain mobile:relative" 
        alt="full photograph of the event" />

        {/* download image button */}
        <button
        onClick={downloadImage}
        className={`[transition:all_.4s_ease-in-out] flex flex-col text-[#1d2433] text-base font-normal justify-center items-center p-3 gap-2 absolute right-[20px] top-[15px] lg:top-[25px] md:!top-[25px] mobile:top-[10%] hover:bg-[#e3e3e3] transition-colors bg-white rounded-[4px] ${showHideBtnStyle}`}>
            <FiDownload size={24} />
            <span className='opacity-[0.65] mobile:hidden'>
                Download image
            </span>
        </button>

        {/* close modal button */}
        <button
        onClick={closeModal}
        className={`[transition:all_.4s_ease-in-out] flex flex-col text-[#1d2433] text-base font-normal justify-center items-center p-3 gap-2 absolute left-[25px] top-[15px] lg:top-[25px] md:!top-[25px] mobile:top-[10%] hover:bg-[#e3e3e3] transition-colors bg-white rounded-[50%] h-[40px] w-[40px] ${showHideBtnStyle}`}>
            <GrClose size={24} />
        </button>

      </div>
    )
}

export default ViewEventImage