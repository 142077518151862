import {
  GET_DATA_ANALYSIS_FORM_SUCCESS,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS,
} from "../types";

// REDUCER'S INITIAL STATE
const initialState = () => ({
  designBootcampParticipants: [],
  dataAnalysisForm: [],
});

const designBootcampReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS:
      return {
        designBootcampParticipants: payload.designs,
      };

    case GET_DATA_ANALYSIS_FORM_SUCCESS:
      return {
        dataAnalysisForm: payload.dabs,
      };

    default:
      return state;
  }
};

export default designBootcampReducer;
