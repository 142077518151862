import { GET_CLUB_SUBSCRIPTION_SUCCESS } from "../types"


const initialState = {
    clubSubscriptions: []
}

const clubSubscriptionReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_CLUB_SUBSCRIPTION_SUCCESS:
    return { 
        ...state,
        clubSubscriptions: payload.clubSubscription
    }

  default:
    return state
  }
}

export default clubSubscriptionReducer
