import ProfileAvatar from "../global/ProfileAvatar";
import UsersCard from "./UsersCard";
import StudentDetailedInfo from "./StudentDetailedInfo";
import ClubDetailedInfo from "./ClubDetailedInfo";
import ApprovalRequests from "./ApprovalRequests";
import LatestEvents from "./LatestEvents";
import { FiBookOpen, FiServer, FiUsers } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { AiOutlineCalendar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getBootcampStudentsRequest,
  getDigitalMarketingStudentsRequest,
} from "../../../redux/action/bootcampStudents";
import {
  getCritiqueEventParticipantRequest,
  getDataAnalysisFormRequest,
  getDesignBootcampParticipantsRequest,
} from "../../../redux/action";
import TableModal from "../../../layouts/TableModal";
import TableModalView from "./TableModalView";
import ParticipantsCard from "./ParticipantsCard";

const HomeBody = () => {
  // OBJECT INITIALIZATION
  const dispatch = useDispatch();

  // STATES
  const [listProp, setLIstProp] = useState([]);
  const [isCritique, setIsCritique] = useState(false);
  const [tableModalState, setTableModalState] = useState(false);
  const {
    bootcampStudents,
    getBootcampStudentsLoading,
    digitalMarketingStudents,
    getDigitalMarketingStudentsLoading,
    digitalMarketingStudentsError,
    critiqueEventParticipants,
    critiqueEventParticipantsLoading,
    getDesignBcampParticipantsLoading,
    designBootcampParticipants,
    dataAnalysisForm,
    getDataAnalysisFormLoading,
  } = useSelector((state) => {
    // bootcamp students
    const { bootcampStudents, digitalMarketingStudents } =
      state.bootcampStudentsReducer;

    // critique event participants
    const { critiqueEventParticipants } = state.critiqueEventReducer;

    // design bootcamp
    const { designBootcampParticipants, dataAnalysisForm } =
      state.designBootcampReducer;

    // loading indicators
    const {
      getBootcampStudentsLoading,
      getDigitalMarketingStudentsLoading,
      critiqueEventParticipantsLoading,
      getDesignBcampParticipantsLoading,
      getDataAnalysisFormLoading,
    } = state.loadingIndicator;

    // ajax statuses
    const { digitalMarketingStudents: digitalMarketingStudentsError } =
      state.ajaxStatuses;

    return {
      bootcampStudents,
      digitalMarketingStudents,
      getBootcampStudentsLoading,
      getDigitalMarketingStudentsLoading,
      digitalMarketingStudentsError,
      critiqueEventParticipants,
      critiqueEventParticipantsLoading,
      getDesignBcampParticipantsLoading,
      designBootcampParticipants,
      dataAnalysisForm,
      getDataAnalysisFormLoading,
    };
  });

  // HANDLERS
  const toggleTableModal = () => {
    setTableModalState(!tableModalState);
  };

  const handleViewDetails = (arr, critique) => {
    toggleTableModal();
    setLIstProp(arr);
    setIsCritique(critique);
  };

  // SIDE EFFECTS
  useEffect(() => {
    dispatch(getBootcampStudentsRequest());
    dispatch(getDigitalMarketingStudentsRequest());
    dispatch(getCritiqueEventParticipantRequest());
    dispatch(getDesignBootcampParticipantsRequest());
    dispatch(getDataAnalysisFormRequest());
  }, []);

  return (
    <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12">
      {/* header */}
      <header className="container mx-auto mobile:px-4 flex justify-between items-center">
        <div>
          <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
            Overview
          </p>
        </div>

        {/* profile dp */}
        <div className="mobile:hidden">
          <ProfileAvatar />
        </div>
      </header>

      {/* body section */}
      <section className="container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9">
        {/* users optics */}
        <div className="grid mobile:grid-cols-[auto] grid-cols-3 xl:grid-cols-[auto_auto_auto] lg:grid-cols-2 gap-7">
          <UsersCard
            title={"All registered users"}
            icon={<FiUsers size={24} color="#1D2433A6" />}
            numbers={0}
            viewAllPath="/dashboard/users"
          />

          <UsersCard
            title={"Users in bootcamps"}
            icon={<FiServer size={24} color="#1D2433A6" />}
            numbers={0}
          />

          <UsersCard
            title={"Club membership subscribers"}
            icon={<FaUsers size={24} color="#1D2433A6" />}
            numbers={0}
            viewAllPath="/dashboard/club-members"
          />

          <UsersCard
            title={"Bootcamps in progress"}
            icon={<FiServer size={24} color="#1D2433A6" />}
            numbers={0}
            viewAllPath="/dashboard/bootcamps"
          />

          <UsersCard
            title={"Available courses"}
            icon={<FiBookOpen size={24} color="#1D2433A6" />}
            numbers={0}
            viewAllPath="/dashboard/course"
          />

          <UsersCard
            title={"Upcoming events"}
            icon={<AiOutlineCalendar size={24} color="#1D2433A6" />}
            numbers={0}
            viewAllPath="/dashboard/events"
          />

          {/* participant card for bootcamp students */}
          <ParticipantsCard
            title={"Bootcamp Participants"}
            icon={<AiOutlineCalendar size={24} color="#1D2433A6" />}
            viewAllPath={
              getBootcampStudentsLoading
                ? ""
                : bootcampStudents && bootcampStudents?.length !== 0
                ? "/dashboard/bootcamp-students"
                : ""
            }
            numbers={
              getBootcampStudentsLoading
                ? "Loading..."
                : bootcampStudents && bootcampStudents?.length !== 0
                ? bootcampStudents?.length
                : "Something's wrong"
            }
            onClick={() => handleViewDetails(bootcampStudents, false)}
          />

          {/* participansts card for digital marketing participants */}
          <ParticipantsCard
            title={"Digital Marketing Participants"}
            icon={<AiOutlineCalendar size={24} color="#1D2433A6" />}
            viewAllPath={
              getDigitalMarketingStudentsLoading
                ? ""
                : digitalMarketingStudents &&
                  digitalMarketingStudents?.length !== 0
                ? "/dashboard/bootcamp-students"
                : ""
            }
            numbers={
              getDigitalMarketingStudentsLoading
                ? "Loading..."
                : digitalMarketingStudents &&
                  digitalMarketingStudents?.length !== 0
                ? digitalMarketingStudents?.length
                : "Something's wrong"
            }
            onClick={() => handleViewDetails(digitalMarketingStudents, false)}
          />

          {/* participants card for critilique and launch event */}
          <ParticipantsCard
            title={"Critique & Lunch"}
            icon={<AiOutlineCalendar size={24} color="#1D2433A6" />}
            viewAllPath={
              critiqueEventParticipantsLoading
                ? ""
                : critiqueEventParticipants &&
                  critiqueEventParticipants?.length !== 0
                ? "/dashboard/bootcamp-students"
                : ""
            }
            numbers={
              critiqueEventParticipantsLoading
                ? "Loading..."
                : critiqueEventParticipants &&
                  critiqueEventParticipants?.length !== 0
                ? critiqueEventParticipants?.length
                : "Something's wrong"
            }
            onClick={() => handleViewDetails(critiqueEventParticipants, true)}
          />

          {/* participants card for design bootcamp */}
          <ParticipantsCard
            title={"March Into Design"}
            icon={<AiOutlineCalendar size={24} color="#1D2433A6" />}
            viewAllPath={
              getDesignBcampParticipantsLoading
                ? ""
                : designBootcampParticipants &&
                  designBootcampParticipants?.length !== 0
                ? "/dashboard/bootcamp-students"
                : ""
            }
            numbers={
              getDesignBcampParticipantsLoading
                ? "Loading..."
                : designBootcampParticipants &&
                  designBootcampParticipants?.length !== 0
                ? designBootcampParticipants?.length
                : "Something's wrong"
            }
            onClick={() => handleViewDetails(designBootcampParticipants, false)}
          />

          {/* participants card for data analysis bootcamp */}
          <ParticipantsCard
            title={"Data Analysis Bootcamp"}
            icon={<AiOutlineCalendar size={24} color="#1D2433A6" />}
            viewAllPath={
              getDataAnalysisFormLoading
                ? ""
                : dataAnalysisForm && dataAnalysisForm?.length !== 0
                ? "/dashboard/bootcamp-students"
                : ""
            }
            numbers={
              getDataAnalysisFormLoading
                ? "Loading..."
                : dataAnalysisForm && dataAnalysisForm?.length !== 0
                ? dataAnalysisForm?.length
                : "Something's wrong"
            }
            onClick={() => handleViewDetails(dataAnalysisForm, false)}
          />
        </div>

        {/* students and club detailed info */}
        <div className="grid grid-cols-[2fr_1fr] gap-x-7">
          <StudentDetailedInfo />

          <ClubDetailedInfo />
        </div>

        {/* Approval requests and Latest Events */}
        <div>
          <LatestEvents />
        </div>
      </section>

      {/* Table Modal */}
      <TableModal
        modalIsOpen={tableModalState}
        toggleModal={toggleTableModal}
        disableBtn={false}
      >
        <TableModalView participantList={listProp} isCritique={isCritique} />
      </TableModal>
    </section>
  );
};

export default HomeBody;
