import {useState} from 'react'
import BtnSm from '../../../Global/BtnSm'

const AddSpeakerPopup = ({handleAddSpeaker, loading, closeModal}) => {

    const [speakerData, setSpeakerData] = useState({
        name: "",
        about: "",
        imgurl: "",
    })

    return (
        <div className='p-5 rounded-[8px] bg-[#F8F9FC] flex flex-col w-full'>
            <form
            onSubmit={(e)=>e.preventDefault()} 
            className='flex flex-col gap-8 w-full'>

                {/* topic */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex items-center justify-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            Name
                        </span>
                    </div>

                    {/* input field */}
                    <input 
                    type="text" 
                    value={speakerData.name}
                    onChange={(e)=>setSpeakerData({
                        ...speakerData,
                        name: e.target.value
                    })}
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Name' />
                </label>

                {/* subtopics */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex flex-col gap-2 items-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            About
                        </span>
                    </div>

                    {/* input field */}
                    <input 
                    type="text" 
                    value={speakerData.about}
                    onChange={(e)=>setSpeakerData({
                        ...speakerData,
                        about: e.target.value
                    })}
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 

                    placeholder='About' />
                </label>

                {/* save changes and delete topics button */}
                <div className='flex w-full justify-between'>
                    <BtnSm
                    loading={loading}
                    onClick={()=>handleAddSpeaker(speakerData)}
                    title={"Add Speaker"} />

                    <button
                    onClick={closeModal}
                    className='font-semibold text-base text-[#B42318] bg-white rounded-[8px] border border-[#FDA29B] h-[44px] px-4 hover:opacity-60'>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddSpeakerPopup