import { HiUserCircle } from "react-icons/hi";
import EmptyDataState from "../../Global/EmptyDataState";
import LoadingCircles from "../../Global/LoadingCircles";

const StudentTable = ({ studentList, studentLoading, inputValue }) => {
  // HANDLERS
  const filteredData = studentList?.filter((student) => {
    //if no input the return the original
    if (inputValue === "") {
      return student;
    }

    //return the item which contains the student input
    else {
      return `${student.firstname.toLowerCase()} ${student.lastname.toLowerCase()}`?.includes(
        inputValue.toLowerCase()
      );
    }
  });

  return (
    <div className="bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] px-8 pb-12 flex flex-col gap-12">
      {/* table head */}
      <div className="border-b border-[#E1E6EF] text-sm font-semibold text-[#1D2433A6] grid grid-cols-[27%_27%_21%_15%_10%] py-7 gap-x-3">
        <span>Name</span>
        <span>Email address</span>
        <span>Phone number</span>
        <span>Session</span>
      </div>

      {/* table body */}
      <div className="flex flex-col gap-12">
        {studentLoading ? (
          <LoadingCircles />
        ) : studentList && studentList?.length !== 0 ? (
          <>
            {filteredData?.map((student, index) => (
              <div
                key={student?._id}
                className="grid grid-cols-[27%_27%_21%_15%_10%] gap-x-3 text-[#1D2433A6] text-sm font-normal items-center"
              >
                {/* name */}
                <div className="flex gap-3 items-center">
                  <span className="font-semibold text-sm">{index + 1}.</span>
                  <span>{`${student?.firstname} ${student?.lastname}`}</span>
                </div>

                {/* email address */}
                <span>{student?.email}</span>

                {/* phone number */}
                <span>{student?.mobile}</span>

                {/* session */}
                <span>{student?.session}</span>
              </div>
            ))}
          </>
        ) : (
          <div className="w-full h-[354px] flex items-center justify-center">
            <EmptyDataState />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentTable;
