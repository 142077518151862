import React, { useEffect, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import MyModal from '../../../../layouts/MyModal'
import BtnSm from '../../../Global/BtnSm'
import DeletePopup from '../../global/DeletePopup'
import AddTopicPopup from '../EditCourse/AddTopicPopup'
import TopicCard from '../EditCourse/TopicCard'
import emptyTopic from "../../../../assets/admindashboard/bootcamp/images/no-topic.png"

const CourseOutline = ({setCourseDetails, courseDetails}) => {

    // STATES
    // Modals
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [addTopicModalState, setAddTopicModalState] = useState(false)
    // Data
    const [topics, setTopics] = useState([])
    const [ deleteIndex, setDeleteIndex ] = useState(null)


    // HANDLERS
    // delete modal state
    const toggleDeleteModalState = () => {
        setDeleteModalState(!deleteModalState)
    }
    // add new topic modal state
    const toggleAddTopicModal = () => {
        setAddTopicModalState(!addTopicModalState)
    }
    const handleDelete = (id) => {
        const newArr = topics.filter(topic=>topic._id!==id)
        setTopics(newArr)
        updateCourse(newArr)
    }
    const handleAddTopic = (newTopic) => {
        setTopics([
            ...topics,
            newTopic
        ])
        updateCourse([
            ...topics,
            newTopic
        ])
    }
    const handleEditTopic = (editedTopic) => {
        const temp = topics.map(topic=>topic._id!==editedTopic._id?topic:editedTopic)
        setTopics(temp)
        updateCourse(temp)
    }
    const updateCourse = (data) => {
        setCourseDetails({
            ...courseDetails,
            topics: data
        })
    }

    // SIDE EFFECTS

    return (
        <div className='flex flex-col gap-10 w-full'>

            <div className='flex justify-between items-center w-full'>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    Course Outline
                </p>

                <button 
                onClick={toggleAddTopicModal}
                className='font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2'>
                    <AiOutlinePlusCircle size={24} />
                    <span>Add new topic</span>
                </button>
            </div>
            
            {/* Box collections */}
            <div className={`${topics.length===0?"flex w-full":"grid grid-cols-2"} gap-10`}>

                {/* list */}
                {
                    topics.length===0?
                    <div className='w-full flex flex-col items-center gap-5 mt-6'>
                        <img src={emptyTopic} className="w-[80px] h-[81.23px]" alt="" />
                        <p className='text-gray-500 font-normal text-base'>
                            No topics yet. Click on create new topics to get started.
                        </p>
                    </div>:
                    <>
                        {
                            topics?.map((topic) => (
                                <TopicCard
                                key={topic._id}
                                sub_topic={topic.sub_topics}
                                content={topic.content}
                                id={topic._id}
                                onSave={handleEditTopic}
                                setId={setDeleteIndex}
                                removeTopic={toggleDeleteModalState} />
                            ))
                        }
                    </>
                }
            </div>

            {/* modal to add new WYL */}
            <MyModal
            modalIsOpen={addTopicModalState}
            toggleModal={toggleAddTopicModal}
            disableBtn={true}>
                
                <AddTopicPopup
                onSave={handleAddTopic}
                closeModal={toggleAddTopicModal} />

            </MyModal>

            {/* delete modal popup */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
               <DeletePopup
               itemId={deleteIndex}
               onDelete={handleDelete}
               closeModal={toggleDeleteModalState} />
            </MyModal>
        </div>
    )
}

export default CourseOutline