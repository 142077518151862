import React from 'react'

const PromoSticker = () => {
    return (
        <p className='font-semibold text-[10px] rounded-[8px] bg-[#E02D3C] text-white w-fit px-3 h-[22px] flex justify-center items-center'>
            Promo price
        </p>
    )
}

export default PromoSticker