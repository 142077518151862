import React from 'react'
import TrackLabel from '../global/TrackLabel'

const TrackCards = ({trackTitle, trackId, bootcampNumber, bootcampTitle, numberOfStudents}) => {
    return (
        <div className='flex flex-col rounded-[8px] bg-[#F8F9FC] p-4 gap-4 h-fit'>

            {/* label */}
            <TrackLabel
            trackId={trackId}
            trackTitle={trackTitle} />

            {/* information */}
            <div className='flex gap-4 flex-col'>
                <p className='flex flex-col justify-start gap-1'>
                    <span className='text-[#3F444D] font-semibold text-base'>
                        Bootcamp {bootcampNumber}
                    </span>
                    <span className='text-[#3F444D] font-normal text-base'>
                        {bootcampTitle}
                    </span>
                </p>
                <p className='flex justify-between items-center'>
                    <span className='font-normal text-base text-[#3F444D]'>
                        No of students:
                    </span>
                    <span className='font-semibold text-sm text-gray-600'>
                        {numberOfStudents}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default TrackCards