import React from 'react'
import { FiServer } from 'react-icons/fi'
import { IoIosArrowForward } from 'react-icons/io'
import { useHistory } from 'react-router-dom'

const UsersCard = ({title, numbers, icon, viewAllPath}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex flex-col gap-4 bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] p-6 h-fit'>
            <div className='flex gap-4 '>
                <div className='h-[52px] w-[52px] flex justify-center items-center bg-[#E1E6EF] rounded-[8px]'>
                    {icon}
                </div>
                <div>
                    <p className='font-normal text-base text-gray-600 opacity-[0.65]'>
                        {title}
                    </p>
                    <p className='text-[#3F444D] font-semibold text-xl'>
                        {numbers.toLocaleString('en-US')}
                    </p>
                </div>
            </div>

            {
                viewAllPath?
                    <p 
                    onClick={()=>history.push(viewAllPath)}
                    className='flex gap-4 text-[#8B54F7] font-normal text-base items-center justify-start cursor-pointer'>
                        <span>View all</span>
                        <IoIosArrowForward size={18} />
                    </p>
                : null
            }
        </div>
    )
}

export default UsersCard