import {
  GET_BOOTCAMP_STUDENTS_FAIL,
  GET_BOOTCAMP_STUDENTS_LOADING,
  GET_BOOTCAMP_STUDENTS_REQUEST,
  GET_BOOTCAMP_STUDENTS_SUCCESS,
  GET_DIGITAL_MARKETING_STUDENTS_FAIL,
  GET_DIGITAL_MARKETING_STUDENTS_LOADING,
  GET_DIGITAL_MARKETING_STUDENTS_REQUEST,
  GET_DIGITAL_MARKETING_STUDENTS_SUCCESS,
} from "../types";

// GET BOOTCAMP STUDENTS
export const getBootcampStudentsRequest = () => {
  return {
    type: GET_BOOTCAMP_STUDENTS_REQUEST,
  };
};

export const getBootcampStudentsLoading = (loading) => {
  return {
    type: GET_BOOTCAMP_STUDENTS_LOADING,
    payload: loading,
  };
};

export const getBootcampStudentsSuccess = (prop) => {
  return {
    type: GET_BOOTCAMP_STUDENTS_SUCCESS,
    payload: prop,
  };
};

export const getBootcampStudentsFailure = (errors) => {
  return {
    type: GET_BOOTCAMP_STUDENTS_FAIL,
    payload: { errors },
  };
};

// GET DIGITAL MARKETING STUDENT
export const getDigitalMarketingStudentsRequest = () => {
  return {
    type: GET_DIGITAL_MARKETING_STUDENTS_REQUEST,
  };
};

export const getDigitalMarketingStudentsLoading = (loading) => {
  return {
    type: GET_DIGITAL_MARKETING_STUDENTS_LOADING,
    payload: loading,
  };
};

export const getDigitalMarketingStudentsSuccess = (prop) => {
  return {
    type: GET_DIGITAL_MARKETING_STUDENTS_SUCCESS,
    payload: prop,
  };
};

export const getDigitalMarketingStudentsFailure = (errors) => {
  return {
    type: GET_DIGITAL_MARKETING_STUDENTS_FAIL,
    payload: { errors },
  };
};
