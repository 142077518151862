import { useEffect, useState } from "react";
import BtnSm from "../../../Global/BtnSm";
import featuredImage from "../../../../assets/admindashboard/bootcamp/images/new-course-image.png";
import WhatYoullLearn from "./WhatYoullLearn";
import CourseBenefits from "./CourseBenefits";
import MyModal from "../../../../layouts/MyModal";
import FileUploadPopup from "../../global/FileUploadPopup";
import { FiCamera } from "react-icons/fi";

const GeneralInformation = ({ setCourseDetails, courseDetails }) => {
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");

  useEffect(() => {
    setCourseName(courseDetails.name);
    setCourseDescription(courseDetails.description);
  }, []);

  return (
    <div className="p-5 rounded-[10px] bg-[#F8F9FC] grid grid-cols-2 justify-between gap-x-16">
      {/* General informaion */}
      <div className="flex flex-col gap-16 w-full">
        {/* form */}
        <form
          className="flex flex-col gap-5 w-full"
          onSubmit={(e) => e.preventDefault()}
        >
          {/* form caption */}
          <p className="font-semibold text-xl text-[#3F444D]">
            General Information
          </p>

          {/* course name input container */}
          <label className="flex flex-col gap-2">
            {/* label text */}
            <div className="flex items-center justify-start">
              <span className="font-semibold text-sm text-[#344054]">
                Course name
              </span>
            </div>

            {/* input field */}
            <input
              type="text"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
              placeholder="Course name"
            />
          </label>

          {/* Course description container */}
          {/* <label className='flex flex-col gap-2'> */}

          {/* label text */}
          {/* <div className='flex items-center justify-start'>
                            <span className='font-semibold text-sm text-[#344054]'>
                                Course-sub name
                            </span>
                        </div> */}

          {/* input field */}
          {/* <input 
                        type="text" 
                        className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                        placeholder='Course sub-name' /> */}
          {/* </label> */}

          {/* Course description container */}
          <label className="flex flex-col gap-2">
            {/* label text */}
            <div className="flex items-center justify-start">
              <span className="font-semibold text-sm text-[#344054]">
                Course description
              </span>
            </div>

            {/* input field */}
            <textarea
              value={courseDescription}
              onChange={(e) => setCourseDescription(e.target.value)}
              className="border border-[#d0d5dd] text-base font-normal h-[116px] rounded-[8px] px-3 py-2 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none"
              placeholder="Description"
            />
          </label>

          {/* save changes button */}
          <div className="flex w-full justify-start">
            <BtnSm
              onClick={() => {
                setCourseDetails({
                  ...courseDetails,
                  name: courseName,
                  description: courseDescription,
                });
              }}
              title={"Save Changes"}
            />
          </div>
        </form>
      </div>

      {/* what you'll learn and course benefits */}
      <div className="flex flex-col gap-12 w-full">
        <WhatYoullLearn
          setCourseDetails={setCourseDetails}
          courseDetails={courseDetails}
        />
      </div>
    </div>
  );
};

export default GeneralInformation;
