import Select from 'react-select';
import "../AdminDashboard/global/styles/custom-select-styling.css"
import React from 'react'

const FormikSelect = ({
    options,
    field,
    form,
  }) => {
    return (
        <Select
        className='custom-select-container'
        options={options}
        name={field.name}
        value={options ? options.find(option => option.value === field.value) : ''}
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        />
    )
}

export default FormikSelect