import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { clearCreateCourseSuccess, clearDeleteCourseSuccess, clearPutSingleCourseSuccess, deleteCourseFailure, deleteCourseLoading, deleteCourseSuccess, getCoursesFailure, getCoursesLoading, getCoursesSuccess, getSingleCoursesFailure, getSingleCoursesLoading, getSingleCoursesSuccess, postCreateCourseFailure, postCreateCourseLoading, postCreateCourseSuccess, putEditSingleCourseFailure, putEditSingleCourseLoading, putEditSingleCourseSuccess } from "../action";
import { DELETE_COURSE_REQUEST, GET_COURSES_REQUEST, GET_SINGLE_COURSES_REQUEST, POST_CREATE_COURSE_REQUEST, PUT_EDIT_SINGLE_COURSE_REQUEST } from "../types";

const ajaxDBCalls = {
    getCourses: async () => {
        const reponse = await axios({
            method: "GET",
            url: "course"
        })
        return reponse
    },

    putEditCourse: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `course/${payload.courseId}`,
            data: payload.courseData
        })

        return response
    },

    getSingleCourse: async ({ payload }) => {
        const response = await axios({
          method: "GET",
          url: `course/${payload}`,
        });
    
        return response;
    },

    postCreateCourse: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `course/create`,
            data: payload.courseData
        })

        return response
    },

    deleteCourse: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: `course/${payload}`
        })

        return response
    },
}

// WORKERS
// get users
function* getCourses() {
    try {
        yield put(getCoursesLoading(true));

        const response = yield call(ajaxDBCalls.getCourses)

        yield put(getCoursesSuccess(response.data))
        yield put(getCoursesLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getCoursesFailure(errorMessage))
        yield put(getCoursesLoading(false))
        yield delay();
        yield put(getCoursesFailure(""))
    }
}

// get single course
function* getSingleCourse(payload) {
    try {
        yield put(getSingleCoursesLoading(true));
    
        const response = yield call(ajaxDBCalls.getSingleCourse, payload);
    
        yield put(getSingleCoursesSuccess(response.data));
        yield put(getSingleCoursesLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getSingleCoursesFailure(errorMessage));
        yield put(getSingleCoursesLoading(false));
        yield delay();
        yield put(getSingleCoursesFailure(""));
    }
}

// update course
function* putEditSingleCourse(payload) {
    try {
        yield put(putEditSingleCourseLoading(true));
        const response = yield call(ajaxDBCalls.putEditCourse, payload);
        yield put(putEditSingleCourseSuccess(response.data));
        yield put(putEditSingleCourseLoading(false));
        yield delay();
        yield put(clearPutSingleCourseSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(putEditSingleCourseFailure(errorMessage));
        yield put(putEditSingleCourseLoading(false));
        yield delay();
        yield put(putEditSingleCourseFailure(""));
    }
}

// create course
function* postCreateCourse(payload) {
    try {
        yield put(postCreateCourseLoading(true));
        const response = yield call(ajaxDBCalls.postCreateCourse, payload);
        yield put(postCreateCourseSuccess(response.data));
        yield put(postCreateCourseLoading(false));
        yield delay();
        yield put(clearCreateCourseSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(postCreateCourseFailure(errorMessage));
        yield put(postCreateCourseLoading(false));
        yield delay();
        yield put(postCreateCourseFailure(""));
    }
}

// delete course
function* deleteCourse(payload) {
    try {
        yield put(deleteCourseLoading(true));
        const response = yield call(ajaxDBCalls.deleteCourse, payload);
        yield put(deleteCourseSuccess(response.data));
        yield put(deleteCourseLoading(false));
        yield delay();
        yield put(clearDeleteCourseSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteCourseFailure(errorMessage));
        yield put(deleteCourseLoading(false));
        yield delay();
        yield put(deleteCourseFailure(""));
    }
}

// WATCHERS
function* getCoursesWatcher() {
    yield takeLatest(GET_COURSES_REQUEST, getCourses)
}

function* getSingleCourseWatcher() {
    yield takeLatest(GET_SINGLE_COURSES_REQUEST, getSingleCourse)
}

function* putEditSingleCourseWatcher() {
    yield takeLatest(PUT_EDIT_SINGLE_COURSE_REQUEST, putEditSingleCourse)
}

function* postCreateCourseWatcher() {
    yield takeLatest(POST_CREATE_COURSE_REQUEST, postCreateCourse)
}

function* deleteCourseWatcher() {
    yield takeLatest(DELETE_COURSE_REQUEST, deleteCourse)
}

// courses saga
export default function* coursesSaga() {
    yield spawn(getCoursesWatcher);
    yield spawn(getSingleCourseWatcher);
    yield spawn(putEditSingleCourseWatcher);
    yield spawn(postCreateCourseWatcher);
    yield spawn(deleteCourseWatcher);
}