import React from 'react'
import BootcampBody from '../../../components/AdminDashboard/Bootcamp/BootcampBody'
import SideNav from '../../../layouts/AdminDashboard/SideNav'

const Bootcamp = () => {
  return (
    <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>

        {/* Side nav */}
        <SideNav />

        {/* Body section */}
        <BootcampBody />
    </div>
  )
}

export default Bootcamp