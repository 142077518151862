import { GET_COURSES_SUCCESS, GET_SINGLE_COURSES_SUCCESS } from "../types"

const initialState = {
    courses: [],
    singleCourse: {}
}

const coursesReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_COURSES_SUCCESS:
    return { 
        ...state,
        courses: payload.courses
    }

  case GET_SINGLE_COURSES_SUCCESS:
    return { 
        ...state,
        singleCourse: payload.course
    }

  default:
    return state
  }
}

export default coursesReducer
