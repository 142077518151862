import {useState} from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const PasswordInput = ({onChange, onBlur, onFocus, value, }) => {

    const [isVisible, setIsVisible] = useState(false)

    return (
        <div className='relative w-full h-fit'>
            <input 
            type={`${isVisible?"text":"password"}`} 
            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base w-full placeholder:text-[#667085]' 
            placeholder='Enter your password'
            // value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus} />

            <span
            onClick={()=>setIsVisible(!isVisible)} 
            className='absolute right-[10px] top-[50%] translate-y-[-50%] z-[100] cursor-pointer'>
                {
                    isVisible?
                    <AiOutlineEye size={24} color={"#1D2433A6"} />:
                    <AiOutlineEyeInvisible size={24} color={"#1D2433A6"} />
                }
            </span>
        </div>
    )
}

export default PasswordInput