import { GET_CRITIQUE_EVENT_PARTICIPANT_FAIL, GET_CRITIQUE_EVENT_PARTICIPANT_LOADING, GET_CRITIQUE_EVENT_PARTICIPANT_REQUEST, GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS } from "../types";

// GET DIGITAL MARKETING STUDENT
export const getCritiqueEventParticipantRequest = () => {
    return {
      type: GET_CRITIQUE_EVENT_PARTICIPANT_REQUEST,
    };
  };
  
  export const getCritiqueEventParticipantLoading = (loading) => {
    return {
      type: GET_CRITIQUE_EVENT_PARTICIPANT_LOADING,
      payload: loading,
    };
  };
  
  export const getCritiqueEventParticipantSuccess = (prop) => {
    return {
      type: GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS,
      payload: prop,
    };
  };
  
  export const getCritiqueEventParticipantFailure = (errors) => {
    return {
      type: GET_CRITIQUE_EVENT_PARTICIPANT_FAIL,
      payload: { errors },
    };
  };
  