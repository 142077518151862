import React, { useEffect } from 'react'
import ProfileAvatar from '../global/ProfileAvatar'
import UsersListTable from './UsersListTable'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersRequest } from '../../../redux/action'

const UsersBody = () => {

    // dispatch object
    const dispatch = useDispatch()

    const {
        users,
        getUsersLoading
    } = useSelector((state)=>{
        const { users } = state.usersReducer
        const {getUsersLoading} = state.loadingIndicator
        return { users, getUsersLoading }
    })

    useEffect(()=>{
        dispatch(getUsersRequest())
    }, [])

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Users
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>

                <UsersListTable
                usersList={users}
                userLoading={getUsersLoading} />

            </section>
        </section>
    )
}

export default UsersBody