import React from 'react'
import GrayCheck from './GrayCheck'

const SingleSubjectText = ({item}) => {
    return (
        <p className='flex items-start gap-2 my-0'>
            <GrayCheck />
            <span className='text-[#667085] text-xs font-semibold'>
                {item}
            </span>
        </p>
    )
}

export default SingleSubjectText