import React, { useState, useEffect } from 'react'
import { HiUserCircle } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MyModal from '../../../layouts/MyModal'
import { clearUpdateClubSubscriptionSuccess, updateClubSubscriptionRequest, getClubSubscriptionsRequest } from '../../../redux/action'
import BtnSm from '../../Global/BtnSm'
import EmptyDataState from '../../Global/EmptyDataState'
import LoadingCircles from '../../Global/LoadingCircles'
import CustomPopup from '../global/CustomPopup'

const MembersTable = ({clubSubscriptions}) => {

    // OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // STATES
    // delete modal state
    const [deleteModalState, setDeleteModalState] = useState(false)
    // student id state
    const [clubSubscriptionId, setClubSubscriptionId] = useState("")
    // is_disabled action state
    const [isDisabled, setIsDisabled] = useState(null)
    // redux state
    const { 
        getClubSubscriptionsLoading,
        updateClubSubscriptionSuccess,
        updateClubSubscriptionLoading,
        deleteClubSubscriptionError 
    } = useSelector(state=>{

        // loading indicator slice
        const { 
            getClubSubscriptionsLoading ,
            updateClubSubscriptionLoading,
        } = state.loadingIndicator

        // ajax statuses slice
        const {
            success: {
                updateClubSubscription: updateClubSubscriptionSuccess
            },
            errors: {
                updateClubSubscription: deleteClubSubscriptionError
            }
        } = state.ajaxStatuses

        return {
          getClubSubscriptionsLoading,
          updateClubSubscriptionLoading,
          updateClubSubscriptionSuccess,
          deleteClubSubscriptionError
        }
    })


    // HANDLER
    // remove student from bootcamp function
    const upateUserClubSubscription = () => {
        const subscriptionData = {
            is_Ended: false,
            is_Disabled: isDisabled
        }
        dispatch(updateClubSubscriptionRequest({
            clubSubscriptionId,
            subscriptionData
        }))
    }
    // toggle the delete modal
    const toggleUpdateModalState = (id, is_disabled) => {
        setIsDisabled(is_disabled)
        setClubSubscriptionId(id)
        setDeleteModalState(!deleteModalState)
    }


    // SIDE EFFECTS
    // listen for error or success messages
    useEffect(() => {
        if (deleteClubSubscriptionError) {
            setDeleteModalState(false)
            toast.error(deleteClubSubscriptionError, {
                duration: 3000,
            });
        }
    }, [deleteClubSubscriptionError]);

    useEffect(() => {
        if (updateClubSubscriptionSuccess) {
            setDeleteModalState(false)
            toast.success(updateClubSubscriptionSuccess, {
                duration: 3000,
            });
            dispatch(clearUpdateClubSubscriptionSuccess())
            dispatch(getClubSubscriptionsRequest())
        }
    }, [updateClubSubscriptionSuccess]);


    return (
        <div className='bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] px-8 pb-12 flex flex-col gap-12'>

            {/* table head */}
            <div className='border-b border-[#E1E6EF] text-sm font-semibold text-[#1D2433A6] grid grid-cols-[22%_10%_25%_13%_30%] py-7 gap-x-3'>
                <span>Name</span> 
                <span>Date created</span>
                <span>Email address</span>
                <span>Package</span>
                <span>-</span>
            </div>

            {/* table body */}
            <div className='flex flex-col gap-12'>
                {
                    getClubSubscriptionsLoading?
                    <LoadingCircles />:
                    clubSubscriptions && clubSubscriptions.length!==0?
                   <> 
                        {
                            clubSubscriptions.map(({user_id, club_id, _id, is_Disabled})=>(
                                <div 
                                key={_id}
                                className='grid grid-cols-[22%_10%_25%_13%_30%] gap-x-3 text-[#1D2433A6] text-sm font-normal items-center'>

                                    {/* avatar and name */}
                                    <div className='flex gap-3 items-center'>
                                        <HiUserCircle size={29} />
                                        <span>{user_id.firstname+" "+user_id.lastname}</span>
                                    </div>

                                    {/* date created */}
                                    <span>{user_id?.createdAt?.split("T")[0]?.split("-")?.reverse()?.join("/") || "Null"}</span>

                                    {/* email address */}
                                    <span>{user_id.email}</span>

                                    {/* package */}
                                    <span>{club_id.name}</span>

                                    {/* delete club subscription button */}
                                    <div className='flex gap-3 items-center'>

                                        <BtnSm
                                        onClick={()=>toggleUpdateModalState(_id, false)}
                                        disabled={!is_Disabled}
                                        title={!is_Disabled?"Active":"Activate"} />

                                    
                                        <button
                                        onClick={()=>toggleUpdateModalState(_id, true)}
                                        disabled={is_Disabled}
                                        className='disabled:cursor-not-allowed disabled:text-[#D0D5DD] disabled:opacity-60 h-[44px] px-3 w-fit border border-[#D0D5DD] rounded-[8px] font-semibold text-sm text-[#344054] hover:text-[#D0D5DD] hover:opacity-90 ]'>
                                            {is_Disabled? "Not Active":"Deactivate"}
                                        </button>
                                    </div>

                                </div>
                            ))
                        }
                    </>:
                    <EmptyDataState />

                }
            </div>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleUpdateModalState}
            disableBtn={false}>
                <CustomPopup
                onClick={upateUserClubSubscription}
                loadinState={updateClubSubscriptionLoading}
                closeModal={toggleUpdateModalState} />
            </MyModal>
        </div>
    )
}

export default MembersTable