import { useState } from 'react'
import BtnSm from '../../../Global/BtnSm'
import { v4 as uuid } from 'uuid';

const AddTopicPopup = ({onSave, closeModal}) => {

    const unique_id = uuid();

    const [item, setItem] = useState({
        content: "",
        sub_topics: "",
        _id: null
    })

    return (
        <div className='p-5 rounded-[8px] bg-[#F8F9FC] flex flex-col w-full'>
            <form
            onSubmit={(e)=>e.preventDefault()} 
            className='flex flex-col gap-8 w-full'>

                {/* topic */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex items-center justify-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            Topic
                        </span>
                    </div>

                    {/* input field */}
                    <input 
                    type="text" 
                    value={item.sub_topics}
                    onChange={(e)=>setItem({
                        ...item,
                        sub_topics: e.target.value
                    })}
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='topic' />
                </label>

                {/* subtopics */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex flex-col gap-2 items-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            Subtopics
                        </span>

                        <span className='text-[#667085] font-normal text-sm'>
                            Use “&” to seperate different subtopics
                        </span>
                    </div>

                    {/* input field */}
                    <textarea
                    value={item.content}
                    onChange={(e)=>setItem({
                        ...item,
                        content: e.target.value
                    })}
                    className='border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none h-[92px] py-2' 

                    placeholder='subtopic' />
                </label>

                {/* save changes and delete topics button */}
                <div className='flex w-full justify-between'>
                    <BtnSm
                    onClick={()=>{
                        onSave({
                            ...item,
                            _id: unique_id
                        })
                        closeModal()
                        setItem({
                            content: "",
                            sub_topics: "",
                            _id: null
                        })
                    }}
                    title={"Save Changes"} />

                    <button
                    onClick={closeModal}
                    className='font-semibold text-base text-[#B42318] bg-white rounded-[8px] border border-[#FDA29B] h-[44px] px-4 hover:opacity-60'>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddTopicPopup