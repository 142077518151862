import React from 'react'
import { FiZap } from 'react-icons/fi'
import { CircularProgress } from "@material-ui/core";

const CustomPopup = ({closeModal, onClick, loadinState}) => {

    return (
        <div className='bg-white flex flex-col gap-5 w-full p-6'>
            
            <div className='flex items-center justify-center h-[48px] w-[48px] bg-[#F8F9FC] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[50%]'>
                <span className='flex items-center justify-center bg-[#e8e0f7] h-[24px] w-[24px] rounded-[50%]'>
                    <FiZap size={12} color="#7F56D9 " />
                </span>
            </div>

            <p className='text-[#111827] text-base font-semibold text-center'>
                Are you sure?
            </p>

            {/* btn containers */}
            <div className='flex flex-col items-stretch w-full gap-4 mt-3'>

                <button 
                onClick={onClick}
                className='text-white font-semibold text-base rounded-[6px] h-[44px] bg-[#7F56D9]  hover:opacity-80'>
                    {loadinState ? 
                        (
                            <div className="text-center">
                                <CircularProgress color={"inherit"} size="20px" />
                            </div>
                        ) : 
                        (
                            "Yes, proceed"
                        )
                    }
                </button>

                <button 
                onClick={closeModal}
                className='text-[#374151] font-medium text-base rounded-[6px] h-[44px] bg-white border border-[#D1D5DB] hover:bg-gray-50'>
                    No, don’t
                </button>

            </div>
        </div>
    )
}

export default CustomPopup