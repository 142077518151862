import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import Bootcamp from ".";
import ViewFullDetails from "./ViewFullDetails";
import EditCourse from "./EditCourse";
import CreateNewCourse from "./CreateNewCourse";

const BootcampRouter = ({path}) => {
    
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={Bootcamp} />

            <Route 
            exact 
            path={`${path}/:id`}
            component={ViewFullDetails} />

            <Route 
            exact 
            path={`${path}/edit/:id`}
            component={EditCourse} />

            <Route 
            exact 
            path={`${path}/create/new-course`}
            component={CreateNewCourse} />

        </ Switch>
    )
}

export default BootcampRouter