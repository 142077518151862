import React, { useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import BootcampCard from "./BootcampCard";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import EmptyDataState from "../../Global/EmptyDataState";
import LoadingCircles from "../../Global/LoadingCircles";
import { timeSpent } from "../../../helpers";

const AvailableBootcamps = ({availableBootcamps}) => {

  // history object
  const history = useHistory();

  const { getBootcampsAdminLoading } = useSelector(state=>{
    const { getBootcampsAdminLoading } = state.loadingIndicator
    return {
      getBootcampsAdminLoading
    }
  })

const subTitles = ["front-end", "back-end"]

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-xl text-[#3F444D]">
          Available bootcamps
        </p>

        <button
          onClick={() =>
            history.push("/dashboard/bootcamps/create/new-bootcamp")
          }
          className="font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-12 flex items-center justify-center gap-2"
        >
          <AiOutlinePlusCircle size={24} />
          <span>Add new bootcamps</span>
        </button>
      </div>

      <div className="bg-white rounded-[10px] p-5">

          {

            getBootcampsAdminLoading?
            <LoadingCircles />:
            availableBootcamps.length===0?
            <EmptyDataState />:
            <div className="mt-4 grid grid-cols-3 gap-9 py-4 lg:grid-cols-[40%_40%] container justify-center mx-auto">
              {
                availableBootcamps.map((bootcamp, index)=>(
                  <BootcampCard
                    key={bootcamp._id}
                    linkId={bootcamp._id}
                    bootcampNumber={index+1}
                    bootcampTitle={bootcamp.name.split("(")[0]}
                    subTitle={subTitles.includes(bootcamp.description.split(" ")[0].toLowerCase())? `(${bootcamp.description.split(" ")[0]})`:`(${bootcamp.name.split("(")[1]}`}
                    trackId={bootcamp.course_type === "regular"?1:2}
                    trackTitle={bootcamp.course_type === "regular"?"Regular":"Special"}
                    monthsRemaining={bootcamp.duration - timeSpent(bootcamp.start_date)}
                    percent={Math.floor((timeSpent(bootcamp.start_date) / (bootcamp.duration)) * 100)}                      
                  />
                ))
              }
            </div>
          }
      </div>
    </div>
  );
};

export default AvailableBootcamps;
