import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ProfileAvatar from '../../../components/AdminDashboard/global/ProfileAvatar'
import UserInfoCard from '../../../components/AdminDashboard/Users/UserInfoCard'
import EmptyDataState from '../../../components/Global/EmptyDataState'
import LoadingCircles from '../../../components/Global/LoadingCircles'
import SideNav from '../../../layouts/AdminDashboard/SideNav'
import { getSingleUserRequest, updateUserRequest } from '../../../redux/action'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'

const EditUser = () => {

    // OBJECT INITIALIZATION
    // history object
    const history = useHistory();
    // dispatch object
    const dispatch = useDispatch()
    // location object
    const location = useLocation()
    // creating the path object variable
    const tempArrPath =  location.pathname.split("/");
    const path = tempArrPath.slice(tempArrPath.length-1)[0]
    // formik object
    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            mobile: "",
            date_of_birth: "",
            reason: "",
            occupation: ""
        },
        onSubmit: (values) => {
            const finalObj = {...values}
            finalObj.date_of_birth = new Date(values.date_of_birth).toISOString()
            dispatch(updateUserRequest({
                userData: finalObj,
                userId: path
            }))
        },
        validationSchema: Yup.object({
            firstname: Yup.string().min(3).required(),
            lastname: Yup.string().min(3).required(),
            mobile: Yup.number("Input should be a number").required(),
            date_of_birth: Yup.date().required(),
            reason: Yup.string().required(),
            occupation:Yup.string().required()
        })
    })


    // STATES
    // users' details, state
    const [userDetails, setUserDetails] = useState({})
    // redux states
    const { 
        singleUser, 
        getSingleUserLoading,
        updateUserSuccess,
        updateUserError,
        updateUserLoading
    } = useSelector(state=>{
        const { 
            getSingleUserLoading, 
            updateUserLoading 
        } = state.loadingIndicator
        const { singleUser } = state.usersReducer
        const {
            success: { updateUser: updateUserSuccess },
            errors: { updateUser: updateUserError }
        } = state.ajaxStatuses
        return {
            getSingleUserLoading,
            singleUser,
            updateUserSuccess,
            updateUserError,
            updateUserLoading
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleUserRequest(path))
    }, [])
    useEffect(()=>{
        if(singleUser && Object.keys(singleUser).length!==0){
            formik.setValues((values)=>({
                ...values,
                firstname: singleUser.firstname,
                lastname: singleUser.lastname,
                mobile: singleUser.mobile,
                date_of_birth: singleUser.date_of_birth.split("T")[0],
                reason: singleUser.reason,
                occupation: singleUser.occupation
            }))
        }
    }, [singleUser])

    // check for success and errors
    useEffect(() => {
        if (updateUserError) {
          toast.error(updateUserError, {
            duration: 3000,
          });
        }
    }, [updateUserError]);
    useEffect(() => {
        if (updateUserSuccess) {
          toast.success(updateUserSuccess, {
            duration: 3000,
          });
          history.replace("/dashboard/users")
        }
    }, [updateUserSuccess]);

    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>
            
            {/* Side nav */}
            <SideNav />

            {/* edit user body */}
            <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

                {/* header */}
                <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                    <div className='flex flex-col gap-7'>
                        <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                            Users
                        </p>

                        <p 
                        onClick={()=>history.goBack()}
                        className='flex items-center justify-start gap-3 cursor-pointer
                        '>
                            <IoIosArrowBack 
                            className="text-[#8B54F7]"
                            size={18} />
                            <span className='font-normal text-base text-gray-500'>
                                Back
                            </span>
                        </p>
                    </div>

                    {/* profile dp */}
                    <div className='mobile:hidden'>
                        <ProfileAvatar />
                    </div>
                </header>

                {/* body section */}
                <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>
                    
                    {/* User Details Body */}
                    <div className='rounded-[10px] bg-white flex flex-col px-7 py-9 gap-12'>

                        {
                            getSingleUserLoading?
                            <LoadingCircles />:
                            (singleUser && Object.keys(singleUser).length === 0)?
                            <EmptyDataState />:
                                <>
                                    <UserInfoCard
                                    formik={formik}
                                    userEmail={singleUser.email}
                                    userId={singleUser._id}
                                    userDetails={singleUser}
                                    updateUserLoading={updateUserLoading} />
                                </>
                        }

                    </div>
                </section>
            </section>
        </div>
    )
}

export default EditUser