import React from "react";
import { Link } from "react-router-dom";

const EventCard = ({ weekday, image, day, month, time, venue, title, desc, to }) => {

  return (
    <div className="flex flex-col [box-shadow:0px_2px_8px_#e0e0e0] bg-white rounded-[10px] p-3 mobile:p-4 lg:p-4 gap-4 w-fit mobile:w-full">
      {/*image */}
      <div className="w-full relative flex flex-col">
        <img src={image} className="object-cover rounded-[10px] h-[184px]" alt="" />

        {/* dark transparent bg */}
        <span className="absolute h-full w-full bg-black opacity-40 z-1 rounded-[10px]">
          {""}
        </span>

        {/* date stamp */}
        <div className="bg-[#F8F5FF] rounded-[8px] text-[#1D2433] absolute top-[12px] left-[12px] gap-2 py-4 px-5 flex flex-col items-center justify-center z-2">
          <span className="font-normal text-base leading-3">{month}</span>
          <span className="font-semibold text-2xl leading-3">{day}</span>
        </div>
      </div>

      {/* texts */}
      <div className="flex flex-col items-start justify-center py-4 gap-1 break-words pr-4">
        <p className="flex flex-col text-xs font-normal text-[#6c707a]">
          <span>{weekday + ", " +  time}</span>
          <span>{venue}</span>
        </p>

        <p className="text-xl xl:text-sm leading-[24px] font-semibold text-[#1D2433]">
          {title}
        </p>

        <p className="font-normal text-sm text-[#1D2433] opacity-80">
          {desc}
        </p>

        <Link
          to={`/dashboard/events/${to}`}
          className="justify-self-end font-semibold hover:no-underline hover:text-[#8B54F7] text-base mobile:text-xs text-[#8B54F7] xl:text-sm mt-3"
        >
          LEARN MORE
        </Link>
      </div>
    </div>
  );
};

export default EventCard;
