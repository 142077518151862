import React, { useEffect, useState } from 'react'
import { FiCamera } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom'
import { DatePicker } from 'rsuite'
import MyModal from '../../../../layouts/MyModal'
import BtnSm from '../../../Global/BtnSm'
import FileUploadPopup from '../../global/FileUploadPopup'
import ProfileAvatar from '../../global/ProfileAvatar'
import addNewEventImage from "../../../../assets/admindashboard/events/images/new-event-image.png"
import AddGuests from './AddGuests'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { toast } from 'react-toastify'
import { postCreateEventRequest } from '../../../../redux/action'
import { FormError } from '../../../Global/Error'
import SubmitBtn from '../../../Global/SubmitBtn'
import moment from 'moment'

const BodySection = () => {

    
    // STATES
    // guestError
    const [guestError, setGuestError] = useState(true)
    // guests
    const [eventGuests, setGuests] = useState([])
    // modal state for saved account info
    const [uploadFileModalState, setUploadFileModalState] = useState(false)
    // image url state
    const [imageUrl, setImageUrl] = useState("")
    // single bootcamp state
    const {
        createEventLoading,
        createEventSuccess,
        createEventError
    } = useSelector((state) => {
        const { 
            createEventLoading,
        } = state.loadingIndicator;
        const {
        success: { createEvent: createEventSuccess },
        errors: { createEvent: createEventError },
        } = state.ajaxStatuses;
        return {
            createEventLoading,
            createEventError,
            createEventSuccess,
        };
    });


    // OBJECT INITIALIZATION
    // history object
    const history = useHistory();
    // dispatch object
    const dispatch = useDispatch();
    // Formik value
    const formik = useFormik({
        initialValues: {
            name: "",
            imgurl: "",
            description: "",
            location: "",
            date: "",
            time: "",
            speakers: []
        },
        onSubmit: (values) => {
            const finalObj = { ...values };
            finalObj.date = new Date(values.date).toISOString();
            finalObj.imgurl = imageUrl
            finalObj.time = moment(values.time, ["HH.mm"]).format("hh:mm a");
            finalObj.speakers = [...eventGuests]

            // dispatch Action
            dispatch(
                postCreateEventRequest(finalObj)
            );
        },
        validationSchema: Yup.object({
            name: Yup.string().min(4).required(),
            description: Yup.string().min(7).required(),
            location: Yup.string().min(4).required(),
            date: Yup.date().required(),
            time: Yup.string().required(),
        }),
    });


    // HANDLERS
    // toggle saved account info
    const toggleUploadFileModal = () => {
        setUploadFileModalState(!uploadFileModalState)
    }

    
    // SIDE EFFECTS
    // check for success and errors
    useEffect(() => {
        if (createEventError) {
            toast.error(createEventError, {
                duration: 3000,
            });
        }
    }, [createEventError]);
    useEffect(() => {
        if (createEventSuccess) {
            toast.success(createEventSuccess, {
                duration: 3000,
            });
            history.replace("/dashboard/events");
        }
    }, [createEventSuccess]);


    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Create new event
                    </p>

                    <p 
                    onClick={()=>history.goBack()}
                    className='flex items-center justify-start gap-3 cursor-pointer
                    '>
                        <IoIosArrowBack 
                        className="text-[#8B54F7]"
                        size={18} />
                        <span className='font-normal text-base text-gray-500'>
                            Back
                        </span>
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-16'>
                
                {/* container */}
                <div className='rounded-[10px] bg-white flex flex-col py-10 px-5 w-full h-full'>

                    <div className='bg-[#F8F9FC] p-5 rounded-[10px] w-full h-full grid grid-cols-2 gap-16'>

                        {/* event infor2 */}
                        <div className='w-full flex flex-col gap-12'>

                            {/* event info form1 */}
                            <form 
                            onSubmit={(e)=>e.preventDefault()}
                            className='flex flex-col gap-5 w-full'>

                                {/* Event name input container */}
                                <label className='flex flex-col gap-2'>

                                    {/* label text */}
                                    <div className='flex items-center justify-start'>
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            Event name
                                        </span>
                                    </div>

                                    {/* input field */}
                                    <input 
                                    type="text" 
                                    {...formik.getFieldProps("name")}
                                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                    placeholder='Enter event name here' />
                                    {formik.touched.name && formik.errors.name && (
                                        <FormError message={formik.errors.name} />
                                    )}
                                </label>

                                <label className='flex flex-col gap-2'>

                                    {/* label text */}
                                    <div className='flex items-center justify-start'>
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            About event
                                        </span>
                                    </div>

                                    {/* input field */}
                                    <textarea 
                                    {...formik.getFieldProps("description")}
                                    className='border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2 h-[350px]' 
                                    placeholder='About event' />
                                    {formik.touched.description && formik.errors.description && (
                                        <FormError message={formik.errors.description} />
                                    )}
                                </label>
                            </form>

                            <AddGuests
                            addGuests={setGuests}
                            setError={setGuestError} />                            

                            {/* featured image */}
                            <div className='flex flex-col gap-3'>
                                <p className='text-[#344054] text-xl font-semibold'>
                                    Featured Image
                                </p>

                                <div className='relative w-fit'>
                                    <img src={imageUrl?imageUrl:addNewEventImage} className="w-[296px] h-[184px] rounded-[10px] object-cover" alt="" />

                                    <span 
                                    onClick={toggleUploadFileModal}
                                    className='bg-white rounded-[50%] flex items-center justify-center w-[40px] h-[40px] absolute top-[10px] right-[10px] active:bg-gray-100 cursor-pointer'>
                                        <FiCamera color="#8B54F7" size={22} />
                                    </span>
                                </div>
                            </div>

                        </div>
                        
                        {/* event infor2 */}
                        <div className='w-full'>

                            {/* event infor form2 */}
                            <form 
                            onSubmit={(e)=>e.preventDefault()}
                            className='flex flex-col gap-5 w-full'>

                                <label className='flex flex-col gap-2'>

                                    {/* label text */}
                                    <div className='flex items-center justify-start'>
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            Event address
                                        </span>
                                    </div>

                                    {/* input field */}
                                    <textarea 
                                    {...formik.getFieldProps("location")}
                                    className='border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2 h-[68px]' 
                                    placeholder='Event address' />
                                    {formik.touched.location && formik.errors.location && (
                                        <FormError message={formik.errors.location} />
                                    )}
                                </label>

                                {/* Date input container */}
                                <label className='flex flex-col gap-2'>

                                    {/* label text */}
                                    <div className='flex items-center justify-start'>
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            Event date
                                        </span>
                                    </div>

                                    {/* input field */}
                                    <input 
                                    type="date" 
                                    {...formik.getFieldProps("date")}
                                    className='w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                                    placeholder='Start date' />
                                    {/* <DatePicker
                                    className='w-full'
                                    editable={false}
                                    {...formik.getFieldProps("date")}
                                    placement='bottomStart'
                                    placeholder="Start date"
                                    size='lg'
                                    /> */}
                                    {formik.touched.date && formik.errors.date && (
                                        <FormError message={formik.errors.date} />
                                    )}
                                </label>

                                {/* Time input container */}
                                <label className='flex flex-col gap-2'>

                                    {/* label text */}
                                    <div className='flex items-center justify-start'>
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            Event time
                                        </span>
                                    </div>
                                    <input 
                                    type="time" 
                                    {...formik.getFieldProps("time")}
                                    pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                                    className='w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                                    placeholder='Start time' />
                                    {/* <DatePicker
                                    format="hh:mm aa"
                                    placement='bottomStart'
                                    ranges={[
                                        {
                                            label: 'Now',
                                            value: new Date()
                                        }
                                    ]}
                                    className='w-full'
                                    showMeridian
                                    {...formik.getFieldProps("time")}
                                    editable={false}
                                    placeholder="Start time"
                                    size='lg'
                                    /> */}
                                    {formik.touched.time && formik.errors.time && (
                                        <FormError message={formik.errors.time} />
                                    )}
                                </label>
                            </form>
                        </div>
                    </div>
                    <div className="w-[40%] flex flex-col items-stretch justify-center mx-auto mt-5">
                        <SubmitBtn
                        loading={createEventLoading}
                        onSubmit={()=>{
                            if(guestError || !imageUrl) {
                                toast.error("Please fill out the provided fields")
                            } else {
                                formik.handleSubmit()
                            }
                        }}
                        title={"Create Event"} />
                    </div>
                </div>
            </section>

            <MyModal
            disableBtn={false}
            modalIsOpen={uploadFileModalState}
            toggleModal={toggleUploadFileModal}>
                <FileUploadPopup
                closeModal={toggleUploadFileModal}
                setImage={setImageUrl} />
            </MyModal>
        </section>
    )
}

export default BodySection