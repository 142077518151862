import React from 'react'
import { HiUserCircle } from 'react-icons/hi'

const List = ({userList, onSelectUser, inputValue}) => {

    const filteredData = userList.filter((user) => {
        //if no input the return the original
        if (inputValue === '') {
            return user;
        }
        //return the item which contains the user input
        else {
            return `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`?.includes(inputValue.toLowerCase())
        }
    })

    return (
        <div className='flex flex-col gap-3'>
            {
                filteredData?.map((item)=>(
                    <div 
                    onClick={()=>{
                        const newName = item.firstname + " " + item.lastname
                        const newId = item._id
                        onSelectUser(newId, newName)
                    }}
                    key={item._id}
                    className='flex gap-2 items-center mr-auto hover:bg-[#F8F5FF] w-full py-3 px-2 rounded-[8px]'>
                        <HiUserCircle size={38} />

                        <span className='text-gray-600 font-normal text-sm'>
                            {item.firstname + " " + item.lastname}
                        </span>
                    </div>
                ))
            }
        </div>
    )
}

export default List