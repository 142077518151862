import React from 'react'
import Modal from "react-modal"
import { GrClose } from "react-icons/gr"
import "./index.css"

const TableModal = ({modalIsOpen, toggleModal, children, disableBtn}) => {
    return (
        <div 
        className='div-modal'>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal-table"
            overlayClassName="modal-overlay"
            closeTimeoutMS={500}
            ariaHideApp={false}>

                {/* modal content */}
                <div className="model-item-cont">

                    {
                        !disableBtn && 

                        // modal close button
                        <span 
                        className="modal-close-bt h-[40px] inline-flex justify-center items-center px-3 rounded-[8px] bg-[#5221B5] text-white hover:opacity-80 font-normal text-base" 
                        onClick={toggleModal}>
                            Close
                        </span>
                    }

                    {/* children */}
                    {children}
                </div>
            </Modal>
        </div>
    )
}

export default TableModal