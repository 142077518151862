import { CLEAR_CREATE_BOOTCAMP_SUCCESS, CLEAR_DELETE_BOOTCAMP_SUCCESS, CLEAR_DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS, CLEAR_PUT_EDIT_BOOTCAMP_SUCCESS, CLEAR_PUT_STUDENT_BOOTCAMP_SUCCESS, DELETE_BOOTCAMP_FAIL, DELETE_BOOTCAMP_LOADING, DELETE_BOOTCAMP_REQUEST, DELETE_BOOTCAMP_SUCCESS, DELETE_STUDENT_FROM_BOOTCAMP_FAIL, DELETE_STUDENT_FROM_BOOTCAMP_LOADING, DELETE_STUDENT_FROM_BOOTCAMP_REQUEST, DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS, GET_BOOTCAMPS_ADMIN_FAIL, GET_BOOTCAMPS_ADMIN_LOADING, GET_BOOTCAMPS_ADMIN_REQUEST, GET_BOOTCAMPS_ADMIN_SUCCESS, GET_SINGLE_BOOTCAMPS_FAIL, GET_SINGLE_BOOTCAMPS_LOADING, GET_SINGLE_BOOTCAMPS_REQUEST, GET_SINGLE_BOOTCAMPS_SUCCESS, POST_CREATE_BOOTCAMP_FAIL, POST_CREATE_BOOTCAMP_LOADING, POST_CREATE_BOOTCAMP_REQUEST, POST_CREATE_BOOTCAMP_SUCCESS, PUT_EDIT_BOOTCAMP_FAIL, PUT_EDIT_BOOTCAMP_LOADING, PUT_EDIT_BOOTCAMP_REQUEST, PUT_EDIT_BOOTCAMP_SUCCESS, PUT_STUDENT_TO_BOOTCAMP_FAIL, PUT_STUDENT_TO_BOOTCAMP_LOADING, PUT_STUDENT_TO_BOOTCAMP_REQUEST, PUT_STUDENT_TO_BOOTCAMP_SUCCESS } from "../types"

// get bootcamps for admin
export const getBootcampsAdminRequest = () => {
    return {
        type: GET_BOOTCAMPS_ADMIN_REQUEST
    }
}

export const getBootcampsAdminSuccess = (prop) => {
    return {
        type: GET_BOOTCAMPS_ADMIN_SUCCESS,
        payload: prop,
    };
};
  
export const getBootcampsAdminFailure = (errors) => {
    return {
        type: GET_BOOTCAMPS_ADMIN_FAIL,
        payload: { errors },
    };
};

export const getBootcampsAdminLoading = (loading) => {
    return {
        type: GET_BOOTCAMPS_ADMIN_LOADING,
        payload: loading,
    };
};

// GET SINGLE BOOTCAMP
export const getSingleBootcampRequest = (prop) => {
    return {
        type: GET_SINGLE_BOOTCAMPS_REQUEST,
        payload: prop,
    };
};

export const getSingleBootcampLoading = (loading) => {
    return {
        type: GET_SINGLE_BOOTCAMPS_LOADING,
        payload: loading,
    }
}

export const getSingleBootcampSuccess = (prop) => {
    return {
        type: GET_SINGLE_BOOTCAMPS_SUCCESS,
        payload: prop,
    }
}

export const getSingleBootcampFailure = (errors) => {
    return {
        type: GET_SINGLE_BOOTCAMPS_FAIL,
        payload: { errors }
    }
}

// PUT EDIT SINGLE BOOTCAMP
export const putEditSingleBootcampRequest = (prop) => {
    return {
        type: PUT_EDIT_BOOTCAMP_REQUEST,
        payload: prop
    }
}

export const putEditSingleBootcampLoading = (loading) => {
    return {
        type: PUT_EDIT_BOOTCAMP_LOADING,
        payload: loading
    }
}

export const putEditSingleBootcampSuccess = (prop) => {
    return {
        type: PUT_EDIT_BOOTCAMP_SUCCESS,
        payload: prop
    }
}


export const putEditSingleBootcampFailure = (errors) => {
    return {
        type: PUT_EDIT_BOOTCAMP_FAIL,
        payload: { errors }
    }
}

export const clearPutEditSingleBootcampSuccess = () => {
    return {
        type: CLEAR_PUT_EDIT_BOOTCAMP_SUCCESS
    }
}

// POST CREATE BOOTCAMP
export const postCreateBootcampRequest = (prop) => {
    return {
        type: POST_CREATE_BOOTCAMP_REQUEST,
        payload: prop
    }
}

export const postCreateBootcampLoading = (loading) => {
    return {
        type: POST_CREATE_BOOTCAMP_LOADING,
        payload: loading
    }
}

export const postCreateBootcampSuccess = (prop) => {
    return {
        type: POST_CREATE_BOOTCAMP_SUCCESS,
        payload: prop
    }
}


export const postCreateBootcampFailure = (errors) => {
    return {
        type: POST_CREATE_BOOTCAMP_FAIL,
        payload: { errors }
    }
}

export const clearCreateBootcampSuccess = () => {
    return {
        type: CLEAR_CREATE_BOOTCAMP_SUCCESS
    }
}

// DELETE CREATE BOOTCAMP
export const deleteBootcampRequest = (prop) => {
    return {
        type: DELETE_BOOTCAMP_REQUEST,
        payload: prop
    }
}

export const deleteBootcampLoading = (loading) => {
    return {
        type: DELETE_BOOTCAMP_LOADING,
        payload: loading
    }
}

export const deleteBootcampSuccess = (prop) => {
    return {
        type: DELETE_BOOTCAMP_SUCCESS,
        payload: prop
    }
}


export const deleteBootcampFailure = (errors) => {
    return {
        type: DELETE_BOOTCAMP_FAIL,
        payload: { errors }
    }
}

export const clearDeleteBootcampSuccess = () => {
    return {
        type: CLEAR_DELETE_BOOTCAMP_SUCCESS
    }
}

// DELETE STUDENT FROM BOOTCAMP
export const deleteStudentFromBootcampRequest = (prop) => {
    return {
        type: DELETE_STUDENT_FROM_BOOTCAMP_REQUEST,
        payload: prop
    }
}

export const deleteStudentFromBootcampLoading = (loading) => {
    return {
        type: DELETE_STUDENT_FROM_BOOTCAMP_LOADING,
        payload: loading
    }
}

export const deleteStudentFromBootcampSuccess = (prop) => {
    return {
        type: DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS,
        payload: prop
    }
}


export const deleteStudentFromBootcampFailure = (errors) => {
    return {
        type: DELETE_STUDENT_FROM_BOOTCAMP_FAIL,
        payload: { errors }
    }
}

export const clearDeleteStudentFromBootcampSuccess = () => {
    return {
        type: CLEAR_DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS
    }
}

// PUT ADD STUDENT TO BOOTCAMP
export const putAddStudentToBootcampRequest = (prop) => {
    return {
        type: PUT_STUDENT_TO_BOOTCAMP_REQUEST,
        payload: prop
    }
}

export const putAddStudentToBootcampLoading = (loading) => {
    return {
        type: PUT_STUDENT_TO_BOOTCAMP_LOADING,
        payload: loading
    }
}

export const putAddStudentToBootcampSuccess = (prop) => {
    return {
        type: PUT_STUDENT_TO_BOOTCAMP_SUCCESS,
        payload: prop
    }
}


export const putAddStudentToBootcampFailure = (errors) => {
    return {
        type: PUT_STUDENT_TO_BOOTCAMP_FAIL,
        payload: { errors }
    }
}

export const clearAddStudentToBootcampSuccess = () => {
    return {
        type: CLEAR_PUT_STUDENT_BOOTCAMP_SUCCESS
    }
}