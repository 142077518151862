import React from "react";
import { Switch, Route } from "react-router-dom";
import BootcampRouter from "./Bootcamp/router";
import BootcampsRouter from "./Bootcamps/router";
import ClubMembersRouter from "./ClubMembers/router";
import ClubSubscriptionRouter from "./ClubSubscription/router";
import EventRouter from "./Events/router";
import HomeRouter from "./Home/router";
import MailRouter from "./Mail/router";
import SettingsRouter from "./Settings/router";
import SpaceBookingRouter from "./SpaceBooking/router";
import UsersRouter from "./Users/router";
import BootcampStudentRouter from "./Students/router"

const AdminDashboard = ({ match: { path } }) => {

    return (
        <Switch>
          
            <HomeRouter
            path={`${path}/home`} />

            <UsersRouter
            path={`${path}/users`} />

            <BootcampsRouter
            path={`${path}/bootcamps`} />

            <BootcampRouter
            path={`${path}/course`} />

            <EventRouter
            path={`${path}/events`} />

            <SettingsRouter
            path={`${path}/settings`} />

            <ClubMembersRouter
            path={`${path}/club-members`} />

            <ClubSubscriptionRouter
            path={`${path}/club-subscription`} />

            <SpaceBookingRouter
            path={`${path}/space-booking`} />

            <BootcampStudentRouter
            path={`${path}/bootcamp-students`} />

            <MailRouter
            path={`${path}/mail`} />

        </Switch>
    );
};

export default AdminDashboard;
