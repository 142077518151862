import React, { useEffect, useState } from 'react'
import BtnSm from '../../Global/BtnSm'
import Select from 'react-select';
import "../global/styles/custom-select-styling.css"
import { getBootcampsAdminRequest, putAddStudentToBootcampRequest } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

const AddUserBootcamp = ({userEmail}) => {

    // OBJECTS INITIALIZATION
    const trackData = [
        { value: 'regular', label: 'Regular' },
        { value: 'pathway', label: 'Special' },
    ];
    // history object
    const history = useHistory();
    // dispatch object
    const dispatch = useDispatch()
    // bootcamp data
    const [bootcampsData, setBootcampData] = useState([])
    const [bootcampValue, setBootcampValue] = useState("")

    // STATES
    // redux states
    const { 
        getBootcampsAdminLoading,
        bootcamps,
        putStudentToBootcampSuccess,
        putStudentToBootcampError,
        putStudentToBootcampLoading
     } = useSelector(state=>{
        const { 
            getBootcampsAdminLoading,
            putStudentToBootcampLoading
        } = state.loadingIndicator
        const {
            success: { putStudentToBootcamp: putStudentToBootcampSuccess },
            errors: { putStudentToBootcamp: putStudentToBootcampError},
        } = state.ajaxStatuses
        const { bootcamps } = state.bootcampsReducer
        return {
            getBootcampsAdminLoading,
            bootcamps,
            putStudentToBootcampSuccess,
            putStudentToBootcampError,
            putStudentToBootcampLoading
        }
    })


    // HANDLERS
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(putAddStudentToBootcampRequest({
            bootcampId: bootcampValue,
            email: userEmail
        }))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getBootcampsAdminRequest())
    }, [])
    useEffect(()=>{
        setBootcampData(bootcamps.map(({_id,name})=>({value:_id, label:name})))
    }, [bootcamps])

    // checking for error and success messages
    useEffect(() => {
        if (putStudentToBootcampError) {
          toast.error(putStudentToBootcampError, {
            duration: 3000,
          });
        }
    }, [putStudentToBootcampError]);
    
    useEffect(() => {
        if (putStudentToBootcampSuccess) {
          toast.success(putStudentToBootcampSuccess, {
            duration: 3000,
          });
          history.replace("/dashboard/users")
        }
    }, [putStudentToBootcampSuccess]);

    return (
        <form
        className='flex flex-col gap-5 rounded-[10px] bg-[#F8F9FC] p-6 w-full h-fit'
        onSubmit={handleSubmit}>

            {/* form caption */}
            <p className='font-semibold text-xl text-[#3F444D]'>
                Others
            </p>

            {
                getBootcampsAdminLoading?
                <div
                    style={{
                    width: "100%",
                    }}
                    className="p-6 flex gap-5 flex-col h-full justify-center items-center m-auto"
                    >
                    {" "}
                    <CircularProgress
                    size={35} 
                    style={{ color: "#7F56D9" }} />
                    {" "}
                </div>
                :
                (bootcamps.length === 0)?
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <div className='flex items-center justify-start'>
                        <span className='font-semibold text-sm text-[#344054]'>
                            Bootcamp enrolled in
                        </span>
                    </div>
                    <Select
                    isDisabled={true}
                    className='custom-select-container'
                    placeholder="No data fetched, kindly refresh"
                    options={trackData} />
                </label>:
                <>
                    

                    {/* Bootcamp enrolled for input container */}
                    <label className='flex flex-col gap-2'>

                        {/* label text */}
                        <div className='flex items-center justify-start'>
                            <span className='font-semibold text-sm text-[#344054]'>
                                Bootcamp enrolled in
                            </span>
                        </div>

                        {/* input field */}
                        <Select
                        className='custom-select-container'
                        placeholder="Add user to bootcamp"
                        value={bootcampsData.filter((data)=>data.value===bootcampValue)}
                        onChange={(obj)=>setBootcampValue(obj.value)}
                        options={bootcampsData} />
                    </label>

                    {/* save changes button */}
                    <div className='flex w-full justify-end'>
                        <BtnSm 
                        loading={putStudentToBootcampLoading}
                        disabled={!bootcampValue?true:false}
                        title={"Save Changes"} />
                    </div>
                </>
            }
        </form>
    )
}

export default AddUserBootcamp