import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Mail from '.'

const MailRouter = ({path}) => {
    return (
        <Switch>
            <Route
            exact
            path={path}
            component={Mail} />
        </Switch>
    )
}

export default MailRouter