import { useEffect, useState } from 'react'
import { HiOutlineSearch } from "react-icons/hi"
import { MdFilterList } from "react-icons/md"
import { IoIosArrowDown } from "react-icons/io"
import UsersTable from './UsersTable'
import FilterMenuList from './FilterMenuList'
import _ from "lodash"

const UsersListTable = ({usersList, userLoading}) => {

    // STATES
    // users state list
    const [userStateList, setUserStateList] = useState([])
    // filter component state
    const [filterIsOpen, setFilterState] = useState(false)
    const [filterValue, setFilterValue] = useState(null)
    const filterList = ["Date", "Name"]
    // search filter state
    const [filteredResult, setFilteredResult] = useState("")


    // HANDLERS
    // handle filter menu item click
    const handleFilterMenuClick = (id) => {
        setFilterValue(id)
        setFilterState(false)
    }
    // handle filter search bar input
    const handleOnChange = (e) => {
        setFilteredResult(e.target.value)
        // setUserStateList(usersList.filter((item,index)=>{
        //     const tempName = `${item.firstname} ${item.lastname}`
        //     return tempName.toLowerCase().includes(filteredResult.toLowerCase())
        // }))
    }
    const sortUserList = (id) => {
        if(id===1) {
            const newArr = [...userStateList]
            newArr.sort((a, b)=>{
                a = new Date(a.createdAt);
                b = new Date(b.createdAt);
                return a>b? 1: a<b && -1;
            })
            setUserStateList(newArr)
        } else if(id === 2) {
            const newArr = [...userStateList]
            newArr.sort((a,b)=>a.firstname.toLowerCase() > b.firstname.toLowerCase()? 1: a.firstname.toLowerCase() < b.firstname.toLowerCase() && -1)
            setUserStateList(newArr)
        }
    }

    // SIDE EFFECTS
    useEffect(()=>{
        setUserStateList(usersList)
    }, [usersList])


    return (
        <div className='flex flex-col gap-7'>

            {/* search and filter */}
            <div className='flex gap-5 w-[83%]'>
                        
                {/* search bar */}
                <div className='bg-white rounded-[4px] flex items-center gap-2 py-3 px-3 w-full'>
                    <HiOutlineSearch />
                    <input 
                    value={filteredResult}
                    onChange={handleOnChange}
                    className='placeholder:font-normal placeholder:text-sm placeholder:text-[#202223] text-sm font-normal text-[#202223] outline-none w-full'
                    type="search" 
                    placeholder='Search' />
                </div>

                {/* filter button */}
                <div className='bg-white rounded-[4px] flex items-center gap-2 py-3 px-4 min-w-[26%] relative'>
                    <MdFilterList
                    onClick={()=>setFilterState(!filterIsOpen)}
                    className={`cursor-pointer`} 
                    size={36} />
                    <div 
                    className='text-sm font-normal text-[#6D7175] w-full'>
                        {filterValue?filterList[filterValue-1]:"Filter by"}
                    </div>
                    <IoIosArrowDown
                    onClick={()=>setFilterState(!filterIsOpen)}
                    className={`cursor-pointer transition-transform ${filterIsOpen?"rotate-180":"rotate-[360deg]"}`} 
                    size={36} />

                    {/* menu list */}
                    {
                        filterIsOpen ?
                            <FilterMenuList
                            sortList={sortUserList}
                            handleMenuClick={handleFilterMenuClick}
                            filterValue={filterValue} />
                        :null
                    }
                </div>
            </div>

            {/* Table */}
            <UsersTable
            inputValue={filteredResult}
            userLoading={userLoading}
            usersList={userStateList} />
        </div>
    )
}

export default UsersListTable