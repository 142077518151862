import React from 'react'

const FilterMenuList = ({filterValue, handleMenuClick, sortList}) => {
    return (
        <ul className={`border border-[#E1E6EF] rounded-[4px] flex flex-col p-3 absolute top-[4rem] right-[0rem] bg-white gap-3 w-[144px] font-normal text-sm text-[#6D7175]`}>
            <li 
            onClick={()=>{
                handleMenuClick(1)
                sortList(1)
            }}
            className={`cursor-pointer hover:bg-gray-50 ${filterValue===1?"bg-[#F8F5FF] transition-all border border-[#E1E6EF]":"bg-white"} p-2 rounded-[4px]`}>
                Date
            </li>

            <li
            onClick={()=>{
                handleMenuClick(2)
                sortList(2)
            }}
            className={`cursor-pointer hover:bg-gray-50 ${filterValue===2?"bg-[#F8F5FF] transition-all border border-[#E1E6EF]":"bg-white"} p-2 rounded-[4px]`}>
                Name
            </li>
        </ul>
    )
}

export default FilterMenuList