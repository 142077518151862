import { Field } from 'formik'
import React from 'react'
import style from "./style.module.css"

const ClubCheckBox = ({name, id}) => {

    return (
        <Field
        name={name}
        >{({ field, form}) => {
            return (
                <div className={style.checkboxContainer}>
                    <label className={style.lbContainer}>
                        <input 
                        type="checkbox" 
                        id={id} 
                        checked={field.value}
                        {...field} />
                    </label>
                </div>
            )
        }}</Field>
    )
}

export default ClubCheckBox