import React from 'react'

const ChangeUserBtn = ({loading, disabled, onClick, usersLength}) => {
    return (
        <button
        disabled={disabled}
        onClick={onClick}
        className="font-semibold text-sm 2xl:text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] active:bg-gray-50 hover:bg-[#f1f1f1] px-4 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-none w-full">
            <span>{loading?"Fetching users":usersLength === 0?"Reload page":"Change user"}</span>
        </button>
    )
}

export default ChangeUserBtn