import { GET_CLUBS_ADMIN_SUCCESS, GET_SINGLE_CLUB_SUCCESS } from "../types"

const initialState = {
    clubs: [],
    singleClub: {}
}

const clubsReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_CLUBS_ADMIN_SUCCESS:
    return { 
        ...state,
        clubs: payload.clubs
    }

  case GET_SINGLE_CLUB_SUCCESS:
    return { 
        ...state,
        singleClub: payload.club
    }

  default:
    return state
  }
}

export default clubsReducer
