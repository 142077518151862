import { 
    CLEAR_PUT_USER_EVENT_SUCCESS, 
    GET_EVENTS_ADMIN_FAIL, 
    GET_EVENTS_ADMIN_LOADING, 
    GET_EVENTS_ADMIN_REQUEST, 
    GET_EVENTS_ADMIN_SUCCESS, 
    PUT_USER_TO_EVENT_FAIL, 
    PUT_USER_TO_EVENT_LOADING, 
    PUT_USER_TO_EVENT_REQUEST, 
    PUT_USER_TO_EVENT_SUCCESS,
    GET_SINGLE_EVENT_FAIL,
    GET_SINGLE_EVENT_LOADING,
    GET_SINGLE_EVENT_REQUEST,
    GET_SINGLE_EVENT_SUCCESS,
    CLEAR_UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_LOADING,
    UPDATE_EVENT_REQUEST,
    POST_CREATE_EVENT_REQUEST,
    POST_CREATE_EVENT_LOADING,
    POST_CREATE_EVENT_SUCCESS,
    POST_CREATE_EVENT_FAIL,
    CLEAR_CREATE_EVENT_SUCCESS,
    CLEAR_DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_LOADING,
    DELETE_EVENT_REQUEST,
    CLEAR_DELETE_SPEAKER_SUCCESS,
    DELETE_SPEAKER_FROM_EVENT_FAIL,
    DELETE_SPEAKER_FROM_EVENT_SUCCESS,
    DELETE_SPEAKER_FROM_EVENT_LOADING,
    DELETE_SPEAKER_FROM_EVENT_REQUEST,
    CLEAR_SPEAKER_TO_EVENT_SUCCESS,
    PUT_SPEAKER_TO_EVENT_FAIL,
    PUT_SPEAKER_TO_EVENT_SUCCESS,
    PUT_SPEAKER_TO_EVENT_LOADING,
    PUT_SPEAKER_TO_EVENT_REQUEST, 
} from "../types";

// get events for admin
export const getEventsAdminRequest = () => {
    return {
        type: GET_EVENTS_ADMIN_REQUEST
    }
}

export const getEventsAdminSuccess = (prop) => {
    return {
        type: GET_EVENTS_ADMIN_SUCCESS,
        payload: prop,
    };
};
  
export const getEventsAdminFailure = (errors) => {
    return {
        type: GET_EVENTS_ADMIN_FAIL,
        payload: { errors },
    };
};

export const getEventsAdminLoading = (loading) => {
    return {
        type: GET_EVENTS_ADMIN_LOADING,
        payload: loading,
    };
};

// GET single event
export const getSingleEventFailure = (errors) => {
    return {
        type: GET_SINGLE_EVENT_FAIL,
        payload: { errors }
    }
}

export const getSingleEventLoading = (loading) => {
    return {
        type: GET_SINGLE_EVENT_LOADING,
        payload: loading
    }
}

export const getSingleEventRequest = (prop) => {
    return {
        type: GET_SINGLE_EVENT_REQUEST,
        payload: prop
    }
}

export const getSingleEventSuccess = (prop) => {
    return {
        type: GET_SINGLE_EVENT_SUCCESS,
        payload: prop
    }
}

// PUT ADD USER TO EVENT
export const putAddUserToEventRequest = (prop) => {
    return {
        type: PUT_USER_TO_EVENT_REQUEST,
        payload: prop
    }
}

export const putAddUserToEventLoading = (loading) => {
    return {
        type: PUT_USER_TO_EVENT_LOADING,
        payload: loading
    }
}

export const putAddUserToEventSuccess = (prop) => {
    return {
        type: PUT_USER_TO_EVENT_SUCCESS,
        payload: prop
    }
}


export const putAddUserToEventFailure = (errors) => {
    return {
        type: PUT_USER_TO_EVENT_FAIL,
        payload: { errors }
    }
}

export const clearAddUserToEventSuccess = () => {
    return {
        type: CLEAR_PUT_USER_EVENT_SUCCESS
    }
}

// UPDATE EVENT
export const updateEventRequest = (prop) => {
    return {
        type: UPDATE_EVENT_REQUEST,
        payload: prop
    }
}

export const updateEventLoading = (loading) => {
    return {
        type: UPDATE_EVENT_LOADING,
        payload: loading
    }
}

export const updateEventSuccess = (prop) => {
    return {
        type: UPDATE_EVENT_SUCCESS,
        payload: prop
    }
}


export const updateEventFailure = (errors) => {
    return {
        type: UPDATE_EVENT_FAIL,
        payload: { errors }
    }
}

export const clearUpdateEventSuccess = () => {
    return {
        type: CLEAR_UPDATE_EVENT_SUCCESS
    }
}

// CREATE EVENT
export const postCreateEventRequest = (prop) => {
    return {
        type: POST_CREATE_EVENT_REQUEST,
        payload: prop
    }
}

export const postCreateEventLoading = (loading) => {
    return {
        type: POST_CREATE_EVENT_LOADING,
        payload: loading
    }
}

export const postCreateEventSuccess = (prop) => {
    return {
        type: POST_CREATE_EVENT_SUCCESS,
        payload: prop
    }
}


export const postCreateEventFailure = (errors) => {
    return {
        type: POST_CREATE_EVENT_FAIL,
        payload: { errors }
    }
}

export const clearCreateEventSuccess = () => {
    return {
        type: CLEAR_CREATE_EVENT_SUCCESS
    }
}

// DELETE EVENT
export const deleteEventRequest = (prop) => {
    return {
        type: DELETE_EVENT_REQUEST,
        payload: prop
    }
}

export const deleteEventLoading = (loading) => {
    return {
        type: DELETE_EVENT_LOADING,
        payload: loading
    }
}

export const deleteEventSuccess = (prop) => {
    return {
        type: DELETE_EVENT_SUCCESS,
        payload: prop
    }
}


export const deleteEventFailure = (errors) => {
    return {
        type: DELETE_EVENT_FAIL,
        payload: { errors }
    }
}

export const clearDeleteEventSuccess = () => {
    return {
        type: CLEAR_DELETE_EVENT_SUCCESS
    }
}

// DELETE SPEAKER FROM EVENT
export const deleteSpeakerFromEventRequest = (prop) => {
    return {
        type: DELETE_SPEAKER_FROM_EVENT_REQUEST,
        payload: prop
    }
}

export const deleteSpeakerFromEventLoading = (loading) => {
    return {
        type: DELETE_SPEAKER_FROM_EVENT_LOADING,
        payload: loading
    }
}

export const deleteSpeakerFromEventSuccess = (prop) => {
    return {
        type: DELETE_SPEAKER_FROM_EVENT_SUCCESS,
        payload: prop
    }
}


export const deleteSpeakerFromEventFailure = (errors) => {
    return {
        type: DELETE_SPEAKER_FROM_EVENT_FAIL,
        payload: { errors }
    }
}

export const clearDeleteSpeakerFromEventSuccess = () => {
    return {
        type: CLEAR_DELETE_SPEAKER_SUCCESS
    }
}

// ADD SPEAKER TO EVENT
export const putSpeakerToEventRequest = (prop) => {
    return {
        type: PUT_SPEAKER_TO_EVENT_REQUEST,
        payload: prop
    }
}

export const putSpeakerToEventLoading = (loading) => {
    return {
        type: PUT_SPEAKER_TO_EVENT_LOADING,
        payload: loading
    }
}

export const putSpeakerToEventSuccess = (prop) => {
    return {
        type: PUT_SPEAKER_TO_EVENT_SUCCESS,
        payload: prop
    }
}


export const putSpeakerToEventFailure = (errors) => {
    return {
        type: PUT_SPEAKER_TO_EVENT_FAIL,
        payload: { errors }
    }
}

export const clearPutSpeakerSuccess = () => {
    return {
        type: CLEAR_SPEAKER_TO_EVENT_SUCCESS
    }
}