import { useEffect } from "react";
import { HiOutlineUserCircle, HiUserCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import EmptyDataState from "../../Global/EmptyDataState";
import LoadingCircles from "../../Global/LoadingCircles";

const UsersTable = ({usersList, userLoading, inputValue}) => {

  // HANDLERS
  const filteredData = usersList.filter((user) => {
    //if no input the return the original
    if (inputValue === '') {
        return user;
    }
    //return the item which contains the user input
    else {
        return `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`?.includes(inputValue.toLowerCase())
    }
})

  return (
    <div className="bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] px-8 pb-12 flex flex-col gap-12">
      {/* table head */}
      <div className="border-b border-[#E1E6EF] text-sm font-semibold text-[#1D2433A6] grid grid-cols-[25%_25%_30%_20%] py-7 gap-x-3">
        <span>Name</span>
        <span>Date created</span>
        <span>Email address</span>
        <span>-</span>
      </div>

      {/* table body */}
      <div className="flex flex-col gap-12">
        
          {
              userLoading?
              <LoadingCircles />:
              usersList.length===0?
              <EmptyDataState />:
              <>
                {
                  filteredData.map(user=>(
                  <div 
                  key={user._id}
                  className="grid grid-cols-[25%_25%_30%_20%] gap-x-3 text-[#1D2433A6] text-sm font-normal items-center">
          
                    {/* avatar and name */}
                    <div className="flex gap-3 items-center">
                      <HiUserCircle size={29} />
                      <span>
                        {`${user.firstname} ${user.lastname}`}
                      </span>
                    </div>
          
                    {/* date created */}
                    <span>{user?.createdAt?.split("T")[0]?.split("-")?.reverse()?.join("/") || "Null"}</span>
          
                    {/* email address */}
                    <span>{user.email}</span>
          
                    {/* edit button */}
                    <Link
                      to={`/dashboard/users/${user._id}`}
                      className="flex justify-center items-center h-[36px] px-3 w-fit border border-[#D0D5DD] rounded-[8px] font-semibold text-sm text-[#344054] hover:text-[#344054] hover:no-underline"
                    >
                      Edit
                    </Link>
                  </div>
                  ))
                }              
              </>
          }
      </div>
    </div>
  );
};

export default UsersTable;
