import React, { useEffect, useState } from 'react'
import { TbCirclePlus } from 'react-icons/tb'
import BtnSm from '../../../Global/BtnSm'

const AddGuests = ({addGuests, setError}) => {

    // STATES
    const [isTyping, setIsTyping] = useState(false)
    const [guests, setGuests] = useState([
        {
            name: "",
            about: ""
        }
    ])

    // HANDLERS
    const handleSaveChanges = () => {
        addGuests(guests)
        setIsTyping(false)
        setError(false)
    }
    
    return (
        <div className='flex flex-col gap-4'>
            
            <div className='flex flex-col'>
                <p className='text-[#344054] font-semibold text-sm'>
                    Guests
                </p>

                <p className='text-[#667085] text-sm font-normal'>
                    Use “&” to seperate different guest name and title
                </p>
            </div>

            <form 
            onSubmit={(e)=>e.preventDefault()}
            className='flex flex-col gap-6 w-full'>
                
                {
                    guests.length===0?
                    <></>:
                    <>
                    {   
                        guests.map((item, index)=>(
                            <div 
                            key={index}
                            className='flex flex-col gap-2 w-full'>
                                <label className='flex flex-col'>

                                    {/* input field */}
                                    <input 
                                    type="text" 
                                    value={item.name}
                                    onChange={(e)=>{
                                        const arr = [...guests]
                                        arr[index].name = e.target.value
                                        setGuests(arr)
                                        setIsTyping(e.target.value<1?false:true)
                                    }}
                                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                    placeholder='name' />
                                </label>

                                <label className='flex flex-col'>

                                    {/* input field */}
                                    <input 
                                    type="text" 
                                    value={item.about}
                                    onChange={(e)=>{
                                        const arr = [...guests]
                                        arr[index].about = e.target.value
                                        setGuests(arr)
                                        setIsTyping(e.target.value<1?false:true)
                                    }}
                                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                    placeholder='about' />
                                </label>
                                
                                {
                                    guests.length===1?
                                    <></>:
                                    <span 
                                    onClick={()=>{
                                        setGuests(guests.filter(singleItem=>guests.indexOf(singleItem)!==index))
                                    }}
                                    className=' self-end font-semibold text-red-600 hover:text-red-400 text-sm cursor-pointer'>
                                        remove
                                    </span>
                                }
                            </div>
                        ))
                    }
                    </>
                }

            </form>

            <div className='flex items-center justify-between'>
                <button className='text-gray-500 flex items-center gap-2 hover:text-gray-400 transition-colors'>
                    <TbCirclePlus size={21} />

                    <span 
                    onClick={()=>{
                        const tempArr = [...guests, {
                            name: "",
                            about: ""
                        }]
                        setIsTyping(false)
                        setGuests(tempArr)
                    }}
                    className='font-semibold text-sm'>
                        Add new guest
                    </span>
                </button>
            </div>

            {/* save changes button */}
            <div className='flex w-full justify-start mt-3'>
                <BtnSm 
                disabled={!isTyping}
                onClick={handleSaveChanges}
                title={"Save Changes"} />
            </div>
        </div>
    )
}

export default AddGuests