import React from 'react'

const EventOptics = ({pastEvents, futureEvents}) => {
    return (
        <div className='bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] p-6 grid grid-cols-2 gap-x-9 w-[70%]'>

            {/* number of students */}
            <div className='flex flex-col border-r border-[#E1E6EF]'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Past events
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {pastEvents.toLocaleString('en-US')}
                </p>
            </div>

            {/* availble bootcamps */}
            <div className='flex flex-col'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Future events
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {futureEvents.toLocaleString('en-US')}
                </p>
            </div>
        </div>
    )
}

export default EventOptics