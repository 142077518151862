import React from 'react'
import { MdOutlineClose } from "react-icons/md"

const CancelTag = () => {
    return (
        <span className='min-w-[32px] bg-[#F8F9FC] flex justify-center items-center rounded-[50%] min-h-[32px]'>
            <MdOutlineClose color='#E02D3C' size={15} />
        </span>
    )
}

export default CancelTag