import { CircularProgress } from '@material-ui/core'
import React from 'react'

const LoadingCircles = () => {
    return (
        <div
        style={{
          width: "100%",
        }}
        className="bg-white rounded-[10px] p-6 flex gap-5 flex-col h-[354px] justify-center items-center m-auto"
        >
          {" "}
          <CircularProgress
          size={35} 
          style={{ color: "#7F56D9" }} />
          {" "}
        </div>
    )
}

export default LoadingCircles