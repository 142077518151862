import Home from ".";
import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";

const HomeRouter = ({path}) => {
    
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={Home} />
        </ Switch>
    )
}

export default HomeRouter