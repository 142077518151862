import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { DELETE_USER_REQUEST, GET_SINGLE_USER_REQUEST, GET_USERS_REQUEST, UPDATE_USER_REQUEST } from "../types";
import { clearDeleteUserSuccess, clearUpdateUserSuccess, deleteUserFailure, deleteUserLoading, deleteUserSuccess, getSingleUserFailure, getSingleUserLoading, getSingleUserSuccess, getUsersFailure, getUsersLoading, getUsersSuccess, updateUserFailure, updateUserLoading, updateUserSuccess } from "../action";

const ajaxDBCalls = {
    getUsers: async () => {
        const reponse = await axios({
            method: "GET",
            url: "auth/users"
        })
        return reponse
    },
    getSingleUser: async ({ payload }) => {
        const response = await axios({
          method: "GET",
          url: `auth/user/${payload}`,
        });
    
        return response;
    },
    updateUser: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `auth/user/${payload.userId}`,
            data: payload.userData
        })

        return response
    },
    deleteUser: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: "auth/delete",
            data: payload
        })

        return response
    },
}

// WORKERS
// get users
function* getUsers() {
    try {
        yield put(getUsersLoading(true));

        const response = yield call(ajaxDBCalls.getUsers)

        yield put(getUsersSuccess(response.data))
        yield put(getUsersLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getUsersFailure(errorMessage))
        yield put(getUsersLoading(false))
        yield delay();
        yield put(getUsersFailure(""))
    }
}

// get single user
function* getSingleUser(payload) {
    try {
        yield put(getSingleUserLoading(true));
    
        const response = yield call(ajaxDBCalls.getSingleUser, payload);
        yield put(getSingleUserSuccess(response.data));
        yield put(getSingleUserLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getSingleUserFailure(errorMessage));
        yield put(getSingleUserLoading(false));
        yield delay();
        yield put(getSingleUserFailure(""));
    }
}

// delete student from bootcamp
function* deleteUser(payload) {
    try {
        yield put(deleteUserLoading(true));
        const response = yield call(ajaxDBCalls.deleteUser, payload);
        yield put(deleteUserSuccess(response.data));
        yield put(deleteUserLoading(false));
        yield delay();
        yield put(clearDeleteUserSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteUserFailure(errorMessage));
        yield put(deleteUserLoading(false));
        yield delay();
        yield put(deleteUserFailure(""));
    }
}

// update user
function* udpateUser(payload) {
    try {
        yield put(updateUserLoading(true));
        const response = yield call(ajaxDBCalls.updateUser, payload);
        yield put(updateUserSuccess(response.data));
        yield put(updateUserLoading(false));
        yield delay();
        yield put(clearUpdateUserSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(updateUserFailure(errorMessage));
        yield put(updateUserLoading(false));
        yield delay();
        yield put(updateUserFailure(""));
    }
}

// WATCHERS
function* getUsersWatcher() {
    yield takeLatest(GET_USERS_REQUEST, getUsers)
}

function* getSingleUserWatcher() {
    yield takeLatest(GET_SINGLE_USER_REQUEST, getSingleUser)
}

function* updateUserWatcher() {
    yield takeLatest(UPDATE_USER_REQUEST, udpateUser)
}

function* deleteUserWatcher() {
    yield takeLatest(DELETE_USER_REQUEST, deleteUser)
}

// user saga
export default function* usersSaga() {
    yield spawn(getUsersWatcher);
    yield spawn(getSingleUserWatcher);
    yield spawn(updateUserWatcher);
    yield spawn(deleteUserWatcher);    
}