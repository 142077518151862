import React from 'react'
import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import ClubMembers from '.';

const ClubMembersRouter = ({path}) => {
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={ClubMembers} />
        </ Switch>
    )
}

export default ClubMembersRouter