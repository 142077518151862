import React from 'react'
import MailBody from '../../../components/AdminDashboard/Mail/MailBody'
import SideNav from '../../../layouts/AdminDashboard/SideNav'

const Mail = () => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>
            
            {/* Side nav */}
            <SideNav />

            {/* Body */}
            <MailBody />
        </div>
    )
}

export default Mail