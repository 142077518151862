import React from 'react'
import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import ClubSubscription from '.';
import ViewFullDetails from './ViewFullDetails';
import EditPackage from './EditPackage';
import NewPackage from './NewPackage';

const ClubSubscriptionRouter = ({path}) => {
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={ClubSubscription} />

            <Route 
            exact 
            path={`${path}/:id`}
            component={ViewFullDetails} />

            <Route 
            exact 
            path={`${path}/edit/:id`}
            component={EditPackage} />

            <Route 
            exact 
            path={`${path}/create/new-package`}
            component={NewPackage} />
        </ Switch>
    )
}

export default ClubSubscriptionRouter