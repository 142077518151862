import { useEffect, useState } from 'react'
import { HiUserCircle } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { realDateFormat } from '../../../../helpers'
import MyModal from '../../../../layouts/MyModal'
import { clearDeleteStudentFromBootcampSuccess, deleteStudentFromBootcampRequest, getSingleBootcampRequest } from '../../../../redux/action'
import EmptyDataState from '../../../Global/EmptyDataState'
import DeletePopup2 from '../../global/DeletePopup2'

const BootcampUsersTable = ({bootcampId, students}) => {

    // OBJECT INITIALIZATION
    const dispatch = useDispatch()
    const history = useHistory()


    // STATE
    // delet modal state
    const [deleteModalState, setDeleteModalState] = useState(false)
    // student id state
    const [studentId, setStudentId] = useState("")
    const {
        deleteStudentFromBootcampLoading,
        deleteStudentFromBootcampError,
        deleteStudentFromBootcampSuccess
    } = useSelector(state=>{
        const { deleteStudentFromBootcampLoading } = state.loadingIndicator
        const {
            success: {
                deleteStudentFromBootcamp: deleteStudentFromBootcampSuccess
            },
            errors: {
                deleteStudentFromBootcamp: deleteStudentFromBootcampError
            }
        } = state.ajaxStatuses
        return {
            deleteStudentFromBootcampLoading,
            deleteStudentFromBootcampError,
            deleteStudentFromBootcampSuccess
        }
    })


    // HANDLER
    // remove student from bootcamp function
    const removeStudentFromBootcamp = (id) => {
        dispatch(deleteStudentFromBootcampRequest({
            studentId,
            bootcampId
        }))
    }
    // toggle the delete modal
    const toggleDeleteModalState = (id) => {
        setStudentId(id)
        setDeleteModalState(!deleteModalState)
    }


    // SIDE EFFECTS
    // listen for error or success messages
    useEffect(() => {
        if (deleteStudentFromBootcampError) {
            setDeleteModalState(false)
            toast.error(deleteStudentFromBootcampError, {
                duration: 3000,
            });
        }
    }, [deleteStudentFromBootcampError]);

    useEffect(() => {
        if (deleteStudentFromBootcampSuccess) {
            setDeleteModalState(false)
            toast.success(deleteStudentFromBootcampSuccess, {
                duration: 3000,
            });
            dispatch(clearDeleteStudentFromBootcampSuccess())
            dispatch(getSingleBootcampRequest(bootcampId))
        }
    }, [deleteStudentFromBootcampSuccess]);

    return (
        <div className='bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] px-8 pb-12 flex flex-col gap-12'>

            {/* table head */}
            <div className='border-b border-[#E1E6EF] text-sm font-semibold text-[#1D2433A6] grid grid-cols-[25%_25%_30%_20%] py-7 gap-x-3'>
                <span>Name</span>
                <span>Date created</span>
                <span>Email address</span>
                <span>-</span>
            </div>

            {/* table body */}
            <div className='flex flex-col gap-12'>

                {
                    students.length===0?
                    <EmptyDataState
                    title='Not registered student yet' />:
                    <>
                        {
                            students.map(({user_id, _id})=>(
                                <div 
                                key={_id}
                                className='grid grid-cols-[25%_25%_30%_20%] gap-x-3 text-[#1D2433A6] text-sm font-normal items-center'>

                                    {/* avatar and name  */}
                                    <div className='flex gap-3 items-center'>
                                        <HiUserCircle size={29} />
                                        <span>{`${user_id.firstname} ${user_id.lastname}`}</span>
                                    </div>

                                    {/* date created */}
                                    <span>{"null"}</span>

                                    {/* email address */}
                                    <span>{user_id.email}</span>

                                    {/* edit button */}
                                    <button
                                    onClick={()=>toggleDeleteModalState(user_id._id)}
                                    className='flex justify-center items-center h-[36px] px-3 w-fit border border-[#D0D5DD] rounded-[8px] font-semibold text-sm text-[#344054] hover:bg-gray-50'>
                                        Remove student
                                    </button>
                                </div>
                            ))
                        }
                    </>
                }
            </div>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
                <DeletePopup2
                onClick={removeStudentFromBootcamp}
                loadinState={deleteStudentFromBootcampLoading}
                closeModal={toggleDeleteModalState} />
            </MyModal>
        </div>
    )
}

export default BootcampUsersTable