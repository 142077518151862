import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom'

const BackBtn = () => {

    // history object
    const history = useHistory()
    
    return (
       <p 
        onClick={()=>history.goBack()}
        className='flex items-center justify-start gap-3 cursor-pointer
        '>
            <IoIosArrowBack
            className="text-[#8B54F7]"
            size={18} />
            <span className='font-normal text-base text-gray-500'>
                Back
            </span>
        </p>
    )
}

export default BackBtn