import { useEffect, useState } from "react";
import TrackLabel from "../global/TrackLabel";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Link } from "react-router-dom";

const BootcampCard = ({
  bootcampNumber,
  bootcampTitle,
  subTitle,
  trackId,
  trackTitle,
  monthsRemaining,
  percent,
  linkId,

}) => {

  return (
    <div className="[box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col justify-between gap-6 p-5 rounded-[10px]">
      <div className="flex flex-col gap-6">
        {/* label */}
        <TrackLabel trackId={trackId} trackTitle={trackTitle} />

        {/* bootcamp info */}
        <p className="flex flex-col gap-1">
          <span className="font-semibold text-2xl text-[#3F444D]">
            Bootcamp {bootcampNumber}
          </span>

          <span className="font-semibold text-xl text-[#3F444D]">
            {bootcampTitle}
          </span>

          {subTitle ? (
            <span className="text-[#3F444D] text-base font-normal mt-1">
              {subTitle}
            </span>
          ) : null}
        </p>
      </div>

      <div className="flex flex-col gap-6">
        {/* progress bar */}
        <div className="flex items-center justify-start w-full mb-2">
          <div className="w-[50%] ml-[-12px]">
            <Progress.Line
              percent={30}
              showInfo={false}
              strokeColor="#7F56D9"
            />
          </div>

          <span className="text-xs font-normal text-[#1D2433] opacity-[0.65] w-[50%] mb-1">
            {monthsRemaining} month(s) remaining
          </span>
        </div>

        {/* view full details */}
        <Link
          to={`/dashboard/bootcamps/${linkId}${bootcampNumber}`}
          className="font-semibold text-base text-[#8B54F7] [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#1D2433A6] h-[44px] rounded-[8px] hover:bg-gray-50 active:bg-[#f1f1f1] flex items-center justify-center hover:text-[#8b54f7] hover:no-underline"
        >
          View Full details
        </Link>
      </div>
    </div>
  );
};

export default BootcampCard;
