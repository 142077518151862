import React from 'react'
import { useHistory } from 'react-router-dom'
import DynamicTag from './DynamicTag'

const ClubPackageCard = ({
    path, 
    packageName,
    packagePrice,
    business_consultancy,
    discounted_services,
    foreign_tech_consultancy,
    investment_database,
    investment_pitch,
    life_insurance,
    member_swag,
    networking_event, 
    news_letter, 
    reserved_office_space
}) => {

    // history object
    const history = useHistory()

    return (
        <div className='flex flex-col [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] p-[40px_12px_20px] gap-4'>
            
            {/* package name */}
            <div className='flex flex-col items-center justify-center gap-4 pb-2'>
                <p className='text-[#3F444D] text-2xl font-semibold'>
                    {packageName} Package
                </p>
            </div>

            {/* package info */}
            <div className='flex justify-between my-2'>

                {/* Listing */}
                <div className='grid grid-cols-2 text-[#667085] font-semibold text-xs justify-start items-center gap-3 gap-y-4'>
            
                    <DynamicTag
                    status={networking_event}
                    title={"Networking Event"} />
            
                    <DynamicTag
                    status={member_swag}
                    title={"Member Swag"} />
            
                    <DynamicTag
                    status={news_letter}
                    title={"Newsletter"} />
            
                    <DynamicTag
                    status={reserved_office_space}
                    title={"Reserved Office Space"} />
            
                    <DynamicTag
                    status={discounted_services}
                    title={"Discounted Services"} />
            
                    <DynamicTag
                    status={investment_pitch}
                    title={"Investment Pitch"} />
            
                    <DynamicTag
                    status={investment_database}
                    title={"Investment Database"} />
            
                    <DynamicTag
                    status={business_consultancy}
                    title={"Business Consultancy"} />
            
                    <DynamicTag
                    status={foreign_tech_consultancy}
                    title={"Foreign Tech Consultancy"} />
            
                    <DynamicTag
                    status={life_insurance}
                    title={"Life Insurance "} />
            
                </div>
                <div>

                </div>
            </div>

            {/* amount */}
            <p className='text-[#6c717a] font-semibold text-xl leading-[24px] text-center'>
                NGN {packagePrice.toLocaleString('en-US')}
                <span className='font-normal text-base'>/yr</span>
            </p>

            {/* button */}
            <div className='flex items-stretch flex-col'>
                <button 
                onClick={()=>history.push(`/dashboard/club-subscription/${path}`)}
                className="font-semibold text-base text-[#8B54F7] hover:opacity-90 border rounded-[8px] border[#1D2433A6] h-[44px]" >
                    View Full details
                </button>
            </div>
        </div>
    )
}

export default ClubPackageCard