import React, { useEffect, useState } from 'react'
import { TbCirclePlus } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MyModal from '../../../../layouts/MyModal'
import { clearDeleteSpeakerFromEventSuccess, clearPutSpeakerSuccess, deleteSpeakerFromEventFailure, deleteSpeakerFromEventRequest, getSingleEventRequest, putSpeakerToEventFailure, putSpeakerToEventRequest } from '../../../../redux/action'
import BtnSm from '../../../Global/BtnSm'
import DeletePopup2 from '../../global/DeletePopup2'
import AddSpeakerPopup from './AddSpeakerPopup'

const AddNewGuests = ({eventGuests, eventId}) => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()


    // STATES
    const [deleteId, setDeleteId] = useState("")
    const [isTyping, setIsTyping] = useState(false)
    const [guests, setGuests] = useState([
        {
            name: "",
            about: ""
        }
    ])
    // delete modal state
    const [deleteModalState, setDeleteModalState] = useState(false)
    // add speaker modal state
    const [addSpeakerModalState, setAddSpeakerModalState] = useState(false)
    // redux state
    const { 
        deleteSpeakerFromEventLoading,
        addSpeakerToEventLoading,
        addSpeakerToEventSuccess,
        deleteSpeakerFromEventSuccess,
        addSpeakerToEventError,
        deleteSpeakerFromEventError,
    } = useSelector(state=>{
        const { 
            deleteSpeakerFromEventLoading ,
            addSpeakerToEventLoading
        } = state.loadingIndicator
        const {
            success: { 
                addSpeakerToEvent: addSpeakerToEventSuccess,
                deleteSpeakerFromEvent: deleteSpeakerFromEventSuccess
            },
            errors: { 
                addSpeakerToEvent: addSpeakerToEventError,
                deleteSpeakerFromEvent: deleteSpeakerFromEventError,
            },
        } = state.ajaxStatuses;
        return {
            deleteSpeakerFromEventLoading,
            addSpeakerToEventLoading,
            addSpeakerToEventError,
            deleteSpeakerFromEventError,
            addSpeakerToEventSuccess,
            deleteSpeakerFromEventSuccess,
        }
    })


    // HANDLERS
    // handle save changes
    const handleSaveChanges = () => {
        dispatch(putSpeakerToEventRequest({
            eventId,
            speakerData: guests
        }))
        setIsTyping(false)
    }
    // toggle delete modal
    const toggleDeleteModalState = (speakerId) => {
        setDeleteModalState(!deleteModalState)
        setDeleteId(speakerId)
    }
    // toggle add speaker modal
    const toggleAddSpeakerModalState = () => {
        setAddSpeakerModalState(!addSpeakerModalState)
    }
    // handle delete speaker
    const handleDeleteSpeaker = () => {
        dispatch(deleteSpeakerFromEventRequest({
            eventId,
            speakerId: deleteId
        }))
    }
    // handle add speaker
    const handleAddSpeaker = (speakerData) => {
        dispatch(putSpeakerToEventRequest({
            eventId,
            speakerData
        }))
    }


    // SIDE EFFECTS
    // populate event data onMount
    useEffect(()=>{
        setGuests(eventGuests)
    }, [eventGuests])

    // listen for errors
    useEffect(() => {
        if (addSpeakerToEventError) {
            dispatch(putSpeakerToEventFailure(""))
            toast.error(addSpeakerToEventError, {
                duration: 3000,
            });
        }
    }, [addSpeakerToEventError]);
    useEffect(() => {
        if (deleteSpeakerFromEventError) {
            dispatch(deleteSpeakerFromEventFailure(""))
            toast.error(deleteSpeakerFromEventError, {
                duration: 3000,
            });
        }
    }, [deleteSpeakerFromEventError]);

    // listening for success messages    
    useEffect(() => {
        if (addSpeakerToEventSuccess) {
            dispatch(getSingleEventRequest(eventId))
            setAddSpeakerModalState(false)
            dispatch(clearPutSpeakerSuccess())
            toast.success(addSpeakerToEventSuccess, {
                duration: 3000,
            });
        }
    }, [addSpeakerToEventSuccess]);
    useEffect(() => {
        if (deleteSpeakerFromEventSuccess) {
            dispatch(getSingleEventRequest(eventId))
            setDeleteModalState(false)
            dispatch(clearDeleteSpeakerFromEventSuccess())
            toast.success(deleteSpeakerFromEventSuccess, {
                duration: 3000,
            });
        }
    }, [deleteSpeakerFromEventSuccess]);
    
    return (
        <div className='flex flex-col gap-4'>
            
            <div className='flex flex-col'>
                <p className='text-[#344054] font-semibold text-sm'>
                    Guests
                </p>
            </div>

            <form 
            onSubmit={(e)=>e.preventDefault()}
            className='flex flex-col gap-6 w-full'>
                
                {
                    guests.length===0?
                    <></>:
                    <>
                    {   
                        guests.map((item, index)=>(
                            <div 
                            key={index}
                            className='flex flex-col gap-2 w-full'>
                                <label className='flex flex-col'>

                                    {/* input field */}
                                    <input 
                                    disabled
                                    type="text" 
                                    value={item.name}
                                    onChange={(e)=>{
                                        const arr = [...guests]
                                        arr[index].name = e.target.value
                                        setGuests(arr)
                                        setIsTyping(e.target.value<1?false:true)
                                    }}
                                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                    placeholder='name' />
                                </label>

                                <label className='flex flex-col'>

                                    {/* input field */}
                                    <input 
                                    disabled
                                    type="text" 
                                    value={item.about}
                                    onChange={(e)=>{
                                        const arr = [...guests]
                                        arr[index].about = e.target.value
                                        setGuests(arr)
                                        setIsTyping(e.target.value<1?false:true)
                                    }}
                                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                    placeholder='about' />
                                </label>
                                
                                <span 
                                onClick={()=>{
                                    toggleDeleteModalState(item._id)
                                }}
                                className=' self-end font-semibold text-red-600 hover:text-red-400 text-sm cursor-pointer'>
                                    remove
                                </span>
                            </div>
                        ))
                    }
                    </>
                }

            </form>

            <div className='flex items-center justify-between'>
                <button className='text-gray-500 flex items-center gap-2 hover:text-gray-400 transition-colors'>
                    <TbCirclePlus size={21} />

                    <span 
                    onClick={toggleAddSpeakerModalState}
                    className='font-semibold text-sm cursor-pointer'>
                        Add new guest
                    </span>
                </button>
            </div>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
               <DeletePopup2
               onClick={handleDeleteSpeaker}
               loadinState={deleteSpeakerFromEventLoading}
               closeModal={toggleDeleteModalState} />
            </MyModal>

            {/* add speaker modal */}
            <MyModal
            modalIsOpen={addSpeakerModalState}
            toggleModal={toggleAddSpeakerModalState}
            disableBtn={false}>
               <AddSpeakerPopup
               handleAddSpeaker={handleAddSpeaker}
               loading={addSpeakerToEventLoading}
               closeModal={toggleAddSpeakerModalState} />
            </MyModal>
        </div>
    )
}

export default AddNewGuests