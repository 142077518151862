// import avatar from "./../../../assets/admindashboard/global/images/Avatar.png"
// import { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { BiUserCircle } from 'react-icons/bi'

const ProfileAvatar = () => {
    // const [userInfo, setUserInfo] = useState({})

    // const user = useSelector((state)=>{
    //     const { user: { firstname, lastname, email} } = state.userData
    //     return {
    //         firstname,
    //         lastname,
    //         email
    //     }
    // })

    // useEffect(()=>{
    //     if(user) {
    //         setUserInfo({...user})
    //     }
    // }, [])


    return (
        <div className='flex justify-between items-center gap-[10px]'>
            <div className='w-40px h-40px [box-shadow:0px_2px_8px_#e0e0e0] rounded-[50%] bg-white relative'>
                {/* <img src={avatar} className="w-[40px]" alt="" /> */}
                <BiUserCircle size={40} />

                <span className='h-[10px] w-[10px] rounded-[50%] bg-green-600 absolute bottom-[0] right-[0] z-[1000]'>
                </span>
            </div>

            <div className='flex flex-col'>
                <span className='font-semibold text-sm text-[#1D2433]'>
                    {/* { `${userInfo.firstname || ""} ${userInfo.lastname || "Loading..."}`} */}
                    Admin
                </span>

                <span className='font-normal text-sm text-[#1D2433] opacity-[0.6]'>
                    {/* {userInfo.email || "Loading..."} */}
                    nonso@aimovement.com
                </span>
            </div>
        </div>
    )
}

export default ProfileAvatar