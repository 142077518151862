import { Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import image from "../../../assets/admindashboard/events/images/jumbotron-event.png";
import EmptyDataState from "../../Global/EmptyDataState";
import LoadingCircles from "../../Global/LoadingCircles";
import { getRTDate, MONTHS, DAY } from "../../../helpers";

const UpcomingEvents = ({upcomingEvent}) => {

    // STATES
    const { getEventsLoading } = useSelector(state=>{
        const { getEventsLoading } = state.loadingIndicator
        return {
            getEventsLoading
        }
    })

    return (
        <div className="flex flex-col gap-6">
            <p className="font-semibold text-xl text-[#3F444D]">Upcoming Event</p>

                {
                    getEventsLoading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "100%",
                      height: "30vh",
                      borderRadius: "10px",
                    }}
                    />:
                    upcomingEvent && upcomingEvent.length!==0?
                    <div className="flex items-center w-full gap-7 overflow-auto scroll">
                      {

                        upcomingEvent.map((event)=>(
                            <div 
                            key={event._id}
                            className="bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex items-center gap-8 p-7 w-full min-w-full">
                                <img
                                  src={event.imgurl}
                                  className="h-[200px] w-[393px] rounded-[10px] object-cover"
                                  alt=""
                                />

                                <div className="flex flex-col gap-2">
                                  <p className="flex flex-col gap-1">
                                    <span className="font-semibold text-2xl text-[#1D2433]">
                                      {event.name}
                                    </span>

                                    <span className="text-gray-500 font-normal text-base">
                                      {`${getRTDate.date(event.date)} ${MONTHS[getRTDate.month(event.date)]}, ${getRTDate.year(event.date)}`}
                                    </span>
                                  </p>

                                  <p className="font-normal text-xl text-gray-500">
                                    {event.description.substring(0,80).concat("...") || event.description}
                                  </p>

                                  <Link
                                    to={`/dashboard/events/${event._id}`}
                                    className="h-[36px] mobile:h-[48px] bg-transparent flex justify-center border-2 border-[#D0D5DD] items-center font-semibold text-base lg:text-xs text-[#344054] px-5 rounded-[8px] hover:bg-[#f5f5f5] hover:no-underline hover:text-[#344054] mt-3 w-fit"
                                  >
                                    Learn more
                                  </Link>
                                </div>
                            </div>
                        ))
                      }
                    </div>:
                    <EmptyDataState />
                }
        </div>
    );
};

export default UpcomingEvents;
