import {
  GET_BOOTCAMP_STUDENTS_SUCCESS,
  GET_DIGITAL_MARKETING_STUDENTS_SUCCESS,
} from "../types";

const initialState = () => ({
  bootcampStudents: [],
  digitalMarketingStudents: [],
});

const bootcampStudentsReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case GET_BOOTCAMP_STUDENTS_SUCCESS:
      return {
        ...state,
        bootcampStudents: payload.forms,
      };
    case GET_DIGITAL_MARKETING_STUDENTS_SUCCESS:
      return {
        ...state,
        digitalMarketingStudents: payload.dmbs,
      };

    default:
      return state;
  }
};

export default bootcampStudentsReducer;
