import React from 'react'
import HomeBody from '../../../components/AdminDashboard/Home/HomeBody'
import SideNav from '../../../layouts/AdminDashboard/SideNav'

const Home = () => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>
            
            {/* Side nav */}
            <SideNav />

            {/* Home Body */}
            <HomeBody />

        </div>
    )
}

export default Home