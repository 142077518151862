import { useEffect, useState } from 'react'
import {ReactComponent as DeleteBin} from "../../../../assets/admindashboard/bootcamp/svg/delete_minor.svg"
import { BiPlusCircle } from 'react-icons/bi'
import MyModal from '../../../../layouts/MyModal'
import AddNewInfoView from '../../Bootcamp/EditCourse/AddNewInfoView'
import DeletePopup from '../../global/DeletePopup'

const Features = ({features, setFeatures}) => {

    // STATES
    // Modals
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [infoModalState, setInfoModalState] = useState(false)
    // Data
    const [contentList, setContentList] = useState([])
    const [ deleteIndex, setDeleteIndex ] = useState(null)


    // HANDLERS
    // delete modal state
    const toggleDeleteModalState = () => {
        setDeleteModalState(!deleteModalState)
    }
    // info modal state
    const toggleInfoModal = () => {
        setInfoModalState(!infoModalState)
    }
    // on item delete handler
    const onDeleteFeature = (id) => {
        const newArr = contentList.filter((item)=>item._id!==id)
        setFeatures(newArr)
    }
    // on save handler
    const onSaveFeatures = (newContent) => {
        setFeatures([
            ...features,
            newContent
        ])
    }

    // SIDE EFFECTS
    useEffect(()=>{
        setContentList(features)
    }, [features])

    return (
        <div className='flex flex-col gap-3 w-full'>
            
            <p className='font-semibold text-xl text-[#344054]'>
                Features
            </p>

            <div className='bg-white rounded-[8px] p-4 w-full flex flex-col gap-4'>

                {/* list */}
                {
                    contentList.length===0?
                    <></>:
                    <>
                        {
                            contentList.map((item)=>(
                                <div 
                                key={item._id}
                                className='bg-[#E1E6EF] rounded-[4px] py-[4px] px-[8px] flex justify-between items-center w-full'>
                                    <p className='text-sm font-normal text-[#1D2433] w-[70%]'>
                                        {item.content}
                                    </p>

                                    <DeleteBin
                                    onClick={()=>{
                                        toggleDeleteModalState()
                                        setDeleteIndex(item._id)
                                    }}
                                    className='h-[20px] w-[20px] cursor-pointer' />
                                </div>
                            ))
                        }
                    </>
                }
                
                <div className='flex items-center justify-between w-full'>
                    
                    {/* add new WYL button */}
                    <button 
                    onClick={toggleInfoModal}
                    className='flex items-center gap-2'>
                        <BiPlusCircle 
                        size={24}
                        className='text-gray-500' />

                        <span className='font-semibold text-sm text-gray-500'>
                            Add new feature
                        </span>
                    </button>

                </div>
            </div>

            {/* modal to add new feature */}
            <MyModal
            modalIsOpen={infoModalState}
            toggleModal={toggleInfoModal}
            disableBtn={true}>
                
                <AddNewInfoView
                isTextArea={true}
                title="Add new feature"
                placeholder={"Enter new feature"}
                onSave={onSaveFeatures}
                closeModal={toggleInfoModal} />

            </MyModal>

            {/* delete modal popup */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
               <DeletePopup
               itemId={deleteIndex}
               onDelete={onDeleteFeature}
               closeModal={toggleDeleteModalState} />
            </MyModal>
        </div>
    )
}

export default Features