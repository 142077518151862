import React from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'

const DurationCounter = ({onChangeCounter, value}) => {
    return (
        <div className='flex gap-4 items-center'>

            {/* decrement */}
            <button 
            onClick={()=>{
                const newValue = value === 1?1:value-1
                onChangeCounter(newValue)
            }}
            disabled={value<=1?true:false}
            className='w-[30px] h-[30px] rounded-[4px] bg-[#1D2433A6] flex items-center justify-center active:bg-gray-400 transition-all disabled:bg-gray-200 disabled:text-gray-300 disabled:cursor-not-allowed text-white font-semibold'>
                <BiMinus className='text-base' />
            </button>

            <span className='text-black font-normal text-sm'>
                {value} day<span className={value>1?"inline":"hidden"}>s</span>
            </span>

            {/* increment */}
            <button 
            onClick={()=>onChangeCounter(value+1)}
            className='w-[30px] h-[30px] rounded-[4px] bg-[#1D2433A6] flex items-center justify-center active:bg-gray-400 transition-all disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed text-white font-semibold'>
                <BiPlus className='text-base' />
            </button>
        </div>
    )
}

export default DurationCounter