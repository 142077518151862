import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { getCritiqueEventParticipantFailure, getCritiqueEventParticipantLoading, getCritiqueEventParticipantSuccess } from "../action";
import { GET_CRITIQUE_EVENT_PARTICIPANT_REQUEST } from "../types";


const ajaxDBCalls = {
    getCritiqueEventParticipants: async () => {
      const reponse = await axios({
        method: "GET",
        url: "critique",
      });
      return reponse;
    }
}

// WORKERS
// get critique event participants
function* getCritiqueEventParticipantsWorker() {
    try {
      yield put(getCritiqueEventParticipantLoading(true));
  
      const response = yield call(ajaxDBCalls.getCritiqueEventParticipants);
  
      yield put(getCritiqueEventParticipantSuccess(response.data));
      yield put(getCritiqueEventParticipantLoading(false));
    } catch (err) {
      let errorMessage = "";
      if (err.request) errorMessage = clientErrorMessage;
  
      if (err.response) {
        console.log("here's what's wrong", err.response.data);
  
        const { message } = err.response.data;
        errorMessage = message;
      }
  
      yield put(getCritiqueEventParticipantFailure(errorMessage));
      yield put(getCritiqueEventParticipantLoading(false));
      yield delay();
      yield put(getCritiqueEventParticipantFailure(""));
    }
  }

//   WATCHERS
function* getCritiqueEventParticipantsWatcher() {
    yield takeLatest(
      GET_CRITIQUE_EVENT_PARTICIPANT_REQUEST,
      getCritiqueEventParticipantsWorker
    );
}

// Critique & Lunch Event saga
export default function* getCritiqueEventParticipantsSaga() {
    yield spawn(getCritiqueEventParticipantsWatcher);
}
  