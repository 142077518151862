import { Skeleton } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import EmptyDataState from '../../Global/EmptyDataState'

const OpticsCard = ({spaces}) => {

    // STATES
    const { getSpacesLoading } = useSelector(state=>{
        const { getSpacesLoading } = state.loadingIndicator
        return {
            getSpacesLoading
        }
    })


    return (
        <>
            {
                getSpacesLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "12vh",
                borderRadius: "10px",
                }} />:
                spaces && spaces.length!==0?
                <div className='bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] p-6 grid grid-cols-2 gap-x-9 w-[70%]'>

                    {/* number of students */}
                    <div className='flex flex-col border-r border-[#E1E6EF]'>
                        <p className='font-normal text-base text-[#1D2433A6]'>
                            All spaces
                        </p>
                        <p className='font-semibold text-xl text-[#3F444D]'>
                            {spaces.length.toLocaleString('en-US')}
                        </p>
                    </div>

                    {/* availble bootcamps */}
                    <div className='flex flex-col'>
                        <p className='font-normal text-base text-[#1D2433A6]'>
                            Currently available
                        </p>
                        <p className='font-semibold text-xl text-[#3F444D]'>
                            {spaces.filter(space=>space.is_Booked===false).length.toLocaleString('en-US')}
                        </p>
                    </div>
                </div>:
                <EmptyDataState />
            }
            
        </>
    )
}

export default OpticsCard