import { useState } from 'react'
import { FiCamera } from 'react-icons/fi'
import profileAvi from "../../../assets/admindashboard/settings/images/Avatar.png"
import MyModal from '../../../layouts/MyModal'
import FileUploadPopup from '../global/FileUploadPopup'

const ProfileAvi = () => {

    // modal state for saved account info
    const [uploadFileModalState, setUploadFileModalState] = useState(false)

    // toggle saved account info
    const toggleUploadFileModal = () => {
        setUploadFileModalState(!uploadFileModalState)
    }

    return (
        <div className='flex items-end gap-5'>
            <div className='relative'>
                <img src={profileAvi} className='h-[140px] w-[140px] object-cover' alt="" />

                {/* select image button */}
                <span
                onClick={toggleUploadFileModal} 
                className='h-[40px] w-[40px] bg-[#8B54F7] flex items-center justify-center rounded-[50%] absolute bottom-[10px] right-[2px] hover:bg-[#ab84fb] cursor-pointer'>
                    <FiCamera color='#fff' size={21} />
                </span>
            </div>

            <div className='flex flex-col'>
                <p className='font-semibold text-xl text-[#344054]'>
                    Olivia Rhye
                </p>

                <p className='text-[#667085] text-base font-normal'>
                    nonso@aimovement.com
                </p>
            </div>

            {/* file upload modal */}
            <MyModal
            disableBtn={false}
            modalIsOpen={uploadFileModalState}
            toggleModal={toggleUploadFileModal}>
                <FileUploadPopup
                closeModal={toggleUploadFileModal} />
            </MyModal>
        </div>
    )
}

export default ProfileAvi