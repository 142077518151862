import React from 'react'
import BodySection from '../../../components/AdminDashboard/Settings/CreateAdmin/BodySection'

import SideNav from '../../../layouts/AdminDashboard/SideNav'

const CreateAdmin = () => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>
            
            {/* Side nav */}
            <SideNav />

            {/* Body Section */}
            <BodySection />

        </div>
    )
}

export default CreateAdmin