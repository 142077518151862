import React from 'react'
import BtnSm from '../../Global/BtnSm'
import PasswordInput from '../../Global/PasswordInput'

const PersonalInformationForm = () => {
    return (
        <div className='bg-[#F8F9FC] p-5 rounded-[10px] flex flex-col gap-5 w-[60%]'>

            <p className='text-[#3F444D] text-xl font-semibold'>
                Personal Information
            </p>

            <form
            className='flex flex-col gap-5'
            onSubmit={(e)=>e.preventDefault()}>

                {/* full name input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        Full name
                    </span>

                    {/* input field */}
                    <input 
                    type="text" 
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Enter your full name' />
                </label>

                {/* email input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        Email
                    </span>

                    {/* input field */}
                    <input 
                    type="email" 
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal disabled:bg-[#f9f5fc] disabled:cursor-not-allowed placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Enter your email' />
                </label>

                {/* phone number input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        Phone number
                    </span>

                    {/* input field */}
                    <input 
                    type="text" 
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Enter your phone number' />
                </label>

                {/* password input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        Password
                    </span>

                    {/* input field */}
                    <PasswordInput />
                </label>

                {/* save changes button */}
                <div className='flex flex-col items-stretch w-full mt-4'>
                    <BtnSm title="Save Changes" />
                </div>
            </form>            
        </div>
    )
}

export default PersonalInformationForm