// DISPATCH ERROR
export const DISPATCH_ERROR = "DISPATCH_ERROR";
export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

// LOGOUT
export const LOGOUT = "LOGOUT";

// REGISTER POST
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_LOADING = "REGISTER_LOADING";

// LOGIN POST

// GET USERS
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_LOADING = "GET_USERS_LOADING";

// GET SINGLE USERS
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAIL = "GET_SINGLE_USER_FAIL";
export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_LOADING = "GET_SINGLE_USER_LOADING";

// UPDATE USERS
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const CLEAR_UPDATE_USER_SUCCESS = "CLEAR_UPDATE_USER_SUCCESS";

// GET BOOTCAMPS FOR ADMIN
export const GET_BOOTCAMPS_ADMIN_SUCCESS = "GET_BOOTCAMPS_ADMIN_SUCCESS";
export const GET_BOOTCAMPS_ADMIN_FAIL = "GET_BOOTCAMPS_ADMIN_FAIL";
export const GET_BOOTCAMPS_ADMIN_REQUEST = "GET_BOOTCAMPS_ADMIN_REQUEST";
export const GET_BOOTCAMPS_ADMIN_LOADING = "GET_BOOTCAMPS_ADMIN_LOADING";

// GET_SINGLE_BOOTCAMPS
export const GET_SINGLE_BOOTCAMPS_SUCCESS = "GET_SINGLE_BOOTCAMPS_SUCCESS";
export const GET_SINGLE_BOOTCAMPS_FAIL = "GET_SINGLE_BOOTCAMPS_FAIL";
export const GET_SINGLE_BOOTCAMPS_REQUEST = "GET_SINGLE_BOOTCAMPS_REQUEST";
export const GET_SINGLE_BOOTCAMPS_LOADING = "GET_SINGLE_BOOTCAMPS_LOADING";

// PUT_EDIT_BOOTCAMP
export const PUT_EDIT_BOOTCAMP_SUCCESS = "PUT_EDIT_BOOTCAMP_SUCCESS";
export const PUT_EDIT_BOOTCAMP_FAIL = "PUT_EDIT_BOOTCAMP_FAIL";
export const PUT_EDIT_BOOTCAMP_REQUEST = "PUT_EDIT_BOOTCAMP_REQUEST";
export const PUT_EDIT_BOOTCAMP_LOADING = "PUT_EDIT_BOOTCAMP_LOADING";
export const CLEAR_PUT_EDIT_BOOTCAMP_SUCCESS =
  "CLEAR_PUT_EDIT_BOOTCAMP_SUCCESS";

// POST_CREATE_BOOTCAMP
export const POST_CREATE_BOOTCAMP_SUCCESS = "POST_CREATE_BOOTCAMP_SUCCESS";
export const POST_CREATE_BOOTCAMP_FAIL = "POST_CREATE_BOOTCAMP_FAIL";
export const POST_CREATE_BOOTCAMP_REQUEST = "POST_CREATE_BOOTCAMP_REQUEST";
export const POST_CREATE_BOOTCAMP_LOADING = "POST_CREATE_BOOTCAMP_LOADING";
export const CLEAR_CREATE_BOOTCAMP_SUCCESS = "CLEAR_CREATE_BOOTCAMP_SUCCESS";

// DELETE_COURSE
export const DELETE_BOOTCAMP_SUCCESS = "DELETE_BOOTCAMP_SUCCESS";
export const DELETE_BOOTCAMP_FAIL = "DELETE_BOOTCAMP_FAIL";
export const DELETE_BOOTCAMP_REQUEST = "DELETE_BOOTCAMP_REQUEST";
export const DELETE_BOOTCAMP_LOADING = "DELETE_BOOTCAMP_LOADING";
export const CLEAR_DELETE_BOOTCAMP_SUCCESS = "CLEAR_DELETE_BOOTCAMP_SUCCESS";

// GET_COURSES
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAIL = "GET_COURSES_FAIL";
export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
export const GET_COURSES_LOADING = "GET_COURSES_LOADING";

// GET_SINGLE_COURSES
export const GET_SINGLE_COURSES_SUCCESS = "GET_SINGLE_COURSES_SUCCESS";
export const GET_SINGLE_COURSES_FAIL = "GET_SINGLE_COURSES_FAIL";
export const GET_SINGLE_COURSES_REQUEST = "GET_SINGLE_COURSES_REQUEST";
export const GET_SINGLE_COURSES_LOADING = "GET_SINGLE_COURSES_LOADING";

// PUT_EDIT_SINGLE_COURSE
export const PUT_EDIT_SINGLE_COURSE_SUCCESS = "PUT_EDIT_SINGLE_COURSE_SUCCESS";
export const PUT_EDIT_SINGLE_COURSE_FAIL = "PUT_EDIT_SINGLE_COURSE_FAIL";
export const PUT_EDIT_SINGLE_COURSE_REQUEST = "PUT_EDIT_SINGLE_COURSE_REQUEST";
export const PUT_EDIT_SINGLE_COURSE_LOADING = "PUT_EDIT_SINGLE_COURSE_LOADING";
export const CLEAR_PUT_SINGLE_COURSE_SUCCESS =
  "CLEAR_PUT_SINGLE_COURSE_SUCCESS";

// POST_CREATE_COURSE
export const POST_CREATE_COURSE_SUCCESS = "POST_CREATE_COURSE_SUCCESS";
export const POST_CREATE_COURSE_FAIL = "POST_CREATE_COURSE_FAIL";
export const POST_CREATE_COURSE_REQUEST = "POST_CREATE_COURSE_REQUEST";
export const POST_CREATE_COURSE_LOADING = "POST_CREATE_COURSE_LOADING";
export const CLEAR_POST_CREATE_COURSE_SUCCESS =
  "CLEAR_POST_CREATE_COURSE_SUCCESS";

// DELETE_COURSE
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";
export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_LOADING = "DELETE_COURSE_LOADING";
export const CLEAR_DELETE_COURSE_SUCCESS = "CLEAR_DELETE_COURSE_SUCCESS";

// GET EVENTS FOR ADMIN
export const GET_EVENTS_ADMIN_SUCCESS = "GET_EVENTS_ADMIN_SUCCESS";
export const GET_EVENTS_ADMIN_FAIL = "GET_EVENTS_ADMIN_FAIL";
export const GET_EVENTS_ADMIN_REQUEST = "GET_EVENTS_ADMIN_REQUEST";
export const GET_EVENTS_ADMIN_LOADING = "GET_EVENTS_ADMIN_LOADING";

// GET CLUBS FOR ADMIN
export const GET_CLUBS_ADMIN_SUCCESS = "GET_CLUBS_ADMIN_SUCCESS";
export const GET_CLUBS_ADMIN_FAIL = "GET_CLUBS_ADMIN_FAIL";
export const GET_CLUBS_ADMIN_REQUEST = "GET_CLUBS_ADMIN_REQUEST";
export const GET_CLUBS_ADMIN_LOADING = "GET_CLUBS_ADMIN_LOADING";

// GET CLUBS SUBSCRIPTIONS FOR ADMIN
export const GET_CLUB_SUBSCRIPTION_SUCCESS = "GET_CLUB_SUBSCRIPTION_SUCCESS";
export const GET_CLUB_SUBSCRIPTION_FAIL = "GET_CLUB_SUBSCRIPTION_FAIL";
export const GET_CLUB_SUBSCRIPTION_REQUEST = "GET_CLUB_SUBSCRIPTION_REQUEST";
export const GET_CLUB_SUBSCRIPTION_LOADING = "GET_CLUB_SUBSCRIPTION_LOADING";

// ADD USER STUDENT TO BOOTCAMP
export const PUT_STUDENT_TO_BOOTCAMP_SUCCESS =
  "PUT_STUDENT_TO_BOOTCAMP_SUCCESS";
export const PUT_STUDENT_TO_BOOTCAMP_FAIL = "PUT_STUDENT_TO_BOOTCAMP_FAIL";
export const PUT_STUDENT_TO_BOOTCAMP_REQUEST =
  "PUT_STUDENT_TO_BOOTCAMP_REQUEST";
export const PUT_STUDENT_TO_BOOTCAMP_LOADING =
  "PUT_STUDENT_TO_BOOTCAMP_LOADING";
export const CLEAR_PUT_STUDENT_BOOTCAMP_SUCCESS =
  "CLEAR_PUT_STUDENT_BOOTCAMP_SUCCESS";

// DELETE STUDENT FROM BOOTCAMP
export const DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS =
  "DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS";
export const DELETE_STUDENT_FROM_BOOTCAMP_FAIL =
  "DELETE_STUDENT_FROM_BOOTCAMP_FAIL";
export const DELETE_STUDENT_FROM_BOOTCAMP_REQUEST =
  "DELETE_STUDENT_FROM_BOOTCAMP_REQUEST";
export const DELETE_STUDENT_FROM_BOOTCAMP_LOADING =
  "DELETE_STUDENT_FROM_BOOTCAMP_LOADING";
export const CLEAR_DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS =
  "CLEAR_DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS";

// ADD USER TO EVENT
export const PUT_USER_TO_EVENT_SUCCESS = "PUT_USER_TO_EVENT_SUCCESS";
export const PUT_USER_TO_EVENT_FAIL = "PUT_USER_TO_EVENT_FAIL";
export const PUT_USER_TO_EVENT_REQUEST = "PUT_USER_TO_EVENT_REQUEST";
export const PUT_USER_TO_EVENT_LOADING = "PUT_USER_TO_EVENT_LOADING";
export const CLEAR_PUT_USER_EVENT_SUCCESS = "CLEAR_PUT_USER_EVENT_SUCCESS";

// CREATE CLUB SUBSCRIPTION
export const POST_CREATE_CLUB_SUBSCRIPTION_SUCCESS =
  "POST_CREATE_CLUB_SUBSCRIPTION_SUCCESS";
export const POST_CREATE_CLUB_SUBSCRIPTION_FAIL =
  "POST_CREATE_CLUB_SUBSCRIPTION_FAIL";
export const POST_CREATE_CLUB_SUBSCRIPTION_REQUEST =
  "POST_CREATE_CLUB_SUBSCRIPTION_REQUEST";
export const POST_CREATE_CLUB_SUBSCRIPTION_LOADING =
  "POST_CREATE_CLUB_SUBSCRIPTION_LOADING";
export const CLEAR_CREATE_CLUB_SUBSCRIPTION_SUCCESS =
  "CLEAR_CREATE_CLUB_SUBSCRIPTION_SUCCESS";

// DELETE CLUB SUBSCRIPTION
export const UPDATE_CLUB_SUBSCRIPTION_SUCCESS =
  "UPDATE_CLUB_SUBSCRIPTION_SUCCESS";
export const UPDATE_CLUB_SUBSCRIPTION_FAIL = "UPDATE_CLUB_SUBSCRIPTION_FAIL";
export const UPDATE_CLUB_SUBSCRIPTION_REQUEST =
  "UPDATE_CLUB_SUBSCRIPTION_REQUEST";
export const UPDATE_CLUB_SUBSCRIPTION_LOADING =
  "UPDATE_CLUB_SUBSCRIPTION_LOADING";
export const CLEAR_UPDATE_CLUB_SUBSCRIPTION_SUCCESS =
  "CLEAR_UPDATE_CLUB_SUBSCRIPTION_SUCCESS";

// DELETE USER
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const CLEAR_DELETE_USER_SUCCESS = "CLEAR_DELETE_USER_SUCCESS";

// GET specific events
export const GET_SINGLE_EVENT_REQUEST = "GET_SINGLE_EVENT_REQUEST";
export const GET_SINGLE_EVENT_FAIL = "GET_SINGLE_EVENT_FAIL";
export const GET_SINGLE_EVENT_SUCCESS = "GET_SINGLE_EVENT_SUCCESS";
export const GET_SINGLE_EVENT_LOADING = "GET_SINGLE_EVENT_LOADING";

// UPDATE_EVENT
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_LOADING = "UPDATE_EVENT_LOADING";
export const CLEAR_UPDATE_EVENT_SUCCESS = "CLEAR_UPDATE_EVENT_SUCCESS";

// CREATE_EVENT
export const POST_CREATE_EVENT_SUCCESS = "POST_CREATE_EVENT_SUCCESS";
export const POST_CREATE_EVENT_FAIL = "POST_CREATE_EVENT_FAIL";
export const POST_CREATE_EVENT_REQUEST = "POST_CREATE_EVENT_REQUEST";
export const POST_CREATE_EVENT_LOADING = "POST_CREATE_EVENT_LOADING";
export const CLEAR_CREATE_EVENT_SUCCESS = "CLEAR_CREATE_EVENT_SUCCESS";

// DELETE EVENT
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_LOADING = "DELETE_EVENT_LOADING";
export const CLEAR_DELETE_EVENT_SUCCESS = "CLEAR_DELETE_EVENT_SUCCESS";

// ADD SPEAKER TO EVENT
export const PUT_SPEAKER_TO_EVENT_SUCCESS = "PUT_SPEAKER_TO_EVENT_SUCCESS";
export const PUT_SPEAKER_TO_EVENT_FAIL = "PUT_SPEAKER_TO_EVENT_FAIL";
export const PUT_SPEAKER_TO_EVENT_REQUEST = "PUT_SPEAKER_TO_EVENT_REQUEST";
export const PUT_SPEAKER_TO_EVENT_LOADING = "PUT_SPEAKER_TO_EVENT_LOADING";
export const CLEAR_SPEAKER_TO_EVENT_SUCCESS = "CLEAR_SPEAKER_TO_EVENT_SUCCESS";

// ADD SPEAKER TO EVENT
export const DELETE_SPEAKER_FROM_EVENT_SUCCESS =
  "DELETE_SPEAKER_FROM_EVENT_SUCCESS";
export const DELETE_SPEAKER_FROM_EVENT_FAIL = "DELETE_SPEAKER_FROM_EVENT_FAIL";
export const DELETE_SPEAKER_FROM_EVENT_REQUEST =
  "DELETE_SPEAKER_FROM_EVENT_REQUEST";
export const DELETE_SPEAKER_FROM_EVENT_LOADING =
  "DELETE_SPEAKER_FROM_EVENT_LOADING";
export const CLEAR_DELETE_SPEAKER_SUCCESS = "CLEAR_DELETE_SPEAKER_SUCCESS";

// GET SINGLE CLUB
export const GET_SINGLE_CLUB_REQUEST = "GET_SINGLE_CLUB_REQUEST";
export const GET_SINGLE_CLUB_FAIL = "GET_SINGLE_CLUB_FAIL";
export const GET_SINGLE_CLUB_SUCCESS = "GET_SINGLE_CLUB_SUCCESS";
export const GET_SINGLE_CLUB_LOADING = "GET_SINGLE_CLUB_LOADING";

// UPDATE_CLUB
export const UPDATE_CLUB_SUCCESS = "UPDATE_CLUB_SUCCESS";
export const UPDATE_CLUB_FAIL = "UPDATE_CLUB_FAIL";
export const UPDATE_CLUB_REQUEST = "UPDATE_CLUB_REQUEST";
export const UPDATE_CLUB_LOADING = "UPDATE_CLUB_LOADING";
export const CLEAR_UPDATE_CLUB_SUCCESS = "CLEAR_UPDATE_CLUB_SUCCESS";

// CREATE_CLUB
export const POST_CREATE_CLUB_SUCCESS = "POST_CREATE_CLUB_SUCCESS";
export const POST_CREATE_CLUB_FAIL = "POST_CREATE_CLUB_FAIL";
export const POST_CREATE_CLUB_REQUEST = "POST_CREATE_CLUB_REQUEST";
export const POST_CREATE_CLUB_LOADING = "POST_CREATE_CLUB_LOADING";
export const CLEAR_CREATE_CLUB_SUCCESS = "CLEAR_CREATE_CLUB_SUCCESS";

// DELETE CLUB
export const DELETE_CLUB_SUCCESS = "DELETE_CLUB_SUCCESS";
export const DELETE_CLUB_FAIL = "DELETE_CLUB_FAIL";
export const DELETE_CLUB_REQUEST = "DELETE_CLUB_REQUEST";
export const DELETE_CLUB_LOADING = "DELETE_CLUB_LOADING";
export const CLEAR_DELETE_CLUB_SUCCESS = "CLEAR_DELETE_CLUB_SUCCESS";

// GET SPACES
export const GET_SPACES_REQUEST = "GET_SPACES_REQUEST";
export const GET_SPACES_FAIL = "GET_SPACES_FAIL";
export const GET_SPACES_SUCCESS = "GET_SPACES_SUCCESS";
export const GET_SPACES_LOADING = "GET_SPACES_LOADING";

// GET SINGLE SPACE
export const GET_SINGLE_SPACE_REQUEST = "GET_SINGLE_SPACE_REQUEST";
export const GET_SINGLE_SPACE_FAIL = "GET_SINGLE_SPACE_FAIL";
export const GET_SINGLE_SPACE_SUCCESS = "GET_SINGLE_SPACE_SUCCESS";
export const GET_SINGLE_SPACE_LOADING = "GET_SINGLE_SPACE_LOADING";

// UPDATE_SPACE
export const UPDATE_SPACE_SUCCESS = "UPDATE_SPACE_SUCCESS";
export const UPDATE_SPACE_FAIL = "UPDATE_SPACE_FAIL";
export const UPDATE_SPACE_REQUEST = "UPDATE_SPACE_REQUEST";
export const UPDATE_SPACE_LOADING = "UPDATE_SPACE_LOADING";
export const CLEAR_UPDATE_SPACE_SUCCESS = "CLEAR_UPDATE_SPACE_SUCCESS";

// CREATE_SPACE
export const CREATE_SPACE_SUCCESS = "CREATE_SPACE_SUCCESS";
export const CREATE_SPACE_FAIL = "CREATE_SPACE_FAIL";
export const CREATE_SPACE_REQUEST = "CREATE_SPACE_REQUEST";
export const CREATE_SPACE_LOADING = "CREATE_SPACE_LOADING";
export const CLEAR_CREATE_SPACE_SUCCESS = "CLEAR_CREATE_SPACE_SUCCESS";

// BOOK SPACE
export const BOOK_SPACE_SUCCESS = "BOOK_SPACE_SUCCESS";
export const BOOK_SPACE_FAIL = "BOOK_SPACE_FAIL";
export const BOOK_SPACE_REQUEST = "BOOK_SPACE_REQUEST";
export const BOOK_SPACE_LOADING = "BOOK_SPACE_LOADING";
export const CLEAR_BOOK_SPACE_SUCCESS = "CLEAR_BOOK_SPACE_SUCCESS";

// UNBOOK SPACE
export const UNBOOK_SPACE_SUCCESS = "UNBOOK_SPACE_SUCCESS";
export const UNBOOK_SPACE_FAIL = "UNBOOK_SPACE_FAIL";
export const UNBOOK_SPACE_REQUEST = "UNBOOK_SPACE_REQUEST";
export const UNBOOK_SPACE_LOADING = "UNBOOK_SPACE_LOADING";
export const CLEAR_UNBOOK_SPACE_SUCCESS = "CLEAR_UNBOOK_SPACE_SUCCESS";

// GET BOOTCAMP STUDENTS
export const GET_BOOTCAMP_STUDENTS_SUCCESS = "GET_BOOTCAMP_STUDENTS_SUCCESS";
export const GET_BOOTCAMP_STUDENTS_REQUEST = "GET_BOOTCAMP_STUDENTS_REQUEST";
export const GET_BOOTCAMP_STUDENTS_FAIL = "GET_BOOTCAMP_STUDENTS_FAIL";
export const GET_BOOTCAMP_STUDENTS_LOADING = "GET_BOOTCAMP_STUDENTS_LOADING";

// GET DIGITAL MARKETING STUDENTS
export const GET_DIGITAL_MARKETING_STUDENTS_SUCCESS =
  "GET_DIGITAL_MARKETING_STUDENTS_SUCCESS";
export const GET_DIGITAL_MARKETING_STUDENTS_REQUEST =
  "GET_DIGITAL_MARKETING_STUDENTS_REQUEST";
export const GET_DIGITAL_MARKETING_STUDENTS_FAIL =
  "GET_DIGITAL_MARKETING_STUDENTS_FAIL";
export const GET_DIGITAL_MARKETING_STUDENTS_LOADING =
  "GET_DIGITAL_MARKETING_STUDENTS_LOADING";

// GET CRITIQUE EVENT PARTICIPANT
export const GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS =
  "GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS";
export const GET_CRITIQUE_EVENT_PARTICIPANT_REQUEST =
  "GET_CRITIQUE_EVENT_PARTICIPANT_REQUEST";
export const GET_CRITIQUE_EVENT_PARTICIPANT_FAIL =
  "GET_CRITIQUE_EVENT_PARTICIPANT_FAIL";
export const GET_CRITIQUE_EVENT_PARTICIPANT_LOADING =
  "GET_CRITIQUE_EVENT_PARTICIPANT_LOADING";

// GET DESIGN BOOTCAMP PARTICIPANT
export const GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS =
  "GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS";
export const GET_DESIGN_BOOTCAMP_PARTICIPANTS_REQUEST =
  "GET_DESIGN_BOOTCAMP_PARTICIPANTS_REQUEST";
export const GET_DESIGN_BOOTCAMP_PARTICIPANTS_FAIL =
  "GET_DESIGN_BOOTCAMP_PARTICIPANTS_FAIL";
export const GET_DESIGN_BOOTCAMP_PARTICIPANTS_LOADING =
  "GET_DESIGN_BOOTCAMP_PARTICIPANTS_LOADING";

// GET DESIGN BOOTCAMP PARTICIPANT
export const GET_DATA_ANALYSIS_FORM_SUCCESS = "GET_DATA_ANALYSIS_FORM_SUCCESS";
export const GET_DATA_ANALYSIS_FORM_REQUEST = "GET_DATA_ANALYSIS_FORM_REQUEST";
export const GET_DATA_ANALYSIS_FORM_FAIL = "GET_DATA_ANALYSIS_FORM_FAIL";
export const GET_DATA_ANALYSIS_FORM_LOADING = "GET_DATA_ANALYSIS_FORM_LOADING";
