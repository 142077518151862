import React from 'react'
import { AiFillCalendar } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbCertificate } from "react-icons/tb";
import { GrUserExpert } from "react-icons/gr";

const CourseBenefits = ({duration}) => {
    return (
        <div className='bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col gap-4 py-6 px-5 h-fit'>

            <p className='font-semibold text-xl text-[#3F444D]'>
                Course Benefits
            </p>

            <div className='flex items-center gap-3'>
                <AiFillCalendar size={24} color={"#000"} />
                <span className='font-normal text-base text-[#3F444D]'>
                    {/* 4 months intensive training */}
                    Intensive training
                </span>
            </div>

            <div className='flex items-center gap-3'>
                <HiOutlineOfficeBuilding size={24} color={"#000"} />
                <span className='font-normal text-base text-[#3F444D]'>
                    Physical/Online learning
                </span>
            </div>

            <div className='flex items-center gap-3'>
                <GrUserExpert size={24} color={"#000"} />
                <span className='font-normal text-base text-[#3F444D]'>
                    Access to industry experts
                </span>
            </div>

            <div className='flex items-center gap-3'>
                <TbCertificate size={24} color={"#000"} />
                <span className='font-normal text-base text-[#3F444D]'>
                    Certificate of completion
                </span>
            </div>
            
        </div>
    )
}

export default CourseBenefits