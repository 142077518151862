import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEventsAdminRequest } from '../../../redux/action'
import ProfileAvatar from '../global/ProfileAvatar'
import AllUpcomingEvents from './AllUpcomingEvents'
import EventOptics from './EventOptics'
import UpcomingEvents from './UpcomingEvents'

const EventBody = () => {
    
    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()


    // STATES
    const { events } = useSelector(state=>{
        const { events } = state.eventsReducer
        return {
            events
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getEventsAdminRequest())
    }, [])


    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Events
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>
                
                <EventOptics
                pastEvents={1000}
                futureEvents={4} />

                <UpcomingEvents
                upcomingEvent={events} />

                <AllUpcomingEvents
                events={events} />
            </section>
        </section>
    )
}

export default EventBody