import React from 'react'
import BodySection from '../../../components/AdminDashboard/SpaceBooking/CreateSpace/BodySection'
import SideNav from '../../../layouts/AdminDashboard/SideNav'

const CreateSpace = () => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>

            {/* sidenav */}
            <SideNav />

            {/* Body Section */}
            <BodySection />
            
        </div>
    )
}

export default CreateSpace