import {
  DELETE_BOOTCAMP_LOADING,
  UPDATE_CLUB_SUBSCRIPTION_LOADING,
  DELETE_COURSE_LOADING,
  DELETE_STUDENT_FROM_BOOTCAMP_LOADING,
  GET_BOOTCAMPS_ADMIN_LOADING,
  GET_CLUBS_ADMIN_LOADING,
  GET_CLUB_SUBSCRIPTION_LOADING,
  GET_COURSES_LOADING,
  GET_EVENTS_ADMIN_LOADING,
  GET_SINGLE_BOOTCAMPS_LOADING,
  GET_SINGLE_COURSES_LOADING,
  GET_SINGLE_USER_LOADING,
  GET_USERS_LOADING,
  POST_CREATE_BOOTCAMP_LOADING,
  POST_CREATE_CLUB_SUBSCRIPTION_LOADING,
  POST_CREATE_COURSE_LOADING,
  PUT_EDIT_BOOTCAMP_LOADING,
  PUT_EDIT_SINGLE_COURSE_LOADING,
  PUT_STUDENT_TO_BOOTCAMP_LOADING,
  PUT_USER_TO_EVENT_LOADING,
  REGISTER_LOADING,
  UPDATE_USER_LOADING,
  DELETE_USER_LOADING,
  GET_SINGLE_EVENT_LOADING,
  UPDATE_EVENT_LOADING,
  POST_CREATE_EVENT_LOADING,
  DELETE_EVENT_LOADING,
  DELETE_SPEAKER_FROM_EVENT_LOADING,
  PUT_SPEAKER_TO_EVENT_LOADING,
  GET_SINGLE_CLUB_LOADING,
  UPDATE_CLUB_LOADING,
  POST_CREATE_CLUB_LOADING,
  DELETE_CLUB_LOADING,
  GET_SPACES_LOADING,
  UPDATE_SPACE_LOADING,
  GET_SINGLE_SPACE_LOADING,
  CREATE_SPACE_LOADING,
  BOOK_SPACE_LOADING,
  UNBOOK_SPACE_LOADING,
  GET_BOOTCAMP_STUDENTS_LOADING,
  GET_DIGITAL_MARKETING_STUDENTS_LOADING,
  GET_CRITIQUE_EVENT_PARTICIPANT_LOADING,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_LOADING,
  GET_DATA_ANALYSIS_FORM_LOADING,
} from "../types";

const initialState = {
  // auth
  registerLoading: false,
  getUsersLoading: true,
  getSingleUserLoading: true,
  updateUserLoading: false,
  deleteUserLoading: false,

  // bootcamp
  getBootcampsAdminLoading: true,
  getSingleBootcampLoading: true,
  putEditSingleBootcampLoading: false,
  postCreateBootcampLoading: false,
  putStudentToBootcampLoading: false,
  deleteBootcampLoading: false,
  deleteStudentFromBootcampLoading: false,

  // courses
  getCoursesLoading: true,
  getSingleCourseLoading: true,
  putEditSingleCourseLoading: false,
  postCreateCourseLoading: false,
  deleteCourseLoading: false,

  // events
  getEventsLoading: true,
  getClubsLoading: true,
  putUserToEventLoading: false,
  singleEventLoading: true,
  updateEventLoading: false,
  createEventLoading: false,
  deleteEventLoading: false,
  deleteSpeakerFromEventLoading: false,
  addSpeakerToEventLoading: false,

  // club subscription
  postCreateClubSubscriptionLoading: false,
  getClubSubscriptionsLoading: true,
  updateClubSubscriptionLoading: false,
  getSingleClubLoading: true,
  updateClubLoading: false,
  postCreateClubLoading: false,
  deleteClubLoading: false,

  // spaces
  getSpacesLoading: true,
  updateSpaceLoading: false,
  getSingleSpaceLoading: true,
  createSpaceLoading: false,
  bookSpaceLoading: false,
  unbookSpaceLoading: false,

  // bootcamp events
  getBootcampStudentsLoading: true,
  getDigitalMarketingStudentsLoading: true,
  critiqueEventParticipantsLoading: true,
  getDesignBcampParticipantsLoading: true,
  getDataAnalysisFormLoading: true,
};

const loadingIndicator = (state = initialState, { type, payload }) => {
  switch (type) {
    // register
    case REGISTER_LOADING:
      return {
        ...state,
        registerLoading: payload,
      };

    // get users
    case GET_USERS_LOADING:
      return {
        ...state,
        getUsersLoading: payload,
      };

    // get single user
    case GET_SINGLE_USER_LOADING:
      return {
        ...state,
        getSingleUserLoading: payload,
      };

    // update user
    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: payload,
      };

    // get bootcamps
    case GET_BOOTCAMPS_ADMIN_LOADING:
      return {
        ...state,
        getBootcampsAdminLoading: payload,
      };

    // get single bootcamp
    case GET_SINGLE_BOOTCAMPS_LOADING:
      return {
        ...state,
        getSingleBootcampLoading: payload,
      };

    // edit bootcamp
    case PUT_EDIT_BOOTCAMP_LOADING:
      return {
        ...state,
        putEditSingleBootcampLoading: payload,
      };

    // create bootcamp
    case POST_CREATE_BOOTCAMP_LOADING:
      return {
        ...state,
        postCreateBootcampLoading: payload,
      };

    // get courses
    case GET_COURSES_LOADING:
      return {
        ...state,
        getCoursesLoading: payload,
      };

    // get single course
    case GET_SINGLE_COURSES_LOADING:
      return {
        ...state,
        getSingleCourseLoading: payload,
      };

    // edit course
    case PUT_EDIT_SINGLE_COURSE_LOADING:
      return {
        ...state,
        putEditSingleCourseLoading: payload,
      };

    // create course
    case POST_CREATE_COURSE_LOADING:
      return {
        ...state,
        postCreateCourseLoading: payload,
      };

    // get events for admin
    case GET_EVENTS_ADMIN_LOADING:
      return {
        ...state,
        getEventsLoading: payload,
      };

    // get clubs for admin
    case GET_CLUBS_ADMIN_LOADING:
      return {
        ...state,
        getClubsLoading: payload,
      };

    // add student to bootcamp
    case PUT_STUDENT_TO_BOOTCAMP_LOADING:
      return {
        ...state,
        putStudentToBootcampLoading: payload,
      };

    // add user to event
    case PUT_USER_TO_EVENT_LOADING:
      return {
        ...state,
        putUserToEventLoading: payload,
      };

    // create club subscription
    case POST_CREATE_CLUB_SUBSCRIPTION_LOADING:
      return {
        ...state,
        postCreateClubSubscriptionLoading: payload,
      };

    // delete bootcamp
    case DELETE_BOOTCAMP_LOADING:
      return {
        ...state,
        deleteBootcampLoading: payload,
      };

    // delete course
    case DELETE_COURSE_LOADING:
      return {
        ...state,
        deleteCourseLoading: payload,
      };

    // delete student from bootcamp
    case DELETE_STUDENT_FROM_BOOTCAMP_LOADING:
      return {
        ...state,
        deleteStudentFromBootcampLoading: payload,
      };

    // club subscription
    case GET_CLUB_SUBSCRIPTION_LOADING:
      return {
        ...state,
        getClubSubscriptionsLoading: payload,
      };

    // update club subscription
    case UPDATE_CLUB_SUBSCRIPTION_LOADING:
      return {
        ...state,
        updateClubSubscriptionLoading: payload,
      };

    // delete user
    case DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserLoading: payload,
      };

    // get single event
    case GET_SINGLE_EVENT_LOADING:
      return {
        ...state,
        singleEventLoading: payload,
      };

    // update event
    case UPDATE_EVENT_LOADING:
      return {
        ...state,
        updateEventLoading: payload,
      };

    // create event
    case POST_CREATE_EVENT_LOADING:
      return {
        ...state,
        createEventLoading: payload,
      };

    // delete event
    case DELETE_EVENT_LOADING:
      return {
        ...state,
        deleteEventLoading: payload,
      };

    // delete speaker from event
    case DELETE_SPEAKER_FROM_EVENT_LOADING:
      return {
        ...state,
        deleteSpeakerFromEventLoading: payload,
      };

    // add speaker to event
    case PUT_SPEAKER_TO_EVENT_LOADING:
      return {
        ...state,
        addSpeakerToEventLoading: payload,
      };

    // get single club
    case GET_SINGLE_CLUB_LOADING:
      return {
        ...state,
        getSingleClubLoading: payload,
      };

    // update club
    case UPDATE_CLUB_LOADING:
      return {
        ...state,
        updateClubLoading: payload,
      };

    // create club
    case POST_CREATE_CLUB_LOADING:
      return {
        ...state,
        postCreateClubLoading: payload,
      };

    // delete club
    case DELETE_CLUB_LOADING:
      return {
        ...state,
        deleteClubLoading: payload,
      };

    // get spaces
    case GET_SPACES_LOADING:
      return {
        ...state,
        getSpacesLoading: payload,
      };

    // update space
    case UPDATE_SPACE_LOADING:
      return {
        ...state,
        updateSpaceLoading: payload,
      };

    // get single space
    case GET_SINGLE_SPACE_LOADING:
      return {
        ...state,
        getSingleSpaceLoading: payload,
      };

    // create space
    case CREATE_SPACE_LOADING:
      return {
        ...state,
        createSpaceLoading: payload,
      };

    // book space
    case BOOK_SPACE_LOADING:
      return {
        ...state,
        bookSpaceLoading: payload,
      };

    // unbook space
    case UNBOOK_SPACE_LOADING:
      return {
        ...state,
        unbookSpaceLoading: payload,
      };

    // get bootcamp students
    case GET_BOOTCAMP_STUDENTS_LOADING:
      return {
        ...state,
        getBootcampStudentsLoading: payload,
      };

    // get digital marketing bootcamp students
    case GET_DIGITAL_MARKETING_STUDENTS_LOADING:
      return {
        ...state,
        getDigitalMarketingStudentsLoading: payload,
      };

    // get digital marketing bootcamp students
    case GET_CRITIQUE_EVENT_PARTICIPANT_LOADING:
      return {
        ...state,
        critiqueEventParticipantsLoading: payload,
      };

    // get digital marketing bootcamp students
    case GET_DESIGN_BOOTCAMP_PARTICIPANTS_LOADING:
      return {
        ...state,
        getDesignBcampParticipantsLoading: payload,
      };

    // get data analysis form loading
    case GET_DATA_ANALYSIS_FORM_LOADING:
      return {
        ...state,
        getDataAnalysisFormLoading: payload,
      };

    default:
      return state;
  }
};

export default loadingIndicator;
