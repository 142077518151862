import React, { useEffect, useState } from "react";
import ProfileAvatar from "../../global/ProfileAvatar";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import BtnSm from "../../../Global/BtnSm";
import Select from "react-select";
import "../../global/styles/custom-select-styling.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesRequest,
  postCreateBootcampRequest,
} from "../../../../redux/action";
import { FormError } from "../../../Global/Error";
import { CircularProgress } from "@material-ui/core";
import featuredImage from "../../../../assets/admindashboard/bootcamp/images/new-course-image.png";
import FileUploadPopup from "../../global/FileUploadPopup";
import { FiCamera } from "react-icons/fi";
import MyModal from "../../../../layouts/MyModal";

const BodySection = () => {
  // modal state for saved account info
  const [uploadFileModalState, setUploadFileModalState] = useState(false);

  // toggle saved account info
  const toggleUploadFileModal = () => {
    setUploadFileModalState(!uploadFileModalState);
  };

  // STATES
  // courses dataset
  const [courseData, setCourseData] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseId, setCourseId] = useState("");
  // track
  const [track, setTrack] = useState(null);
  // hasStarted
  const [hasStarted, setHasStarted] = useState(null);
  // bootcamp number
  const [bCampNum, setBcampNum] = useState("");
  // image url state
  const [imageUrl, setImageUrl] = useState("")

  // single bootcamp state
  const {
    courses,
    postCreateBootcampLoading,
    postCreateBootcampSuccess,
    postCreateBootcampError,
    getCoursesLoading
  } = useSelector((state) => {
    const { courses } = state.coursesReducer;
    const { 
      postCreateBootcampLoading,
      getCoursesLoading 
    } = state.loadingIndicator;
    const {
      success: { postCreateBootcamp: postCreateBootcampSuccess },
      errors: { postCreateBootcamp: postCreateBootcampError },
    } = state.ajaxStatuses;
    return {
      courses,
      postCreateBootcampLoading,
      postCreateBootcampSuccess,
      postCreateBootcampError,
      getCoursesLoading
    };
  });

  // OBJECT INITIALIZATION
  // track Dataset
  const trackData = [
    { value: "regular", label: "Regular" },
    { value: "pathway", label: "Special" },
  ];
  // isStarted dataset
  const isStarted = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  // history object
  const history = useHistory();
  // dispatch object
  const dispatch = useDispatch();
  // Formik value
  const formik = useFormik({
    initialValues: {
      imgurl: "",
      name: "",
      course_id: "",
      pathway: "",
      course_type: "",
      old_price: "",
      new_price: "",
      duration: "",
      entry_disable: false,
      is_started: "",
      is_ended: false,
      start_date: "",
      end_date: "",
      description: "",
    },
    onSubmit: (values) => {
      const finalObj = { ...values };
      finalObj.name = courseName;
      finalObj.course_id = courseId;
      finalObj.is_started = hasStarted;
      finalObj.duration = parseInt(values.duration);
      finalObj.course_type = track;
      finalObj.old_price = parseInt(values.old_price);
      finalObj.new_price = parseInt(values.new_price);
      finalObj.start_date = new Date(values.start_date).toISOString();
      finalObj.end_date = new Date(values.end_date).toISOString();
      finalObj.imgurl = imageUrl

      // dispatch Action
      dispatch(
        postCreateBootcampRequest({
          bootcampData: finalObj,
        })
      );
    },
    validationSchema: Yup.object({
      duration: Yup.number().required(),
      pathway: Yup.string().min(4).required(),
      old_price: Yup.number("Input should be a number").required(),
      new_price: Yup.number("Input should be a number").required(),
      start_date: Yup.date().required(),
      end_date: Yup.date().required(),
      description: Yup.string().min(7).required(),
    }),
  });


  // HANDLERS
  const handleChange = (obj) => {
    setCourseName(obj.label);
    setCourseId(obj.value);
  };


  // SIDE EFFECTS
  // get courses
  useEffect(() => {
    dispatch(getCoursesRequest());
  }, []);
  // populate course data
  useEffect(() => {
    setCourseData(
      courses.map(({ _id, name }) => ({ value: _id, label: name }))
    );
  }, [courses]);

  // check for success and errors
  useEffect(() => {
    if (postCreateBootcampError) {
      toast.error(postCreateBootcampError, {
        duration: 3000,
      });
    }
  }, [postCreateBootcampError]);
  useEffect(() => {
    if (postCreateBootcampSuccess) {
      toast.success(postCreateBootcampSuccess, {
        duration: 3000,
      });
      history.replace("/dashboard/bootcamps");
    }
  }, [postCreateBootcampSuccess]);

  return (
    <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12">
      {/* header */}
      <header className="container mx-auto mobile:px-4 flex justify-between items-center">
        <div className="flex flex-col gap-7">
          <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
            Bootcamp
          </p>

          <p
            onClick={() => history.goBack()}
            className="flex items-center justify-start gap-3 cursor-pointer
                    "
          >
            <IoIosArrowBack className="text-[#8B54F7]" size={18} />
            <span className="font-normal text-base text-gray-500">Back</span>
          </p>
        </div>

        {/* profile dp */}
        <div className="mobile:hidden">
          <ProfileAvatar />
        </div>
      </header>

      {/* body section */}
      <section className="container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10">
        {/* Bootcamp details */}
        <div className="rounded-[10px] bg-white flex flex-col gap-16 py-12 px-6">
          {/* Heading information */}
          <div className="flex items-end justify-start">
            {/* first container */}
            <div className="flex flex-col gap-4 w-[50%] items-start">
              <p className="text-gray-600 font-semibold text-4xl">
                Create new Bootcamp
              </p>
            </div>
          </div>

          {/* general information */}
          <div className="flex flex-col gap-7">
            <p className="text-xl font-normal text-[#3F444D]">
              Fill out the following information to create a new bootcamp
            </p>

            {/* forms */}
            <div className="rounded-[10px] bg-[#F8F9FC] p-5 flex justify-between gap-20">
              {/* first form */}
              <div className="w-full">
                <form className="flex flex-col gap-5 w-full">
                  {/* bootcamp name input container */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Bootcamp name
                      </span>
                    </div>

                    {/* input field */}
                    <input
                      type="text"
                      value={bCampNum}
                      onChange={(e) => setBcampNum(e.target.value)}
                      className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                      placeholder="Enter Bootcamp Name"
                    />
                  </label>

                  {/* course name input container */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Course name
                      </span>
                    </div>

                    {/* input field */}
                    {
                      getCoursesLoading?
                      <Select
                      isDisabled={true}
                      className="custom-select-container"
                      placeholder="Fetching courses...Please wait"
                      options={courseData}
                      />:
                      courses.length === 0?
                      <Select
                      isDisabled={true}
                      className='custom-select-container'
                      placeholder="No data fetched, kindly Refresh"
                      options={trackData} />:
                      <Select
                      className="custom-select-container"
                      placeholder="Select course"
                      value={courseData.filter(
                        (data) => data.value === courseId
                      )}
                      onChange={handleChange}
                      options={courseData}
                    />
                    }
                  </label>

                  {/* duration */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Duration
                      </span>
                    </div>

                    {/* input field */}
                    <input
                      type="text"
                      {...formik.getFieldProps("duration")}
                      className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                      placeholder="Course duration"
                    />
                    {formik.touched.duration && formik.errors.duration && (
                      <FormError message={formik.errors.duration} />
                    )}
                  </label>

                  {/* pathway */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Pathway
                      </span>
                    </div>

                    {/* input field */}
                    <input
                      type="text"
                      {...formik.getFieldProps("pathway")}
                      className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                      placeholder="Pathway"
                    />
                    {formik.touched.pathway && formik.errors.pathway && (
                      <FormError message={formik.errors.pathway} />
                    )}
                  </label>

                  {/* description */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Description
                      </span>
                    </div>

                    {/* input field */}
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="border border-[#d0d5dd] text-base font-normal h-[134px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2"
                      placeholder="Description"
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <FormError message={formik.errors.description} />
                      )}
                  </label>

                  {/* Track */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Track
                      </span>
                    </div>

                    {/* input field */}
                    <Select
                      className="custom-select-container"
                      placeholder="Select track"
                      defaultValue={track}
                      value={trackData.filter((data) => data.value === track)}
                      onChange={(obj) => setTrack(obj.value)}
                      options={trackData}
                    />
                  </label>

                  {/* Started */}
                  <label className="flex flex-col gap-2">+
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Started
                      </span>
                    </div>

                    {/* input field */}
                    <Select
                      className="custom-select-container"
                      placeholder="Select"
                      defaultValue={hasStarted}
                      value={isStarted.filter(
                        (data) => data.value === hasStarted
                      )}
                      onChange={(obj) => setHasStarted(obj.value)}
                      options={isStarted}
                    />
                  </label>
                </form>
              </div>

              {/* second form */}
              <div className="w-full flex flex-col justify-between">
                <form className="flex flex-col gap-5 w-full">
                  {/* Slashed Price input container */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Slashed Price
                      </span>
                    </div>

                    {/* input field */}
                    <div className="flex items-center border border-[#d0d5dd] rounded-[8px] bg-white outline-[#d0d5dd]">
                      <span className="font-normal text-base text-[#667085] h-[44px] border-r border-[#D0D5DD] px-3 flex items-center justify-center">
                        NGN
                      </span>

                      <input
                        type="text"
                        {...formik.getFieldProps("new_price")}
                        className=" text-base font-normal outline-none w-full h-[44px] px-3 bg-transparent placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                        placeholder="Old price"
                      />
                    </div>
                    {formik.touched.new_price && formik.errors.new_price && (
                      <FormError message={formik.errors.new_price} />
                    )}
                  </label>

                  {/* Actual Price input container */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Actual price
                      </span>
                    </div>

                    {/* input field */}
                    <div className="flex items-center border border-[#d0d5dd] rounded-[8px] bg-white outline-[#d0d5dd]">
                      <span className="font-normal text-base text-[#667085] h-[44px] border-r border-[#D0D5DD] px-3 flex items-center justify-center">
                        NGN
                      </span>

                      <input
                        type="text"
                        {...formik.getFieldProps("old_price")}
                        className=" text-base font-normal outline-none w-full h-[44px] px-3 bg-transparent placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                        placeholder="Actual price"
                      />
                    </div>
                    {formik.touched.old_price && formik.errors.old_price && (
                      <FormError message={formik.errors.old_price} />
                    )}
                  </label>

                  {/* featured image */}
                  <div className="flex flex-col gap-3">
                    <p className="text-[#344054] text-xl font-semibold">
                      Featured Image
                    </p>

                    <div className="relative w-fit">
                      <img
                        src={imageUrl?imageUrl:featuredImage}
                        className="w-[296px] h-[184px] rounded-[10px] object-cover "
                        alt=""
                      />

                      <span
                        onClick={toggleUploadFileModal}
                        className="bg-white rounded-[50%] flex items-center justify-center w-[40px] h-[40px] absolute top-[10px] right-[10px] active:bg-gray-100 cursor-pointer"
                      >
                        <FiCamera color="#8B54F7" size={22} />
                      </span>
                    </div>
                  </div>
                </form>

                <form className="flex flex-col gap-5 w-full">
                  {/* Duration input container */}
                  <label className="flex flex-col gap-2">
                    {/* label text */}
                    <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                        Duration
                      </span>
                    </div>

                    {/* input field */}
                    <div className="flex items-center justify-between gap-8">
                      <input
                        type="date"
                        {...formik.getFieldProps("start_date")}
                        className="w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                        placeholder="Start date"
                      />

                      <span className="font-semibold text-base text-black">
                        to
                      </span>

                      <input
                        type="date"
                        {...formik.getFieldProps("end_date")}
                        className="w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                        placeholder="Start date"
                      />
                    </div>
                    {formik.touched.start_date && formik.errors.start_date && (
                      <FormError message={formik.errors.start_date} />
                    )}
                    {formik.touched.end_date && formik.errors.end_date && (
                      <FormError message={formik.errors.end_date} />
                    )}
                  </label>
                </form>
              </div>
            </div>

            <div className="w-[40%] flex flex-col items-stretch justify-center mx-auto">
              <button
                type={"submit"}
                className="h-[44px] mobile:h-[48px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base lg:text-xs text-white px-7 rounded-[8px] hover:opacity-80 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={formik.handleSubmit}
              >
                {postCreateBootcampLoading ? (
                  <div className="text-center">
                    <CircularProgress color={"inherit"} size="20px" />
                  </div>
                ) : (
                  "Create Bootcamp"
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Upload file modal */}
      <MyModal
        disableBtn={false}
        modalIsOpen={uploadFileModalState}
        toggleModal={toggleUploadFileModal}>

        <FileUploadPopup 
        closeModal={toggleUploadFileModal} 
        formik={formik}
        setImage={setImageUrl} />

      </MyModal>
    </section>
  );
};

export default BodySection;
