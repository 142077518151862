import { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useLocation } from 'react-router-dom'
import ProfileAvatar from '../../global/ProfileAvatar'
import GeneralInformation from './GeneralInformation'
import BtnSm from '../../../Global/BtnSm'
import { toast } from 'react-toastify'
import { getSingleSpaceRequest, updateSpaceRequest } from '../../../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import * as Yup from "yup"
import MyModal from '../../../../layouts/MyModal'
import FileUploadPopup from '../../global/FileUploadPopup'
import featuredImage from "../../../../assets/admindashboard/bootcamp/images/new-course-image.png";
import { FiCamera } from 'react-icons/fi'
import Features from './Features'
import BookedBy from './BookedBy'
import { FormError } from '../../../Global/Error'
import EmptyDataState from '../../../Global/EmptyDataState'
import LoadingCircles from '../../../Global/LoadingCircles'
import SubmitBtn from '../../../Global/SubmitBtn'
import FormikSelect from '../../../Global/FormikSelect'

const BodySection = () => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    // creating the path object variable
    const tempArrPath =  location.pathname.split("/");
    const spaceId = tempArrPath[tempArrPath.length-1]
    // track Dataset
    const availabilityData = [
        { value: true, label: 'No' },
        { value: false, label: 'Yes' },
    ];


    // STATES
    // features
    const [features, setFeatures] = useState([])
    // imgurl
    const [imgUrl, setImageUrl] = useState("")
    // redux state
    const { 
        singleSpace ,
        updateSpaceLoading,
        updateSpaceError,
        updateSpaceSuccess,
        getSingleSpaceLoading
    } = useSelector(state=>{
        const { singleSpace } = state.spacesReducer
        const { 
            updateSpaceLoading,
            getSingleSpaceLoading
        } = state.loadingIndicator
        const {
            success: { 
                updateSpace: updateSpaceSuccess
            },
            errors: { 
                updateSpace: updateSpaceError
            },
        } = state.ajaxStatuses;
        return {
            getSingleSpaceLoading,
            singleSpace,
            updateSpaceLoading,
            updateSpaceError,
            updateSpaceSuccess,
        }
    })

    // isAvailable
    const [isAvailable, setIsAvailable] = useState(null);

    // modal state for saved account info
    const [uploadFileModalState, setUploadFileModalState] = useState(false)


    // HANDLERS
    // toggle saved account info
    const toggleUploadFileModal = () => {
        setUploadFileModalState(!uploadFileModalState)
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleSpaceRequest(spaceId))
    }, [])
    useEffect(()=>{
        if(singleSpace){
            setImageUrl(singleSpace.imgurl)
            setFeatures(singleSpace.features)
        }
    }, [singleSpace])

    // listen for error or success messages
    useEffect(() => {
        if (updateSpaceError) {
            toast.error(updateSpaceError, {
                duration: 3000,
            });
        }
    }, [updateSpaceError]);
    
    useEffect(() => {
        if (updateSpaceSuccess) {
            toast.success(updateSpaceSuccess, {
                duration: 3000,
            });
            history.replace(`/dashboard/space-booking`)
        }
    }, [updateSpaceSuccess]); 

    return (
        <Formik
        enableReinitialize
        initialValues= {{
            features: singleSpace.features || [],
            imgurl: singleSpace.imgurl || "",
            is_Booked: singleSpace.is_Booked || false,
            name: singleSpace.name || "",
            new_price: singleSpace.new_price || null,
            old_price: singleSpace.old_price ||  null
        }}
        onSubmit= {(values) => {
            const finalObj = { ...values };
            finalObj.new_price = parseInt(values.new_price)
            finalObj.old_price = parseInt(values.old_price)
            finalObj.imgurl = imgUrl
            finalObj.features = features.map(({content})=>({content}))

            // dispatch Action
            dispatch(
                updateSpaceRequest({
                    spaceId,
                    spaceData: finalObj
                })
            );
        }}
        validationSchema= {Yup.object({
            name: Yup.string().min(4).required(),
            is_Booked: Yup.boolean().required(),
            new_price: Yup.number().required(),
            old_price: Yup.number().required(),
        })}
        >
            {
                ({
                    values,
                    dirty,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    field,
                    form
                })=>{
                    return (
                        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
                            
                            {/* header */}
                            <header  className='container mx-auto mobile:px-4 flex justify-between items-start'>
                                <div className='flex flex-col gap-7'>
                                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                                        Space Booking
                                    </p>

                                    <p 
                                    onClick={()=>history.goBack()}
                                    className='flex items-center justify-start gap-3 cursor-pointer
                                    '>
                                        <IoIosArrowBack 
                                        className="text-[#8B54F7]"
                                        size={18} />
                                        <span className='font-normal text-base text-gray-500'>
                                            Back
                                        </span>
                                    </p>
                                </div>

                                {/* profile dp */}
                                <div className='mobile:hidden'>
                                    <ProfileAvatar />
                                </div>
                            </header>

                            {/* body section */}
                            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>
                                
                                {/* container */}
                                <div className='rounded-[10px] bg-white flex flex-col gap-16 py-9 px-6 pc:min-h-[80vh]'>

                                    {
                                        getSingleSpaceLoading?
                                        <LoadingCircles />:
                                        singleSpace && Object.keys(singleSpace).length!==0?
                                        <>
                                        
                                            <div className='flex flex-col gap-1'>
                                                <p className='text-gray-700 font-semibold text-4xl'>
                                                    {singleSpace.name}
                                                </p>
                                            </div>

                                            <div className='p-5 rounded-[10px] bg-[#F8F9FC] grid grid-cols-2 justify-between gap-x-16'>
                    
                    
                                                {/* General informaion */}
                                                <div className='flex flex-col gap-16 w-full'>
                                                    
                                                    {/* form */}
                                                    <form
                                                    className='flex flex-col gap-5 w-full'
                                                    onSubmit={(e)=>e.preventDefault()}>

                                                        {/* form caption */}
                                                        <p className='font-semibold text-xl text-[#3F444D]'>
                                                            General Information
                                                        </p>

                                                        {/* space name input container */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    Space name
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <Field 
                                                            type="text" 
                                                            name="name"
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                                            placeholder='Space name' />
                                                            {touched.name && errors.name && (
                                                                <FormError message={errors.name} />
                                                            )}
                                                        </label>

                                                        {/* Old price input container */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    Old price
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <div className='flex items-center border border-[#d0d5dd] rounded-[8px] bg-white outline-[#d0d5dd]'>

                                                                <span className='font-normal text-base text-[#667085] h-[44px] border-r border-[#D0D5DD] px-3 flex items-center justify-center'>
                                                                    NGN/day
                                                                </span>

                                                                <Field 
                                                                type="text" 
                                                                name={"old_price"}
                                                                value={values.old_price}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                className=' text-base font-normal outline-none w-full h-[44px] px-3 bg-transparent placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                                                placeholder='Old price' />
                                                                {touched.old_price && errors.old_price && (
                                                                    <FormError message={errors.old_price} />
                                                                )}
                                                            </div>
                                                        </label>

                                                        {/* New Price input container */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    New price
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <div className='flex items-center border border-[#d0d5dd] rounded-[8px] bg-white outline-[#d0d5dd]'>

                                                                <span className='font-normal text-base text-[#667085] h-[44px] border-r border-[#D0D5DD] px-3 flex items-center justify-center'>
                                                                    NGN/day
                                                                </span>

                                                                <Field 
                                                                type="text" 
                                                                name={"new_price"}
                                                                value={values.new_price}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                className=' text-base font-normal outline-none w-full h-[44px] px-3 bg-transparent placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                                                placeholder='New price' />
                                                                {touched.new_price && errors.new_price && (
                                                                    <FormError message={errors.new_price} />
                                                                )}
                                                            </div>
                                                        </label>
                                                    </form>

                                                    {/* featured image and availability */}
                                                    <form
                                                    className='flex flex-col gap-6 w-full'
                                                    onSubmit={(e)=>e.preventDefault()}>
                                                        <div className='flex flex-col gap-3'>
                                                            <p className='text-[#344054] text-xl font-semibold'>
                                                                Featured Image
                                                            </p>

                                                            <div className='relative w-fit'>
                                                                <img 
                                                                src={imgUrl?imgUrl:featuredImage}className="w-[296px] h-[184px] rounded-[10px] object-cover "
                                                                alt="" />

                                                                <span 
                                                                onClick={toggleUploadFileModal}
                                                                className='bg-white rounded-[50%] flex items-center justify-center w-[40px] h-[40px] absolute top-[10px] right-[10px] active:bg-gray-100 cursor-pointer'>
                                                                    <FiCamera color="#8B54F7" size={22} />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {/* availability container */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    Available
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <Field 
                                                            name={'is_Booked'} 
                                                            component={FormikSelect} 
                                                            options={availabilityData} />
                                                            
                                                        </label>
                                                    </form>
                                                </div>

                                                {/* features and benefits*/}
                                                <div className='flex flex-col gap-12 w-full'>

                                                    <Features
                                                    features={features}
                                                    setFeatures={setFeatures} />

                                                    <BookedBy
                                                    days={singleSpace.is_Booked?singleSpace.number_of_days:0}

                                                    currentUserName={singleSpace.is_Booked?
                                                    `${singleSpace.user_id.firstname} ${singleSpace.user_id.lastname}`:"No user has booked"}

                                                    spaceId={spaceId}
                                                    
                                                    currentUserId={singleSpace.is_Booked?singleSpace.user_id._id:""} />

                                                </div>
                                            </div>

                                            <div className='w-[40%] mx-auto flex flex-col'>
                                                <SubmitBtn
                                                loading={updateSpaceLoading}
                                                disabled={!imgUrl || isSubmitting || features.length ===0}
                                                onSubmit={handleSubmit}
                                                title={"Submit"} />
                                            </div>
                                        </>:
                                        <EmptyDataState /> 
                                    }

                                </div>
                            </section>

                            {/* Upload file modal */}
                            <MyModal
                            disableBtn={false}
                            modalIsOpen={uploadFileModalState}
                            toggleModal={toggleUploadFileModal}>
                                
                                <FileUploadPopup
                                closeModal={toggleUploadFileModal}
                                setImage={setImageUrl} />
                            </MyModal>
                        </section>
                    )
                }
            }
        </Formik>
    )
}

export default BodySection