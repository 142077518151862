import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import MyModal from '../../../../layouts/MyModal'
import { deleteEventRequest, getSingleEventRequest } from '../../../../redux/action'
import BackBtn from '../../../Global/BackBtn'
import DeletePopup2 from '../../global/DeletePopup2'
import ProfileAvatar from '../../global/ProfileAvatar'
import AboutEvent from './AboutEvent'
import EventInfo from './EventInfo'
import Jumbotron from './Jumbotron'

const BodySection = () => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    // creating the path object variable
    const tempArrPath =  location.pathname.split("/");
    const eventId = tempArrPath[tempArrPath.length-1]


    // STATES
    // delete modal state
    const [deleteModalState, setDeleteModalState] = useState(false)
    // redux state
    const { 
        singleEvent ,
        deleteEventLoading,
        deleteEventSuccess,
        deleteEventError,
        singleEventLoading
    } = useSelector(state=>{
        const { singleEvent } = state.eventsReducer
        const { 
            deleteEventLoading ,
            singleEventLoading
        } = state.loadingIndicator
        const {
            success: { 
                deleteEvent: deleteEventSuccess
            },
            errors: { 
                deleteEvent: deleteEventError
            },
        } = state.ajaxStatuses;
        return {
            singleEventLoading,
            singleEvent,
            deleteEventLoading,
            deleteEventError,
            deleteEventSuccess,
        }
    })


    // HANDLERS
    const toggleDeleteModalState = () => {
        setDeleteModalState(!deleteModalState)
    }
    const handleDeleteEvent = () => {
        dispatch(deleteEventRequest(eventId))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleEventRequest(eventId))
    }, [])
    // listen for error or success messages
    useEffect(() => {
        if (deleteEventError) {
            toast.error(deleteEventError, {
                duration: 3000,
            });
        }
    }, [deleteEventError]);
    
    useEffect(() => {
        if (deleteEventSuccess) {
            setDeleteModalState(false)
            toast.success(deleteEventSuccess, {
                duration: 3000,
            });
            history.replace("/dashboard/events")
        }
    }, [deleteEventSuccess]);

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Events
                    </p>

                    <BackBtn />
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-16'>
                
                <Jumbotron
                event={singleEvent} />

                <div className='grid grid-cols-[2fr_1fr] gap-8'>
                    <AboutEvent
                    event={singleEvent} />

                    <EventInfo
                    event={singleEvent} />
                </div>

                {/* delete course */}
                <div className='w-[20vw] self-end'>
                    {singleEventLoading?
                        <Skeleton
                        variant='rectangular'
                        sx={{
                            width: "100%",
                            height: "5vh",
                            borderRadius: "10px",
                        }}
                        />:
                        <p 
                        onClick={toggleDeleteModalState}
                        className='text-[#E02D3C] text-base font-semibold w-full text-right cursor-pointer'>
                            {
                                deleteEventLoading?
                                "Deleting...":
                                "Delete event?"
                            }
                        </p> 
                    }
                </div>
            </section>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
               <DeletePopup2
               onClick={handleDeleteEvent}
               loadinState={deleteEventLoading}
               closeModal={toggleDeleteModalState} />
            </MyModal>
        </section>
    )
}

export default BodySection