import React, { useEffect, useState } from 'react'
import { HiUserCircle } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MyModal from '../../../../layouts/MyModal'
import MyModal2 from '../../../../layouts/MyModal2'
import { bookSpaceRequest, clearBookSpaceSuccess, clearUnbookSpaceSuccess, getSingleSpaceRequest, getUsersRequest, unbookSpaceRequest } from '../../../../redux/action'
import BtnSm from '../../../Global/BtnSm'
import CustomPopup from '../../global/CustomPopup'
import ChangeUser from '../ChangeUser'
import ChangeUserBtn from './ChangeUserBtn'
import DurationCounter from './DurationCounter'
import UnbookUserBtn from './UnbookUserBtn'

const BookedBy = ({
    currentUserName, 
    currentUserId, 
    days, 
    spaceId
}) => {


    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()


    // STATES
    const [currentUser, setCurrentUser] = useState({
        name: "",
        id: ""
    })
    const [duration, setDuration] = useState(1)
    // Modals
    const [modalState, setModalState] = useState(false)
    // unbook Modals state
    const [unBookModalState, setUnbookModalState] = useState(false)
    // users list
    const [userLIst, setUserList] = useState()
    // redux state
    const {
        users,
        getUsersLoading,
        bookSpaceLoading,
        bookSpaceError,
        bookSpaceSuccess,
        unbookSpaceSuccess,
        unbookSpaceError,
        unbookSpaceLoading
    } = useSelector((state)=>{
        const { users } = state.usersReducer
        const {
            getUsersLoading,
            bookSpaceLoading,
            unbookSpaceLoading
        } = state.loadingIndicator
        const {
            success: { 
                bookSpace: bookSpaceSuccess,
                unbookSpace: unbookSpaceSuccess
            },
            errors: { 
                bookSpace: bookSpaceError,
                unbookSpace: unbookSpaceError
            },
        } = state.ajaxStatuses;
        return { 
            users, 
            getUsersLoading,
            bookSpaceLoading,
            bookSpaceError,
            bookSpaceSuccess,
            unbookSpaceSuccess,
            unbookSpaceError,
            unbookSpaceLoading
        }
    })


    // HANDLERS
    // info modal state
    const toggleUserModal = () => {
        setModalState(!modalState)
    }
    // unbook modal state
    const toggleUnbookModal = () => {
        setUnbookModalState(!unBookModalState)
    }

    // book space handler
    const handleBookSpace = () => {
        const payload = {
            spaceId,
            userData: {
                user_id: currentUser.id,
                number_of_days: duration.toString()
            }
        }
        dispatch(bookSpaceRequest(payload))
    }

    // unbook space handler
    const handleUnbookSpace = () => {
        dispatch(unbookSpaceRequest(spaceId))
    }

    // onselect new user
    const onSelectNewUser = (new_id, new_name) => {
        setCurrentUser({
            id: new_id,
            name: new_name,
        })
        toggleUserModal()
    }


    // SIDE EFFECTS
    useEffect(()=>{
        if(users) {
            setUserList(users)
        }
    }, [users])
    // populate data for the current user
    useEffect(()=>{
        setCurrentUser({
            name: currentUserName,
            id: currentUserId
        })
        setDuration(days)
    }, [currentUserName, currentUserId, days])
    // dispatch request
    useEffect(()=>{
        dispatch(getUsersRequest())
    }, [])

    // checking for success and error messages: book space for users
    useEffect(() => {
        if (bookSpaceError) {
            toast.error(bookSpaceError, {
                duration: 3000,
            });
        }
    }, [bookSpaceError]);
    
    useEffect(() => {
        if (bookSpaceSuccess) {
            dispatch(clearBookSpaceSuccess())
            toast.success(bookSpaceSuccess, {
                duration: 3000,
            });
            dispatch(getSingleSpaceRequest(spaceId))
        }
    }, [bookSpaceSuccess]); 

    // checking for success and error messages: unbook space for users
    useEffect(() => {
        if (unbookSpaceError) {
            setUnbookModalState(false)
            toast.error(unbookSpaceError, {
                duration: 3000,
            });
        }
    }, [unbookSpaceError]);
    
    useEffect(() => {
        if (unbookSpaceSuccess) {
            dispatch(clearUnbookSpaceSuccess())
            toast.success(unbookSpaceSuccess, {
                duration: 3000,
            });
            dispatch(getSingleSpaceRequest(spaceId))
        }
    }, [unbookSpaceSuccess]); 


    return (
        <div className='flex items-start flex-col gap-4 w-full'>
            
            {/* caption */}
            <p className='font-semibold text-sm text-[#344054]'>
                Booked by
            </p>

            {/* change user */}
            {/*  */}
            <div className='flex justify-end items-center gap-8 w-full'>

                {/* user */}
                <div className='flex gap-2 items-center mr-auto'>
                    <HiUserCircle size={29} />

                    <span className='text-gray-600 font-normal text-sm'>
                        {currentUser.name.length <=15 ? currentUser.name: currentUser.name.substring(0,15).concat("...")}
                    </span>
                </div>

                {/* metrics */}
                {/* <div className='font-semibold text-sm text-gray-800'>
                    2 days
                </div> */}
                <DurationCounter
                value={duration}
                onChangeCounter={setDuration} />
            </div>

            {/* book and unbook user buttons */}
            <div className='flex items-center w-full gap-3'>

                {/* book user button */}
                <ChangeUserBtn
                disabled={getUsersLoading || userLIst?.length === 0}
                loading={getUsersLoading}
                usersLength={userLIst?.length}
                onClick={toggleUserModal} />

                {/* unbook user button */}
                <UnbookUserBtn
                disabled={!currentUserId}
                onClick={toggleUnbookModal} />
            </div>                
            
            {/* button */}
            <div className='flex flex-col items-stretch w-full'>
                <BtnSm 
                onClick={handleBookSpace}
                loading={bookSpaceLoading}
                disabled={currentUserId === currentUser.id || duration<1}
                title={"Book user"} />
            </div>

            {/* modal to change user */}
            <MyModal2
            modalIsOpen={modalState}
            toggleModal={toggleUserModal}
            disableBtn={true}>
                
                <ChangeUser
                users={userLIst}
                onSelectUser={onSelectNewUser}/>
            </MyModal2>

            {/* modal to unbook a user */}
            <MyModal
            modalIsOpen={unBookModalState}
            toggleModal={toggleUnbookModal}
            disableBtn={true}>
                
                <CustomPopup
                onClick={handleUnbookSpace}
                loadinState={unbookSpaceLoading}
                closeModal={toggleUnbookModal} />
            </MyModal>
        </div>
    )
}

export default BookedBy