import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import SpaceBooking from ".";
import EditSpace from "./EditSpace";
import CreateSpace from "./CreateSpace";

const SpaceBookingRouter = ({path}) => {
    
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={SpaceBooking} />

            {/* <Route 
            exact 
            path={`${path}/:id`}
            component={""} /> */}

            <Route 
            exact 
            path={`${path}/edit/:id`}
            component={EditSpace} />

            <Route 
            exact 
            path={`${path}/create/new-space`}
            component={CreateSpace} />
        </ Switch>
    )
}

export default SpaceBookingRouter