import React from 'react'
import GreenCheck from '../../global/GreenCheck'

const WhatYouLearn = ({expectedOutcome}) => {
    return (
        <div className='bg-white flex flex-col gap-4 p-5 rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)]'>

            <p className='text-gray-700 font-semibold text-xl'>
                What you'll learn
            </p>

            <div className='grid grid-cols-2 gap-y-5 gap-x-4'>
                {
                    expectedOutcome.map((item)=>(
                        <div 
                        key={item._id}
                        className='flex gap-3 items-start m-0'>
                            <GreenCheck />
                            <span className='text-gray-500 text-base font-normal'>
                                {item.content}
                            </span>
                        </div>
                    ))
                }
            </div>           
        </div>
    )
}

export default WhatYouLearn