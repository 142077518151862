import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { bookSpaceFailure, bookSpaceLoading, bookSpaceSuccess, clearBookSpaceSuccess, clearCreateSpaceSuccess, clearUnbookSpaceSuccess, clearUpdateSpaceSuccess, createSpaceFailure, createSpaceLoading, createSpaceSuccess, getSingleSpaceFailure, getSingleSpaceLoading, getSingleSpaceSuccess, getSpacesFailure, getSpacesLoading, getSpacesSuccess, unbookSpaceFailure, unbookSpaceLoading, unbookSpaceSuccess, updateSpaceFailure, updateSpaceLoading, updateSpaceSuccess } from "../action";
import { BOOK_SPACE_REQUEST, CREATE_SPACE_REQUEST, GET_SINGLE_SPACE_REQUEST, GET_SPACES_REQUEST, UNBOOK_SPACE_REQUEST, UPDATE_SPACE_REQUEST } from "../types";

const ajaxDBCalls = {
    getSpaces: async () => {
        const reponse = await axios({
            method: "GET",
            url: "space/admin"
        })
        return reponse
    },
    getSingleSpace: async ({ payload }) => {
        const response = await axios({
          method: "GET",
          url: `space/admin/${payload}`,
        });
    
        return response;
    },
    updateSpace: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `space/${payload.spaceId}`,
            data: payload.spaceData
        })

        return response
    },
    createSpace: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `space/create`,
            data: payload
        })

        return response
    },
    bookSpace: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `space/user/${payload.spaceId}`,
            data: payload.userData
        })

        return response
    },
    unbookSpace: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `space/unbook/${payload}`
        })

        return response
    },
}


// WORKERS
// get clubs
function* getSpaces() {
    try {
        yield put(getSpacesLoading(true));

        const response = yield call(ajaxDBCalls.getSpaces)

        yield put(getSpacesSuccess(response.data))
        yield put(getSpacesLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getSpacesFailure(errorMessage))
        yield put(getSpacesLoading(false))
        yield delay();
        yield put(getSpacesFailure(""))
    }
}

// get single club
function* getSingleSpace(payload) {
    try {
        yield put(getSingleSpaceLoading(true));
    
        const response = yield call(ajaxDBCalls.getSingleSpace, payload);
    
        yield put(getSingleSpaceSuccess(response.data));
        yield put(getSingleSpaceLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getSingleSpaceFailure(errorMessage));
        yield put(getSingleSpaceLoading(false));
        yield delay();
        yield put(getSingleSpaceFailure(""));
    }
}

// update club
function* updateSpace(payload) {
    try {
        yield put(updateSpaceLoading(true));
        const response = yield call(ajaxDBCalls.updateSpace, payload);
        yield put(updateSpaceSuccess(response.data));
        yield put(updateSpaceLoading(false));
        yield delay();
        yield put(clearUpdateSpaceSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(updateSpaceFailure(errorMessage));
        yield put(updateSpaceLoading(false));
        yield delay();
        yield put(updateSpaceFailure(""));
    }
}

// create club
function* createSpace(payload) {
    try {
        yield put(createSpaceLoading(true));
        const response = yield call(ajaxDBCalls.createSpace, payload);
        yield put(createSpaceSuccess(response.data));
        yield put(createSpaceLoading(false));
        yield delay();
        yield put(clearCreateSpaceSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(createSpaceFailure(errorMessage));
        yield put(createSpaceLoading(false));
        yield delay();
        yield put(createSpaceFailure(""));
    }
}

// create club
function* bookSpace(payload) {
    try {
        yield put(bookSpaceLoading(true));
        const response = yield call(ajaxDBCalls.bookSpace, payload);
        yield put(bookSpaceSuccess(response.data));
        yield put(bookSpaceLoading(false));
        yield delay();
        yield put(clearBookSpaceSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(bookSpaceFailure(errorMessage));
        yield put(bookSpaceLoading(false));
        yield delay();
        yield put(bookSpaceFailure(""));
    }
}

// create club
function* unbookSpace(payload) {
    try {
        yield put(unbookSpaceLoading(true));
        const response = yield call(ajaxDBCalls.unbookSpace, payload);
        yield put(unbookSpaceSuccess(response.data));
        yield put(unbookSpaceLoading(false));
        yield delay();
        yield put(clearUnbookSpaceSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(unbookSpaceFailure(errorMessage));
        yield put(unbookSpaceLoading(false));
        yield delay();
        yield put(unbookSpaceFailure(""));
    }
}


// WATCHERS
function* getSpacesWatcher() {
    yield takeLatest(GET_SPACES_REQUEST, getSpaces)
}
function* getSingleSpaceWatcher() {
    yield takeLatest(GET_SINGLE_SPACE_REQUEST, getSingleSpace)
}
function* updateSpaceWatcher() {
    yield takeLatest(UPDATE_SPACE_REQUEST, updateSpace)
}
function* createSpaceWatcher() {
    yield takeLatest(CREATE_SPACE_REQUEST, createSpace)
}
function* bookSpaceWatcher() {
    yield takeLatest(BOOK_SPACE_REQUEST, bookSpace)
}
function* unbookSpaceWatcher() {
    yield takeLatest(UNBOOK_SPACE_REQUEST, unbookSpace)
}

// spaces saga
export default function* spacesSaga() {
    yield spawn(getSpacesWatcher);
    yield spawn(getSingleSpaceWatcher);
    yield spawn(updateSpaceWatcher);
    yield spawn(createSpaceWatcher);
    yield spawn(bookSpaceWatcher);
    yield spawn(unbookSpaceWatcher);
}