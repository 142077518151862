import {
  CLEAR_CREATE_BOOTCAMP_SUCCESS,
  CLEAR_CREATE_CLUB_SUBSCRIPTION_SUCCESS,
  CLEAR_DELETE_BOOTCAMP_SUCCESS,
  CLEAR_UPDATE_CLUB_SUBSCRIPTION_SUCCESS,
  CLEAR_DELETE_COURSE_SUCCESS,
  CLEAR_DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS,
  CLEAR_POST_CREATE_COURSE_SUCCESS,
  CLEAR_PUT_EDIT_BOOTCAMP_SUCCESS,
  CLEAR_PUT_SINGLE_COURSE_SUCCESS,
  CLEAR_PUT_STUDENT_BOOTCAMP_SUCCESS,
  CLEAR_PUT_USER_EVENT_SUCCESS,
  CLEAR_UPDATE_USER_SUCCESS,
  DELETE_BOOTCAMP_FAIL,
  DELETE_BOOTCAMP_SUCCESS,
  UPDATE_CLUB_SUBSCRIPTION_FAIL,
  UPDATE_CLUB_SUBSCRIPTION_SUCCESS,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_SUCCESS,
  DELETE_STUDENT_FROM_BOOTCAMP_FAIL,
  DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS,
  GET_BOOTCAMPS_ADMIN_FAIL,
  GET_BOOTCAMPS_ADMIN_SUCCESS,
  GET_CLUBS_ADMIN_FAIL,
  GET_CLUBS_ADMIN_SUCCESS,
  GET_CLUB_SUBSCRIPTION_FAIL,
  GET_CLUB_SUBSCRIPTION_SUCCESS,
  GET_COURSES_FAIL,
  GET_COURSES_SUCCESS,
  GET_EVENTS_ADMIN_FAIL,
  GET_EVENTS_ADMIN_SUCCESS,
  GET_SINGLE_BOOTCAMPS_FAIL,
  GET_SINGLE_BOOTCAMPS_SUCCESS,
  GET_SINGLE_COURSES_FAIL,
  GET_SINGLE_COURSES_SUCCESS,
  GET_SINGLE_USER_FAIL,
  GET_SINGLE_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  POST_CREATE_BOOTCAMP_FAIL,
  POST_CREATE_BOOTCAMP_SUCCESS,
  POST_CREATE_CLUB_SUBSCRIPTION_FAIL,
  POST_CREATE_CLUB_SUBSCRIPTION_SUCCESS,
  POST_CREATE_COURSE_FAIL,
  POST_CREATE_COURSE_SUCCESS,
  PUT_EDIT_BOOTCAMP_FAIL,
  PUT_EDIT_BOOTCAMP_SUCCESS,
  PUT_EDIT_SINGLE_COURSE_FAIL,
  PUT_EDIT_SINGLE_COURSE_SUCCESS,
  PUT_STUDENT_TO_BOOTCAMP_FAIL,
  PUT_STUDENT_TO_BOOTCAMP_SUCCESS,
  PUT_USER_TO_EVENT_FAIL,
  PUT_USER_TO_EVENT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_DELETE_USER_SUCCESS,
  GET_SINGLE_EVENT_SUCCESS,
  GET_SINGLE_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  CLEAR_UPDATE_EVENT_SUCCESS,
  POST_CREATE_EVENT_SUCCESS,
  POST_CREATE_EVENT_FAIL,
  CLEAR_CREATE_EVENT_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  CLEAR_DELETE_EVENT_SUCCESS,
  DELETE_SPEAKER_FROM_EVENT_SUCCESS,
  DELETE_SPEAKER_FROM_EVENT_FAIL,
  CLEAR_DELETE_SPEAKER_SUCCESS,
  PUT_SPEAKER_TO_EVENT_SUCCESS,
  PUT_SPEAKER_TO_EVENT_FAIL,
  CLEAR_SPEAKER_TO_EVENT_SUCCESS,
  GET_SINGLE_CLUB_SUCCESS,
  GET_SINGLE_CLUB_FAIL,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_FAIL,
  CLEAR_UPDATE_CLUB_SUCCESS,
  POST_CREATE_CLUB_SUCCESS,
  POST_CREATE_CLUB_FAIL,
  CLEAR_CREATE_CLUB_SUCCESS,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_FAIL,
  CLEAR_DELETE_CLUB_SUCCESS,
  GET_SPACES_SUCCESS,
  GET_SPACES_FAIL,
  GET_SINGLE_SPACE_SUCCESS,
  GET_SINGLE_SPACE_FAIL,
  UPDATE_SPACE_SUCCESS,
  UPDATE_SPACE_FAIL,
  CLEAR_UPDATE_SPACE_SUCCESS,
  CREATE_SPACE_SUCCESS,
  CREATE_SPACE_FAIL,
  CLEAR_CREATE_SPACE_SUCCESS,
  BOOK_SPACE_SUCCESS,
  BOOK_SPACE_FAIL,
  CLEAR_BOOK_SPACE_SUCCESS,
  UNBOOK_SPACE_SUCCESS,
  UNBOOK_SPACE_FAIL,
  CLEAR_UNBOOK_SPACE_SUCCESS,
  GET_DIGITAL_MARKETING_STUDENTS_SUCCESS,
  GET_DIGITAL_MARKETING_STUDENTS_FAIL,
  GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS,
  GET_CRITIQUE_EVENT_PARTICIPANT_FAIL,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_FAIL,
  GET_DATA_ANALYSIS_FORM_SUCCESS,
  GET_DATA_ANALYSIS_FORM_FAIL,
} from "../types";

const initialState = {
  errors: {},
  success: {},
};

const ajaxStatuses = (state = initialState, { type, payload }) => {
  switch (type) {
    // Registration
    case REGISTER_FAIL:
      state.errors.register = payload.errors;
      return { ...state };
    case REGISTER_SUCCESS:
      state.success.register = payload.message;
      return { ...state };

    // Get Users
    case GET_USERS_SUCCESS:
      state.success.getUsers = payload.message;
      return { ...state };

    case GET_USERS_FAIL:
      state.errors.getUsers = payload.errors;
      return { ...state };

    // Get Single User
    case GET_SINGLE_USER_SUCCESS:
      state.success.getSingleUser = payload.message;
      return { ...state };

    case GET_SINGLE_USER_FAIL:
      state.errors.getSingleUser = payload.message;
      return { ...state };

    // Update User
    case UPDATE_USER_SUCCESS:
      state.success.updateUser = payload.message;
      return { ...state };

    case UPDATE_USER_FAIL:
      state.errors.updateUser = payload.errors;
      return { ...state };

    case CLEAR_UPDATE_USER_SUCCESS:
      state.success.updateUser = "";
      return { ...state };

    // Get Bootcamps
    case GET_BOOTCAMPS_ADMIN_SUCCESS:
      state.success.getBootcamps = payload.message;
      return { ...state };

    case GET_BOOTCAMPS_ADMIN_FAIL:
      state.errors.getBootcampsAdmin = payload.errors;
      return { ...state };

    // Get Single Bootcamp
    case GET_SINGLE_BOOTCAMPS_SUCCESS:
      state.success.getSingleBootcamp = payload.message;
      return { ...state };

    case GET_SINGLE_BOOTCAMPS_FAIL:
      state.errors.getSingleBootcamp = payload.errors;
      return { ...state };

    // Update specific bootcamp
    case PUT_EDIT_BOOTCAMP_SUCCESS:
      state.success.putEditBootcamp = payload.message;
      return { ...state };

    case PUT_EDIT_BOOTCAMP_FAIL:
      state.errors.putEditBootcamp = payload.errors;
      return { ...state };

    case CLEAR_PUT_EDIT_BOOTCAMP_SUCCESS:
      state.success.putEditBootcamp = "";
      return { ...state };

    // create bootcamp
    case POST_CREATE_BOOTCAMP_SUCCESS:
      state.success.postCreateBootcamp = payload.message;
      return { ...state };

    case POST_CREATE_BOOTCAMP_FAIL:
      state.errors.postCreateBootcamp = payload.error;
      return { ...state };

    case CLEAR_CREATE_BOOTCAMP_SUCCESS:
      state.success.postCreateBootcamp = "";
      return { ...state };

    // delete bootcamp
    case DELETE_BOOTCAMP_SUCCESS:
      state.success.deleteBootcamp = payload.message;
      return { ...state };

    case DELETE_BOOTCAMP_FAIL:
      state.errors.deleteBootcamp = payload.error;
      return { ...state };

    case CLEAR_DELETE_BOOTCAMP_SUCCESS:
      state.success.deleteBootcamp = "";
      return { ...state };

    // delete student from bootcamp
    case DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS:
      state.success.deleteStudentFromBootcamp = payload.message;
      return { ...state };

    case DELETE_STUDENT_FROM_BOOTCAMP_FAIL:
      state.errors.deleteStudentFromBootcamp = payload.error;
      return { ...state };

    case CLEAR_DELETE_STUDENT_FROM_BOOTCAMP_SUCCESS:
      state.success.deleteStudentFromBootcamp = "";
      return { ...state };

    // Get Courses
    case GET_COURSES_SUCCESS:
      state.success.getCourses = payload.message;
      return { ...state };

    case GET_COURSES_FAIL:
      state.errors.getCourses = payload.errors;
      return { ...state };

    // Get Single courses
    case GET_SINGLE_COURSES_SUCCESS:
      state.success.getSingleCourse = payload.message;
      return { ...state };

    case GET_SINGLE_COURSES_FAIL:
      state.errors.getSingleCourse = payload.errors;
      return { ...state };

    // Update specific courses
    case PUT_EDIT_SINGLE_COURSE_SUCCESS:
      state.success.putEditSingleCourse = payload.message;
      return { ...state };

    case PUT_EDIT_SINGLE_COURSE_FAIL:
      state.errors.putEditSingleCourse = payload.errors;
      return { ...state };

    case CLEAR_PUT_SINGLE_COURSE_SUCCESS:
      state.success.putEditSingleCourse = "";
      return { ...state };

    // create course
    case POST_CREATE_COURSE_SUCCESS:
      state.success.postCreateCourse = payload.message;
      return { ...state };

    case POST_CREATE_COURSE_FAIL:
      state.errors.postCreateCourse = payload.errors;
      return { ...state };

    case CLEAR_POST_CREATE_COURSE_SUCCESS:
      state.success.postCreateCourse = "";
      return { ...state };

    // delete bootcamp
    case DELETE_COURSE_SUCCESS:
      state.success.deleteCourse = payload.message;
      return { ...state };

    case DELETE_COURSE_FAIL:
      state.errors.deleteCourse = payload.error;
      return { ...state };

    case CLEAR_DELETE_COURSE_SUCCESS:
      state.success.deleteCourse = "";
      return { ...state };

    // Get Events
    case GET_EVENTS_ADMIN_SUCCESS:
      state.success.getEvents = payload.message;
      return { ...state };

    case GET_EVENTS_ADMIN_FAIL:
      state.errors.getEvents = payload.errors;
      return { ...state };

    // Get Clubs
    case GET_CLUBS_ADMIN_SUCCESS:
      state.success.getClubs = payload.message;
      return { ...state };

    case GET_CLUBS_ADMIN_FAIL:
      state.errors.getClubs = payload.errors;
      return { ...state };

    // add student to bootcamp
    case PUT_STUDENT_TO_BOOTCAMP_SUCCESS:
      state.success.putStudentToBootcamp = payload.message;
      return { ...state };

    case PUT_STUDENT_TO_BOOTCAMP_FAIL:
      state.errors.putStudentToBootcamp = payload.errors;
      return { ...state };

    case CLEAR_PUT_STUDENT_BOOTCAMP_SUCCESS:
      state.success.putStudentToBootcamp = "";
      return { ...state };

    // add user to event
    case PUT_USER_TO_EVENT_SUCCESS:
      state.success.putUserToEvent = payload.message;
      return { ...state };

    case PUT_USER_TO_EVENT_FAIL:
      state.errors.putUserToEvent = payload.errors;
      return { ...state };

    case CLEAR_PUT_USER_EVENT_SUCCESS:
      state.success.putUserToEvent = "";
      return { ...state };

    // create club subscription
    case POST_CREATE_CLUB_SUBSCRIPTION_SUCCESS:
      state.success.postCreateClubSubscription = payload.message;
      return { ...state };

    case POST_CREATE_CLUB_SUBSCRIPTION_FAIL:
      state.errors.postCreateClubSubscription = payload.errors;
      return { ...state };

    case CLEAR_CREATE_CLUB_SUBSCRIPTION_SUCCESS:
      state.success.postCreateClubSubscription = "";
      return { ...state };

    // delete club subscription
    case UPDATE_CLUB_SUBSCRIPTION_SUCCESS:
      state.success.updateClubSubscription = payload.message;
      return { ...state };

    case UPDATE_CLUB_SUBSCRIPTION_FAIL:
      state.errors.updateClubSubscription = payload.errors;
      return { ...state };

    case CLEAR_UPDATE_CLUB_SUBSCRIPTION_SUCCESS:
      state.success.updateClubSubscription = "";
      return { ...state };

    // delete user
    case DELETE_USER_SUCCESS:
      state.success.deleteUser = payload.message;
      return { ...state };

    case DELETE_USER_FAIL:
      state.errors.deleteUser = payload.errors;
      return { ...state };

    case CLEAR_DELETE_USER_SUCCESS:
      state.success.deleteUser = "";
      return { ...state };

    // Get club subscriptions
    case GET_CLUB_SUBSCRIPTION_SUCCESS:
      state.success.getClubSubscriptions = payload.message;
      return { ...state };

    case GET_CLUB_SUBSCRIPTION_FAIL:
      state.errors.getClubSubscriptions = payload.errors;
      return { ...state };

    // GET single events
    case GET_SINGLE_EVENT_SUCCESS:
      state.success.getSingleEvents = payload.message;
      return { ...state };
    case GET_SINGLE_EVENT_FAIL:
      state.error.getSingleEvents = payload.message;
      return { ...state };

    // Update single event
    case UPDATE_EVENT_SUCCESS:
      state.success.updateEvent = payload.message;
      return { ...state };

    case UPDATE_EVENT_FAIL:
      state.errors.updateEvent = payload.errors;
      return { ...state };

    case CLEAR_UPDATE_EVENT_SUCCESS:
      state.success.updateEvent = "";
      return { ...state };

    // create event
    case POST_CREATE_EVENT_SUCCESS:
      state.success.createEvent = payload.message;
      return { ...state };

    case POST_CREATE_EVENT_FAIL:
      state.errors.createEvent = payload.errors;
      return { ...state };

    case CLEAR_CREATE_EVENT_SUCCESS:
      state.success.createEvent = "";
      return { ...state };

    // delete event
    case DELETE_EVENT_SUCCESS:
      state.success.deleteEvent = payload.message;
      return { ...state };

    case DELETE_EVENT_FAIL:
      state.errors.deleteEvent = payload.errors;
      return { ...state };

    case CLEAR_DELETE_EVENT_SUCCESS:
      state.success.deleteEvent = "";
      return { ...state };

    // delete speaker from event
    case DELETE_SPEAKER_FROM_EVENT_SUCCESS:
      state.success.deleteSpeakerFromEvent = payload.message;
      return { ...state };

    case DELETE_SPEAKER_FROM_EVENT_FAIL:
      state.errors.deleteSpeakerFromEvent = payload.errors;
      return { ...state };

    case CLEAR_DELETE_SPEAKER_SUCCESS:
      state.success.deleteSpeakerFromEvent = "";
      return { ...state };

    // delete event
    case PUT_SPEAKER_TO_EVENT_SUCCESS:
      state.success.addSpeakerToEvent = payload.message;
      return { ...state };

    case PUT_SPEAKER_TO_EVENT_FAIL:
      state.errors.addSpeakerToEvent = payload.errors;
      return { ...state };

    case CLEAR_SPEAKER_TO_EVENT_SUCCESS:
      state.success.addSpeakerToEvent = "";
      return { ...state };

    // GET single club
    case GET_SINGLE_CLUB_SUCCESS:
      state.success.getSingleClub = payload.message;
      return { ...state };
    case GET_SINGLE_CLUB_FAIL:
      state.error.getSingleClub = payload.message;
      return { ...state };

    // Update single club
    case UPDATE_CLUB_SUCCESS:
      state.success.updateClub = payload.message;
      return { ...state };

    case UPDATE_CLUB_FAIL:
      state.errors.updateClub = payload.errors;
      return { ...state };

    case CLEAR_UPDATE_CLUB_SUCCESS:
      state.success.updateClub = "";
      return { ...state };

    // create club
    case POST_CREATE_CLUB_SUCCESS:
      state.success.createClub = payload.message;
      return { ...state };

    case POST_CREATE_CLUB_FAIL:
      state.errors.createClub = payload.errors;
      return { ...state };

    case CLEAR_CREATE_CLUB_SUCCESS:
      state.success.createClub = "";
      return { ...state };

    // delete event
    case DELETE_CLUB_SUCCESS:
      state.success.deleteClub = payload.message;
      return { ...state };

    case DELETE_CLUB_FAIL:
      state.errors.deleteClub = payload.errors;
      return { ...state };

    case CLEAR_DELETE_CLUB_SUCCESS:
      state.success.deleteClub = "";
      return { ...state };

    // GET spaces
    case GET_SPACES_SUCCESS:
      state.success.getSpaces = payload.message;
      return { ...state };
    case GET_SPACES_FAIL:
      state.error.getSpaces = payload.message;
      return { ...state };

    // GET single space
    case GET_SINGLE_SPACE_SUCCESS:
      state.success.getSingleSpace = payload.message;
      return { ...state };
    case GET_SINGLE_SPACE_FAIL:
      state.error.getSingleSpace = payload.message;
      return { ...state };

    // update space
    case UPDATE_SPACE_SUCCESS:
      state.success.updateSpace = payload.message;
      return { ...state };

    case UPDATE_SPACE_FAIL:
      state.errors.updateSpace = payload.errors;
      return { ...state };

    case CLEAR_UPDATE_SPACE_SUCCESS:
      state.success.updateSpace = "";
      return { ...state };

    // create space
    case CREATE_SPACE_SUCCESS:
      state.success.createSpace = payload.message;
      return { ...state };

    case CREATE_SPACE_FAIL:
      state.errors.createSpace = payload.errors;
      return { ...state };

    case CLEAR_CREATE_SPACE_SUCCESS:
      state.success.createSpace = "";
      return { ...state };

    // book space
    case BOOK_SPACE_SUCCESS:
      state.success.bookSpace = payload.message;
      return { ...state };

    case BOOK_SPACE_FAIL:
      state.errors.bookSpace = payload.errors;
      return { ...state };

    case CLEAR_BOOK_SPACE_SUCCESS:
      state.success.bookSpace = "";
      return { ...state };

    // unbook space
    case UNBOOK_SPACE_SUCCESS:
      state.success.unbookSpace = payload.message;
      return { ...state };

    case UNBOOK_SPACE_FAIL:
      state.errors.unbookSpace = payload.errors;
      return { ...state };

    case CLEAR_UNBOOK_SPACE_SUCCESS:
      state.success.unbookSpace = "";
      return { ...state };

    // get digital marketing students
    case GET_DIGITAL_MARKETING_STUDENTS_SUCCESS:
      state.success.digitalMarketingStudents = payload.message;
      return { ...state };

    case GET_DIGITAL_MARKETING_STUDENTS_FAIL:
      state.errors.digitalMarketingStudents = payload.errors;
      return { ...state };

    // get critique event participants
    case GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS:
      state.success.critiqueEventParticipants = payload.message;
      return { ...state };

    case GET_CRITIQUE_EVENT_PARTICIPANT_FAIL:
      state.errors.critiqueEventParticipants = payload.errors;
      return { ...state };

    // get design bootcamp participants
    case GET_DESIGN_BOOTCAMP_PARTICIPANTS_SUCCESS:
      state.success.designBootcampParticipants = payload.message;
      return { ...state };

    case GET_DESIGN_BOOTCAMP_PARTICIPANTS_FAIL:
      state.errors.designBootcampParticipants = payload.errors;
      return { ...state };

    // get data analysis form
    case GET_DATA_ANALYSIS_FORM_SUCCESS:
      state.success.getDataAnalysisForm = payload.message;
      return { ...state };

    case GET_DATA_ANALYSIS_FORM_FAIL:
      state.errors.getDataAnalysisForm = payload.errors;
      return { ...state };

    default:
      return state;
  }
};

export default ajaxStatuses;
