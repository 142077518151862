import { GET_SINGLE_SPACE_SUCCESS, GET_SPACES_SUCCESS } from "../types"

const initialState = {
    spaces: [],
    singleSpace: {}
}

const spacesReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_SPACES_SUCCESS:
    return { 
        ...state, 
        spaces: payload.spaces
    }

  case GET_SINGLE_SPACE_SUCCESS:
    return { 
        ...state, 
        singleSpace: payload.space
    }

  default:
    return state
  }
}

export default spacesReducer