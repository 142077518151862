import React from 'react'
import SideNav from '../../../layouts/AdminDashboard/SideNav'
import BodySection from '../../../components/AdminDashboard/Bootcamps/EditBootcamp/BodySection'

const EditBootcamp = () => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]'>

            {/* Side nav */}
            <SideNav />

            {/* Body section */}
            <BodySection />
        </div>
    )
}

export default EditBootcamp