import { CLEAR_CREATE_CLUB_SUBSCRIPTION_SUCCESS, CLEAR_UPDATE_CLUB_SUBSCRIPTION_SUCCESS, UPDATE_CLUB_SUBSCRIPTION_FAIL, UPDATE_CLUB_SUBSCRIPTION_LOADING, UPDATE_CLUB_SUBSCRIPTION_REQUEST, UPDATE_CLUB_SUBSCRIPTION_SUCCESS, GET_CLUB_SUBSCRIPTION_FAIL, GET_CLUB_SUBSCRIPTION_LOADING, GET_CLUB_SUBSCRIPTION_REQUEST, GET_CLUB_SUBSCRIPTION_SUCCESS, POST_CREATE_CLUB_SUBSCRIPTION_FAIL, POST_CREATE_CLUB_SUBSCRIPTION_LOADING, POST_CREATE_CLUB_SUBSCRIPTION_REQUEST, POST_CREATE_CLUB_SUBSCRIPTION_SUCCESS } from "../types"

// POST CREATE CLUB SUBSCRIPTION
export const postCreateClubSubscriptionRequest = (prop) => {
    return {
        type: POST_CREATE_CLUB_SUBSCRIPTION_REQUEST,
        payload: prop
    }
}

export const postCreateClubSubscriptionLoading = (loading) => {
    return {
        type: POST_CREATE_CLUB_SUBSCRIPTION_LOADING,
        payload: loading
    }
}

export const postCreateClubSubscriptionSuccess = (prop) => {
    return {
        type: POST_CREATE_CLUB_SUBSCRIPTION_SUCCESS,
        payload: prop
    }
}


export const postCreateClubSubscriptionFailure = (errors) => {
    return {
        type: POST_CREATE_CLUB_SUBSCRIPTION_FAIL,
        payload: { errors }
    }
}

export const clearCreateClubSubscriptionSuccess = () => {
    return {
        type: CLEAR_CREATE_CLUB_SUBSCRIPTION_SUCCESS
    }
}

// GET COURSES FOR ADMIN
export const getClubSubscriptionsRequest = () => {
    return {
        type: GET_CLUB_SUBSCRIPTION_REQUEST
    }
}

export const getClubSubscriptionsLoading = (loading) => {
    return {
        type: GET_CLUB_SUBSCRIPTION_LOADING,
        payload: loading,
    }
}

export const getClubSubscriptionsSuccess = (prop) => {
    return {
        type: GET_CLUB_SUBSCRIPTION_SUCCESS,
        payload: prop,
    }
}

export const getClubSubscriptionsFailure = (errors) => {
    return {
        type: GET_CLUB_SUBSCRIPTION_FAIL,
        payload: { errors }
    }
}

// DELETE CLUB SUBSCRIPTION
export const updateClubSubscriptionRequest = (prop) => {
    return {
        type: UPDATE_CLUB_SUBSCRIPTION_REQUEST,
        payload: prop
    }
}

export const updateClubSubscriptionLoading = (loading) => {
    return {
        type: UPDATE_CLUB_SUBSCRIPTION_LOADING,
        payload: loading
    }
}

export const updateClubSubscriptionSuccess = (prop) => {
    return {
        type: UPDATE_CLUB_SUBSCRIPTION_SUCCESS,
        payload: prop
    }
}


export const updateClubSubscriptionFailure = (errors) => {
    return {
        type: UPDATE_CLUB_SUBSCRIPTION_FAIL,
        payload: { errors }
    }
}

export const clearUpdateClubSubscriptionSuccess = () => {
    return {
        type: CLEAR_UPDATE_CLUB_SUBSCRIPTION_SUCCESS
    }
}