import { useState, useEffect } from 'react'
import BtnSm from '../../../Global/BtnSm'
import { v4 as uuid } from 'uuid';

const AddNewInfoView = ({title, placeholder, onSave, isTextArea, closeModal}) => {

    const unique_id = uuid();

    const [singleOutcome, setSingleOutcome] = useState({
        content: "",
        _id: null
    })

    return (
        <div className='bg-white rounded-[8px] flex flex-col gap-4 w-full m-4'>
            <p className='text-[#344054] text-base font-semibold'>
                {title}
            </p>

            {
                isTextArea?
                <textarea
                value={singleOutcome.content}
                onChange={(e)=>{
                    setSingleOutcome({
                        ...singleOutcome,
                        content: e.target.value
                    })
                }}
                className={`border border-[#d0d5dd] text-base font-normal h-[68px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2`}
                placeholder={placeholder} />:
                <input 
                type="text" 
                value={singleOutcome.content}
                onChange={(e)=>{
                    setSingleOutcome({
                        ...singleOutcome,
                        content: e.target.value
                    })
                }}
                className={`border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]`}
                placeholder={placeholder} />
            }

            <div className='flex flex-col items-stretch'>
                <BtnSm 
                onClick={()=>{
                    onSave({
                        ...singleOutcome,
                        _id: unique_id
                    })
                    closeModal()
                    setSingleOutcome("")
                }}
                title={"Save"} />
            </div>          
        </div>
    )
}

export default AddNewInfoView