import { useEffect } from 'react'
import ProfileAvatar from '../global/ProfileAvatar'
import { useHistory } from 'react-router-dom'
import "../global/styles/custom-select-styling.css"
import OpticsCard from '../Bootcamps/OpticsCard'
import AvailbleCourses from './AvailbleCourses'
import { useDispatch, useSelector } from 'react-redux'
import { getCoursesRequest } from '../../../redux/action'

const BootcampBody = () => {

    // history object
    const history = useHistory()

    // dispatch object
    const dispatch = useDispatch()

    const { courses } = useSelector(state=>{
        const { courses } = state.coursesReducer
        return {
            courses
        }
    })

    useEffect(()=>{
        dispatch(getCoursesRequest())
    }, [])

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Courses
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>
                
                <OpticsCard
                numberOfStudents={1000}
                availableBootcamps={4}
                availableCourses={3} />

                <AvailbleCourses
                courses={courses} />
            </section>
        </section>
    )
}

export default BootcampBody