import { Skeleton } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import EmptyDataState from '../../../Global/EmptyDataState'

const AboutEvent = ({event}) => {

    // STATES
    const { singleEventLoading } = useSelector(state=>{
        const { singleEventLoading } = state.loadingIndicator
        return {
            singleEventLoading
        }
    })

    return (
        <>

            {
                singleEventLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                    width: "100%",
                    height: "30vh",
                    borderRadius: "10px",
                }}
                />:
                event && Object.keys(event).length!==0?
                <div className='bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] py-6 px-5 flex flex-col gap-4 h-fit'>

                    <p className='text-[#3F444D] font-semibold text-xl'>
                        About Event
                    </p>

                    <p className='text-gray-500 font-normal text-sm'>
                        {event?.description || "you're not using the right attributes of this object"}
                    </p>
                    
                </div>:
                <EmptyDataState />
            }
        </>
    )
}

export default AboutEvent