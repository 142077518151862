import React from 'react'
import { FiCheckCircle } from 'react-icons/fi'

const GrayCheck = () => {
    return (
        <span className='w-[24px] bg-[#F8F9FC] flex justify-center items-center rounded-[50%] h-[24px]'>
            <FiCheckCircle size={13} />
        </span>
    )
}

export default GrayCheck