import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { clearCreateClubSuccess, clearDeleteClubSuccess, clearUpdateClubSuccess, deleteClubFailure, deleteClubLoading, deleteClubSuccess, getClubsAdminFailure, getClubsAdminLoading, getClubsAdminSuccess, getSingleClubFailure, getSingleClubLoading, getSingleClubSuccess, postCreateClubFailure, postCreateClubLoading, postCreateClubSuccess, updateClubFailure, updateClubLoading, updateClubSuccess } from "../action";
import { DELETE_CLUB_REQUEST, GET_CLUBS_ADMIN_REQUEST, GET_SINGLE_CLUB_REQUEST, POST_CREATE_CLUB_REQUEST, UPDATE_CLUB_REQUEST } from "../types";

const ajaxDBCalls = {
    getClubs: async () => {
        const reponse = await axios({
            method: "GET",
            url: "club/"
        })
        return reponse
    },

    getSingleClub: async ({ payload }) => {
        const response = await axios({
          method: "GET",
          url: `club/${payload}`,
        });
    
        return response;
    },
    
    updateClub: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `club/${payload.clubId}`,
            data: payload.clubData
        })

        return response
    },

    postCreateClub: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `club/create`,
            data: payload
        })

        return response
    },

    deleteClub: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: `club/${payload}`
        })

        return response
    },
}

// WORKERS
// get clubs
function* getClubs() {
    try {
        yield put(getClubsAdminLoading(true));

        const response = yield call(ajaxDBCalls.getClubs)

        yield put(getClubsAdminSuccess(response.data))
        yield put(getClubsAdminLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getClubsAdminFailure(errorMessage))
        yield put(getClubsAdminLoading(false))
        yield delay();
        yield put(getClubsAdminFailure(""))
    }
}

// get single club
function* getSingleClub(payload) {
    try {
        yield put(getSingleClubLoading(true));
    
        const response = yield call(ajaxDBCalls.getSingleClub, payload);
    
        yield put(getSingleClubSuccess(response.data));
        yield put(getSingleClubLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getSingleClubFailure(errorMessage));
        yield put(getSingleClubLoading(false));
        yield delay();
        yield put(getSingleClubFailure(""));
    }
}

// update club
function* updateClub(payload) {
    try {
        yield put(updateClubLoading(true));
        const response = yield call(ajaxDBCalls.updateClub, payload);
        yield put(updateClubSuccess(response.data));
        yield put(updateClubLoading(false));
        yield delay();
        yield put(clearUpdateClubSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(updateClubFailure(errorMessage));
        yield put(updateClubLoading(false));
        yield delay();
        yield put(updateClubFailure(""));
    }
}

// create club
function* postCreateClub(payload) {
    try {
        yield put(postCreateClubLoading(true));
        const response = yield call(ajaxDBCalls.postCreateClub, payload);
        yield put(postCreateClubSuccess(response.data));
        yield put(postCreateClubLoading(false));
        yield delay();
        yield put(clearCreateClubSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(postCreateClubFailure(errorMessage));
        yield put(postCreateClubLoading(false));
        yield delay();
        yield put(postCreateClubFailure(""));
    }
}

// delete club
function* deleteClub(payload) {
    try {
        yield put(deleteClubLoading(true));
        const response = yield call(ajaxDBCalls.deleteClub, payload);
        yield put(deleteClubSuccess(response.data));
        yield put(deleteClubLoading(false));
        yield delay();
        yield put(clearDeleteClubSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteClubFailure(errorMessage));
        yield put(deleteClubLoading(false));
        yield delay();
        yield put(deleteClubFailure(""));
    }
}

// WATCHERS
function* getClubsWatcher() {
    yield takeLatest(GET_CLUBS_ADMIN_REQUEST, getClubs)
}
function* getSingleClubWatcher() {
    yield takeLatest(GET_SINGLE_CLUB_REQUEST, getSingleClub)
}
function* updateClubWatcher() {
    yield takeLatest(UPDATE_CLUB_REQUEST, updateClub)
}
function* postCreateClubWatcher() {
    yield takeLatest(POST_CREATE_CLUB_REQUEST, postCreateClub)
}
function* deleteClubWatcher() {
    yield takeLatest(DELETE_CLUB_REQUEST, deleteClub)
}

// club saga
export default function* clubsSaga() {
    yield spawn(getClubsWatcher);
    yield spawn(getSingleClubWatcher);
    yield spawn(updateClubWatcher);
    yield spawn(postCreateClubWatcher);
    yield spawn(deleteClubWatcher);
}