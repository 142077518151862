import React from 'react'

const OpticsCard = ({numberOfStudents, availableBootcamps, availableCourses}) => {
    return (
        <div className='bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] p-6 grid grid-cols-3 gap-x-9'>

            {/* number of students */}
            <div className='flex flex-col border-r border-[#E1E6EF]'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Total number of students
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {numberOfStudents.toLocaleString('en-US')}
                </p>
            </div>

            {/* availble bootcamps */}
            <div className='flex flex-col border-r border-[#E1E6EF]'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Available bootcamps
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {availableBootcamps.toLocaleString('en-US')}
                </p>
            </div>

            {/* total number of students */}
            <div className='flex flex-col'>
                <p className='font-normal text-base text-[#1D2433A6]'>
                    Available courses
                </p>
                <p className='font-semibold text-xl text-[#3F444D]'>
                    {availableCourses.toLocaleString('en-US')}
                </p>
            </div>
        </div>
    )
}

export default OpticsCard