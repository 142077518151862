import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import {
  getBootcampStudentsFailure,
  getBootcampStudentsLoading,
  getBootcampStudentsSuccess,
  getDigitalMarketingStudentsFailure,
  getDigitalMarketingStudentsLoading,
  getDigitalMarketingStudentsSuccess,
} from "../action";
import {
  GET_BOOTCAMP_STUDENTS_REQUEST,
  GET_DIGITAL_MARKETING_STUDENTS_REQUEST,
} from "../types";

const ajaxDBCalls = {
  getBootcampStudents: async () => {
    const reponse = await axios({
      method: "GET",
      url: "form",
    });
    return reponse;
  },
  getDigitalMarketingStudents: async () => {
    const reponse = await axios({
      method: "GET",
      url: "digitalmarketing",
    });
    return reponse;
  },
};

// WORKERS
// get bootcamp students
function* getBootcampStudentsWorker() {
  try {
    yield put(getBootcampStudentsLoading(true));

    const response = yield call(ajaxDBCalls.getBootcampStudents);

    yield put(getBootcampStudentsSuccess(response.data));
    yield put(getBootcampStudentsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getBootcampStudentsFailure(errorMessage));
    yield put(getBootcampStudentsLoading(false));
    yield delay();
    yield put(getBootcampStudentsFailure(""));
  }
}

function* getDigitalMarketingStudentsWorker() {
  try {
    yield put(getDigitalMarketingStudentsLoading(true));

    const response = yield call(ajaxDBCalls.getDigitalMarketingStudents);

    yield put(getDigitalMarketingStudentsSuccess(response.data));
    yield put(getDigitalMarketingStudentsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getDigitalMarketingStudentsFailure(errorMessage));
    yield put(getDigitalMarketingStudentsLoading(false));
    yield delay();
    yield put(getDigitalMarketingStudentsFailure(""));
  }
}

// WATCHERS
function* getBootcampStudentsWatcher() {
  yield takeLatest(GET_BOOTCAMP_STUDENTS_REQUEST, getBootcampStudentsWorker);
}
function* getDigitalMarketingStudentsWatcher() {
  yield takeLatest(
    GET_DIGITAL_MARKETING_STUDENTS_REQUEST,
    getDigitalMarketingStudentsWorker
  );
}

// getBootcampStudents saga
export default function* bootcampStudentsSaga() {
  yield spawn(getBootcampStudentsWatcher);
  yield spawn(getDigitalMarketingStudentsWatcher);
}
