import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import {
  GET_DATA_ANALYSIS_FORM_REQUEST,
  GET_DESIGN_BOOTCAMP_PARTICIPANTS_REQUEST,
} from "../types";
import {
  getDataAnalysisFormFailure,
  getDataAnalysisFormLoading,
  getDataAnalysisFormSuccess,
  getDesignBootcampParticipantsFailure,
  getDesignBootcampParticipantsLoading,
  getDesignBootcampParticipantsSuccess,
} from "../action";

const ajaxDBCalls = {
  getDesignBootcampParticipants: async () => {
    const response = await axios({
      method: "GET",
      url: "design",
    });
    return response;
  },
  getDataAnalysisForm: async () => {
    const response = await axios({
      method: "GET",
      url: "genericbootcamp",
    });
    return response;
  },
};

// WORKERS
// get design bootcamp participants
function* getDesignBootcampParticipantsWorker() {
  try {
    yield put(getDesignBootcampParticipantsLoading(true));

    const response = yield call(ajaxDBCalls.getDesignBootcampParticipants);

    yield put(getDesignBootcampParticipantsSuccess(response.data));
    yield put(getDesignBootcampParticipantsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getDesignBootcampParticipantsFailure(errorMessage));
    yield put(getDesignBootcampParticipantsLoading(false));
    yield delay();
    yield put(getDesignBootcampParticipantsFailure(""));
  }
}

// WORKERS
// get design bootcamp participants
function* getDataAnalysisFormWorker() {
  try {
    yield put(getDataAnalysisFormLoading(true));

    const response = yield call(ajaxDBCalls.getDataAnalysisForm);

    yield put(getDataAnalysisFormSuccess(response.data));
    yield put(getDataAnalysisFormLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getDataAnalysisFormFailure(errorMessage));
    yield put(getDataAnalysisFormLoading(false));
    yield delay();
    yield put(getDataAnalysisFormFailure(""));
  }
}

//   WATCHERS
function* getDesignBootcampParticipantsWatcher() {
  yield takeLatest(
    GET_DESIGN_BOOTCAMP_PARTICIPANTS_REQUEST,
    getDesignBootcampParticipantsWorker
  );
}

function* getDataAnalysisFormWatcher() {
  yield takeLatest(GET_DATA_ANALYSIS_FORM_REQUEST, getDataAnalysisFormWorker);
}

export default function* getDesignBootcampParticipantsSaga() {
  yield spawn(getDesignBootcampParticipantsWatcher);
  yield spawn(getDataAnalysisFormWatcher);
}
