import { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import ProfileAvatar from '../../global/ProfileAvatar'
import { useHistory, useLocation } from 'react-router-dom'
import EditCourseJumbotron from './EditCourseJumbotron'
import WhatYouLearn from './WhatYouLearn'
import CourseBenefits from './CourseBenefits'
import CourseOutlineAccordion from './CourseOutlineAccordion'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCourseRequest, getSingleCoursesRequest } from '../../../../redux/action'
import LoadingCircles from '../../../Global/LoadingCircles'
import EmptyDataState from '../../../Global/EmptyDataState'
import MyModal from '../../../../layouts/MyModal'
import DeletePopup2 from '../../global/DeletePopup2'
import { toast } from 'react-toastify'

const BodySection = () => {

    // OBJECT INITIALIZATION
    const subTitles = ["front-end", "back-end"]
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    const courseId = location.pathname.split("/").pop();


    // STATE
    // single course state
    const { 
        singleCourse, 
        getSingleCourseLoading ,
        deleteCourseLoading,
        deleteCourseSuccess,
        deleteCourseError
    } = useSelector(state=>{
        const { 
            getSingleCourseLoading, 
            deleteCourseLoading ,
        } = state.loadingIndicator
        const { singleCourse } = state.coursesReducer
        const {
            success: { 
                deleteCourse: deleteCourseSuccess
            },
            errors: { 
                deleteCourse: deleteCourseError
            },
        } = state.ajaxStatuses;
        return {
            getSingleCourseLoading,
            singleCourse,
            deleteCourseLoading,
            deleteCourseSuccess,
            deleteCourseError
        }
    })
    // delete modal state
    const [deleteModalState, setDeleteModalState] = useState(false)

    
    // HANDLERS
    const toggleDeleteModalState = () => {
        setDeleteModalState(!deleteModalState)
    }
    const handleDeleteCourse = () => {
        dispatch(deleteCourseRequest(courseId))
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleCoursesRequest(courseId))
    }, [])

    // listen for error or success messages
    useEffect(() => {
        if (deleteCourseError) {
            toast.error(deleteCourseError, {
                duration: 3000,
            });
        }
    }, [deleteCourseError]);
    
    useEffect(() => {
        if (deleteCourseSuccess) {
            setDeleteModalState(false)
            toast.success(deleteCourseSuccess, {
                duration: 3000,
            });
            history.replace("/dashboard/course")
        }
    }, [deleteCourseSuccess]);

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-start'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Courses
                    </p>

                    <p 
                    onClick={()=>history.goBack()}
                    className='flex items-center justify-start gap-3 cursor-pointer
                    '>
                        <IoIosArrowBack 
                        className="text-[#8B54F7]"
                        size={18} />
                        <span className='font-normal text-base text-gray-500'>
                            Back
                        </span>
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>

                {/* Bootcamp detais */}
                <div className='rounded-[10px] bg-white flex flex-col gap-16 py-9 px-6 pc:min-h-[65vh]'>
                
                    {
                        getSingleCourseLoading?
                        <LoadingCircles />:
                        Object.keys(singleCourse).length === 0?
                        <EmptyDataState />:
                        <>
                            <EditCourseJumbotron
                            title={singleCourse.name.split("(")[0] || singleCourse.name}
                            subTitle={
                                !(singleCourse.description.includes(" "))?
                                singleCourse.description:
                                subTitles.includes(singleCourse.description.split(" ")[0].toLowerCase())? `(${singleCourse.description.split(" ")[0]})`:singleCourse.description.toLowerCase().includes("mobile")?"":`(${singleCourse.name.split("(")[1].replace(")", "")})`}
                            description={singleCourse.description}
                            courseId={singleCourse._id} />

                            <div className='grid grid-cols-[55%_40%] gap-8 justify-between'>
                                <WhatYouLearn
                                expectedOutcome={singleCourse.expected_outcome} />

                                <CourseBenefits />
                            </div>

                            <CourseOutlineAccordion
                            topics={singleCourse.topics} />

                            {/* delete course */}
                            <p 
                            onClick={toggleDeleteModalState}
                            className='text-[#E02D3C] text-base font-semibold w-full text-right cursor-pointer'>
                                {
                                    deleteCourseLoading?
                                    "Deleting...":
                                    "Delete course?"
                                }
                            </p> 
                        </>
                    }  
                </div>             
            </section>

            {/* delete course modal */}
            <MyModal
            modalIsOpen={deleteModalState}
            toggleModal={toggleDeleteModalState}
            disableBtn={false}>
               <DeletePopup2
               onClick={handleDeleteCourse}
               loadinState={deleteCourseLoading}
               closeModal={toggleDeleteModalState} />
            </MyModal>
        </section>
    )
}

export default BodySection