// import { useState, useEffect } from "react";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { FormError } from "../Global/FormError";
// import { loginRequest } from "../../redux/action/auth";
import GoogleSignInBtn from "../Global/GoogleSignInBtn";
import BtnSm from "../Global/BtnSm";
import shape1 from "./../../assets/login/svg/caption-shape1.svg";
import shape2 from "./../../assets/login/svg/caption-shape2.svg";
import { Link } from "react-router-dom";
import Checkbox from "../Global/Checkbox";
import { useHistory } from "react-router-dom";

const LoginForm = () => {
  // history object
  const history = useHistory();

  // dispatch object
  // const dispatch = useDispatch();

  // // states
  // const {
  //     getUserLoading,
  //     getUserError,
  //     loginSuccess,
  //     loginError,
  //     loginLoading,
  //     user,
  //     singleUser
  //   } = useSelector((state) => {
  //     const {
  //       success: { login: loginSuccess },
  //       errors: { getUser: getUserError, login: loginError },
  //     } = state.ajaxStatuses;

  //     const { loginLoading, getUserLoading } = state.loadingIndicator;

  //     const { user, singleUser } = state.userData;

  //     return {
  //       getUserLoading,
  //       getUserError,
  //       loginSuccess,
  //       loginError,
  //       loginLoading,
  //       user,
  //       singleUser
  //     };
  //   });

  //   useEffect(() => {
  //     if (loginError) {
  //       toast.error(loginError, {
  //         duration: 3000,
  //       });
  //     }
  //   }, [loginError]);

  //   useEffect(() => {
  //     if (loginSuccess) {
  //       toast.success(loginSuccess, {
  //         duration: 3000,
  //       });
  //       history.push("/dashboard/home")
  //     }
  //   }, [loginSuccess]);

  //   const formik = useFormik({
  //     initialValues: {
  //       email: "",
  //       password: "",
  //     },
  //     validationSchema: yup.object({
  //       email: yup
  //         .string()
  //         .email("Email is not valid")
  //         .required("Email is required"),
  //       password: yup.string().required("Password is required"),
  //     }),

  //     onSubmit: (prop) => {
  //         let formData = prop;
  //         dispatch(loginRequest({ formData }));
  //     },
  //   });

  return (
    <div className="[box-shadow:0px_2px_8px_#e0e0e0] relative z-[5] flex flex-col my-24 justify-center items-center bg-white w-[45%] mobile:w-full mobile:shadow-none h-auto py-24 mobile:py-12 rounded-[8px] gap-6">
      {/* shapes and patterns */}
      <img
        src={shape1}
        className="mobile:hidden absolute top-[-5%] right-[-15%]"
        alt=""
      />
      <img
        src={shape2}
        className="mobile:hidden absolute bottom-[25%] left-[-25%]"
        alt=""
      />

      {/* form */}
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex flex-col justify-center items-center w-full gap-5"
      >
        {/* form caption */}
        <div className=" text-center flex flex-col mobile:gap-3 gap-6 mb-2 mobile:mb-5">
          <p className="font-semibold mobile:text-2xl text-4xl text-[#1D2433]">
            Admin Login
          </p>
          <p className="text-base font-normal text-[#667085]">
            Welcome back! Please enter your details.
          </p>
        </div>

        {/* FORM CONTROLS */}
        {/* email address */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">Email</span>

          <input
            type="email"
            // {...formik.getFieldProps("email")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your email"
          />

          {/* {formik.errors.email && formik.touched.email && (
                        <FormError message={formik.errors.email} />
                    )} */}
        </label>

        {/* password */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">Password</span>

          <input
            type="password"
            // {...formik.getFieldProps("password")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your password"
          />

          {/* {formik.errors.password && formik.touched.password && (
                        <FormError message={formik.errors.password} />
                    )} */}
        </label>

        {/* remember login & forgot password */}
        <label className="flex mobile:w-full w-[360px] gap-2 items-center justify-between">
          <div className="flex items-center justify-start gap-2">
            <Checkbox />
            <span className="font-normal text-sm text-[#344054] mt-1 cursor-pointer">
              Remember for 30 days
            </span>
          </div>

          <Link to="/forgot-password">
            <span className="font-semibold text-sm text-[#6941C6]">
              Forgot password
            </span>
          </Link>
        </label>

        {/* send message button */}
        <div className="flex flex-col items-stretch mobile:w-full w-[360px] gap-5">
          {/* login */}
          <BtnSm
            // onClick={formik.handleSubmit}
            // loading={loginLoading}
            onClick={() => history.push("/dashboard/home")}
            title={"Log in"}
          />

          {/* google signin button */}
          {/* <GoogleSignInBtn title={"Sign in with Google"} /> */}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
