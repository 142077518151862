import {
    GET_SINGLE_USER_SUCCESS,
    GET_USERS_SUCCESS
} from "../types"

const initialState = () => ({
    users: [],
    singleUser: {}
})

const usersReducer = (state = initialState(), {type, payload}) => {

    switch (type) {

        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: payload.users
            }
        
        case GET_SINGLE_USER_SUCCESS:
            return {
                ...state,
                singleUser: payload.user
            }

        default:
            return state;
    }
}

export default usersReducer;