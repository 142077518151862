import React from 'react'

const EmptyDataState = ({title = "Nothing here"}) => {
    return (
        <div className="flex w-full items-center justify-center text-xl font-semibold text-gray-800 m-auto">
            {title}
        </div>
    )
}

export default EmptyDataState