import Settings from ".";
import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import CreateAdmin from "./CreateAdmin";

const SettingsRouter = ({path}) => {
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={Settings} />

            <Route 
            exact 
            path={`${path}/create-admin`}
            component={CreateAdmin} />
        </ Switch>
    )
}

export default SettingsRouter