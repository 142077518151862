import ProfileAvatar from '../../global/ProfileAvatar'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleClubRequest, updateClubRequest } from '../../../../redux/action'
import { Formik, Field } from 'formik'
import * as Yup from "yup"
import { FormError } from '../../../Global/Error'
import EmptyDataState from '../../../Global/EmptyDataState'
import LoadingCircles from '../../../Global/LoadingCircles'
import SubmitBtn from '../../../Global/SubmitBtn'
import ClubCheckBox from '../../../Global/ClubCheckBox.js/ClubCheckBox'
import BackBtn from '../../../Global/BackBtn'
import { FormControlLabel } from '@material-ui/core'

const BodySection = () => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    // creating the path object variable
    const tempArrPath =  location.pathname.split("/");
    const clubId = tempArrPath[tempArrPath.length-1]


    // STATES
    // redux state
    const { 
        singleClub ,
        updateClubLoading,
        updateClubError,
        updateClubSuccess,
        getSingleClubLoading
    } = useSelector(state=>{
        const { singleClub } = state.clubsReducer
        const { 
            updateClubLoading,
            getSingleClubLoading
        } = state.loadingIndicator
        const {
            success: { 
                updateClub: updateClubSuccess
            },
            errors: { 
                updateClub: updateClubError
            },
        } = state.ajaxStatuses;
        return {
            getSingleClubLoading,
            singleClub,
            updateClubLoading,
            updateClubError,
            updateClubSuccess,
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleClubRequest(clubId))
    }, [])

    // listen for error or success messages
    useEffect(() => {
        if (updateClubError) {
            toast.error(updateClubError, {
                duration: 3000,
            });
        }
    }, [updateClubError]);
    
    useEffect(() => {
        if (updateClubSuccess) {
            toast.success(updateClubSuccess, {
                duration: 3000,
            });
            history.replace(`/dashboard/club-subscription/${clubId}`)
        }
    }, [updateClubSuccess]);

    return (
        <Formik
        enableReinitialize
        initialValues= {{
            name: singleClub?.name || "",
            amount: singleClub?.amount || "",
            duration: singleClub?.duration || "",
            business_consultancy: singleClub?.business_consultancy || false,
            discounted_services: singleClub?.discounted_services || false,
            foreign_tech_consultancy: singleClub?.foreign_tech_consultancy || false,
            investment_database: singleClub?.investment_database || false,
            investment_pitch: singleClub?.investment_pitch || false,
            life_insurance: singleClub?.life_insurance || false,
            member_swag: singleClub?.member_swag || false,
            networking_event: singleClub?.networking_event || false,
            news_letter: singleClub?.news_letter || false,
            reserved_office_space: singleClub?.reserved_office_space || false,
        }}
        onSubmit= {(values) => {
            const finalObj = { ...values };
            finalObj.amount = parseInt(values.amount)
            finalObj.duration = parseInt(values.duration)

            // dispatch Action
            dispatch(
                updateClubRequest({
                    clubId,
                    clubData: finalObj
                })
            );
        }}
        validationSchema= {Yup.object({
            name: Yup.string().min(4).required(),
            duration: Yup.number().required(),
            amount: Yup.number().required(),
        })}
        >
            {
                ({
                    values,
                    dirty,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    field,
                })=>{
                    return(
                        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

                            {/* header */}
                            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                                <div className='flex flex-col gap-7'>
                                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                                        Club Subscriptions
                                    </p>

                                    <BackBtn />
                                </div>

                                {/* profile dp */}
                                <div className='mobile:hidden'>
                                    <ProfileAvatar />
                                </div>
                            </header>

                            {/* body section */}
                            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>

                                {/* container */}
                                <div className='rounded-[10px] bg-white flex flex-col gap-12 py-10 px-5'>

                                    {
                                        getSingleClubLoading?
                                        <LoadingCircles />:
                                        singleClub && Object.keys(singleClub).length!==0?
                                        <>

                                            <p className='text-gray-800 font-semibold text-4xl'>
                                                {singleClub.name} Package
                                            </p>

                                            {/* first form container */}
                                            <div className='flex flex-col gap-8'>

                                                <p className='text-[#3F444D] font-semibold text-xl'>
                                                    General Information
                                                </p>

                                                <div className='w-full flex justify-between gap-16 bg-[#F8F9FC] p-5 rounded-[10px]'>

                                                    {/* first form */}
                                                    <form className='flex flex-col gap-5 w-full'>

                                                        {/* Package name */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    Package name
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <Field
                                                            type="text" 
                                                            name="name"
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                                            placeholder='Enter package name here' />
                                                            {touched.name && errors.name && (
                                                                <FormError message={errors.name} />
                                                            )}
                                                        </label>

                                                        {/* Duration input container */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    Duration
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <Field 
                                                            type="text" 
                                                            name='duration'
                                                            value={values.duration}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                                            placeholder='Enter the duration' />
                                                            {touched.duration && errors.duration && (
                                                                <FormError message={errors.duration} />
                                                            )}
                                                        </label>
                                                    </form>
                                                

                                                    {/* second form */}
                                                    <form className='flex flex-col gap-5 w-full'>

                                                        {/* Price input container */}
                                                        <label className='flex flex-col gap-2'>

                                                            {/* label text */}
                                                            <div className='flex items-center justify-start'>
                                                                <span className='font-semibold text-sm text-[#344054]'>
                                                                    Price
                                                                </span>
                                                            </div>

                                                            {/* input field */}
                                                            <div className='flex items-center border border-[#d0d5dd] rounded-[8px] bg-white outline-[#d0d5dd]'>

                                                                <span className='font-normal text-base text-[#667085] h-[44px] border-r border-[#D0D5DD] px-3 flex items-center justify-center'>
                                                                    NGN
                                                                </span>

                                                                <Field 
                                                                name="amount"
                                                                value={values.amount}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="text" 
                                                                className=' text-base font-normal outline-none w-full h-[44px] px-3 bg-transparent placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                                                placeholder='Price' />
                                                                {touched.amount && errors.amount && (
                                                                    <FormError message={errors.amount} />
                                                                )}
                                                            </div>
                                                        </label>
                                                    </form>                        
                                                </div>
                                            </div>

                                            {/* second form container */}
                                            <div className='flex flex-col gap-8'>

                                                <div className='flex flex-col gap-'>
                                                    <p className='text-[#3F444D] font-semibold text-xl'>
                                                        Benefits
                                                    </p>

                                                    <p className='text-gray-600 text-base font-normal'>
                                                        Kindly select the benefits from the list below
                                                    </p>
                                                </div>

                                                {/* form container */}
                                                <div className='w-full flex flex-col items-start gap-2 bg-[#F8F9FC] py-6 px-5 rounded-[10px]'>

                                                    <div className='grid grid-cols-2 gap-3 gap-y-4 w-full'>
                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"networking_event"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Networking Event
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"member_swag"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Member Swag
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"news_letter"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Newsletter
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"reserved_office_space"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Reserved Office Space
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"discounted_services"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Discounted Services
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"investment_pitch"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Investment Pitch
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"investment_database"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Investment Database
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"business_consultancy"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Business Consultancy
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"foreign_tech_consultancy"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Foreign Tech Consultancy
                                                            </span>
                                                        </div>

                                                        <div 
                                                        className='flex items-center gap-1'>
                                                            <ClubCheckBox
                                                            name={"life_insurance"} />
                                                            <span className="font-normal text-gray-600 text-base">
                                                                Life Insurance
                                                            </span>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-[40%] flex flex-col items-stretch justify-center mx-auto mt-5">
                                                <SubmitBtn
                                                loading={updateClubLoading}
                                                disabled={!dirty || isSubmitting}
                                                onSubmit={handleSubmit}
                                                title={"Update"} />
                                            </div>
                                        </>:
                                        <EmptyDataState /> 
                                    }
                                </div>
                            </section>
                        </section>
                    )
                }
            }
        </Formik>
    )
}

export default BodySection