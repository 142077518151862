import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import Event from ".";
import SingleEventInfo from "./SingleEventInfo";
import EditEvent from "./EditEvent";
import AddNewEvent from "./AddNewEvent";

const EventRouter = ({path}) => {
    
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={Event} />

            <Route 
            exact 
            path={`${path}/:id`}
            component={SingleEventInfo} />

            <Route 
            exact 
            path={`${path}/edit/:id`}
            component={EditEvent} />

            <Route 
            exact 
            path={`${path}/create/new-event`}
            component={AddNewEvent} />
        </ Switch>
    )
}

export default EventRouter