import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import { PrivateRoute } from "../components/PrivateRoute";
import Login from "./Login";

const Pages = () => {
  return (
    <>
      {/* switching component based on their routes */}
      <Switch>
        {/* authentication pages */}
        <Route exact path="/" component={Login} />

        {/* Admin Dashboard */}
        {/* <PrivateRoute path="/dashboard" component={AdminDashboard} /> */}
        <Route path={"/dashboard"} component={AdminDashboard} />
      </Switch>
    </>
  );
};

export default Pages;
