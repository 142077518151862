import { GET_EVENTS_ADMIN_SUCCESS, GET_SINGLE_EVENT_SUCCESS } from "../types"

const initialState = {
    events: [],
    singleEvent: {}
}

const eventsReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_EVENTS_ADMIN_SUCCESS:
    return { 
        ...state,
        events: payload.event
    }

  case GET_SINGLE_EVENT_SUCCESS:
    return {
        ...state,
        singleEvent: payload.event
    }

  default:
    return state
  }
}

export default eventsReducer
