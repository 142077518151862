import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClubSubscriptionsRequest } from '../../../redux/action'
import ProfileAvatar from '../global/ProfileAvatar'
import MembersTable from './MembersTable'
import OpticsCard from './OpticsCard'

const BodySection = () => {

    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()


    // STATES
    const { clubSubscriptions } = useSelector(state=>{
        const { clubSubscriptions } = state.clubSubscriptionReducer
        return {
            clubSubscriptions
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getClubSubscriptionsRequest())
    }, [])


    return (
      <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

          {/* header */}
          <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
              <div>
                  <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                      Club Memberships
                  </p>
              </div>

              {/* profile dp */}
              <div className='mobile:hidden'>
                  <ProfileAvatar />
              </div>
          </header>

          {/* body section */}
          <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>

              <OpticsCard
              totalMembers={1000}
              availableSubscription={4}
              currentlySubscribed={450} />

              <MembersTable
              clubSubscriptions={clubSubscriptions} />
          </section>
      </section>
    )
}

export default BodySection