import React, { useState } from "react";
import BtnSm from "../../../Global/BtnSm";
import image from "../../../../assets/admindashboard/events/images/launch-image.png";
import eventFullImage from "../../../../assets/admindashboard/events/images/EventImage.png";
import { TbViewportWide } from "react-icons/tb";
import ImageViewerModal from "../../../../layouts/AdminDashboard/ImageViewerModal";
import ViewEventImage from "./ViewEventImage";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import EmptyDataState from "../../../Global/EmptyDataState";
import { Skeleton } from "@mui/material";

const Jumbotron = ({event}) => {

  // OBJECT INITIALIZATION
  // history object
  const history = useHistory();


  // STATES
  const [imageModalState, setImageModalState] = useState(false);
  const { singleEventLoading } = useSelector(state=>{
    const { singleEventLoading } = state.loadingIndicator
    return {
        singleEventLoading
    }
})


  // HANDLERS
  // image modal handler
  const toggleImageModal = () => {
    setImageModalState(!imageModalState);
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex item-center justify-between">
        <p className="text-[#1D2433] text-4xl font-semibold">
          First Enugu Tech Networking Event
        </p>

        <div className="flex flex-col w-[30%]">
          <BtnSm
            onClick={() => history.push(`/dashboard/events/edit/${event._id}`)}
            title={"Edit Event"}
          />
        </div>
      </div>

      <div className="relative w-full">



        {
            singleEventLoading?
            <Skeleton
            variant="rectangular"
            sx={{
                width: "100%",
                height: "30vh",
                borderRadius: "10px",
            }}
            />:
            event && Object.keys(event).length!==0?
            <>
              <img
                src={event.imgurl}
                className="rounded-[10px] w-full object-cover h-[30vh]"
                alt=""
              />

              {/* dark transparent bg */}
              <span className="absolute h-full w-full bg-black opacity-40 z-1 rounded-[10px] top-0 left-0">
                {""}
              </span>

              {/* view image button */}
              <button
                onClick={toggleImageModal}
                className="flex flex-col text-[#1d2433] text-base font-normal justify-center items-center p-3 gap-2 absolute right-[15px] top-[15px] hover:bg-[#e3e3e3] transition-colors bg-white rounded-[4px] z-2"
              >
                <TbViewportWide color="#5C5F62" size={24} />
                <span className="opacity-[0.65] mobile:hidden">View full image</span>
              </button>
            </>:
            <EmptyDataState />
        }
      </div>

      <ImageViewerModal
        modalIsOpen={imageModalState}
        toggleModal={toggleImageModal}
        disableBtn={true}
      >
        <ViewEventImage 
        closeModal={toggleImageModal} 
        image={event?.imgurl} />
      </ImageViewerModal>
    </div>
  );
};

export default Jumbotron;
