import React from 'react'

const TrackLabel = ({trackId, trackTitle}) => {
  return (
    <span className={`text-[10px] font-semibold ${trackId===1?"bg-[#E1E6EF] text-[#1D2433]":trackId===2? "bg-[#8B54F7] text-white":"bg-white text-white"} rounded-[100px] py-2 px-4 self-start w-fit`}>
        {trackTitle} track
    </span>
  )
}

export default TrackLabel