import React from 'react'
import { AiFillCalendar, AiOutlineClockCircle } from 'react-icons/ai'
import { HiOutlineOfficeBuilding, HiUserCircle } from 'react-icons/hi'
import { Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import EmptyDataState from '../../../Global/EmptyDataState'
import { FULL_MONTHS, getRTDate } from '../../../../helpers'

const EventInfo = ({event}) => {
    // STATES
    const { singleEventLoading } = useSelector(state=>{
        const { singleEventLoading } = state.loadingIndicator
        return {
            singleEventLoading
        }
    })

    return (
        <>

            {
                singleEventLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                }}
                />:
                event && Object.keys(event).length!==0?
                <div className='[box-shadow:0_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] p-6 md:!py-12 h-fit'>

                    <div className='container mx-auto flex flex-col gap-4 md:!gap-7'>
                        <div className='flex flex-col gap-5'>
                            
                            {/* Event Info */}
                            <p className='text-[#1d2433] opacity-[0.65] font-semibold text-xl'>
                                Event Info
                            </p>

                            <div className='flex flex-col gap-2 border-b border-[#e1e6ef] pb-9'>
                                <p className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3'>
                                    <AiFillCalendar size={23} />
                                    <span className='w-[80%]'>
                                        {getRTDate.date(event.date) + " " + FULL_MONTHS[getRTDate.month(event.date)] + ", 2022"}
                                    </span>
                                </p>

                                <p className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3'>
                                    <AiOutlineClockCircle size={23} />
                                    <span className='w-[80%]'>
                                        {event.time}
                                    </span>
                                </p>

                                <p className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3 w-full'>
                                    <HiOutlineOfficeBuilding size={23} />
                                    <span className='w-[80%]'>
                                        {event.location}
                                    </span>
                                </p>
                            </div>
                            
                            {/* Guests */}
                            <div className='flex flex-col gap-4 pt-3'>

                                <p className='text-[#1d2433] opacity-[0.65] font-semibold text-xl'>
                                    Guests
                                </p>

                                {
                                    event.speakers.map(({name, about, _id})=>(
                                        <div
                                        key={_id}
                                        className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3'>
                                            <HiUserCircle size={25} />

                                            <p className='flex flex-col gap-1'>
                                                <span>{name}</span>

                                                <span className='text-xs'>{about}</span>
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>:
                <EmptyDataState />
            }
        </>
    )
}

export default EventInfo