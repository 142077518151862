import React from 'react'
import { Link } from 'react-router-dom'
import ProfileAvatar from '../global/ProfileAvatar'
import PersonalInformationForm from './PersonalInformationForm'
import ProfileAvi from './ProfileAvi'

const SettingsBody = () => {
    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Settings
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-9'>

                <div className='bg-white rounded-[10px] w-full flex flex-col gap-16 py-10 px-5'>

                    <ProfileAvi />

                    <PersonalInformationForm />

                    <Link 
                    to="/dashboard/settings/create-admin"
                    className='font-semibold text-xl text-[#8B54F7] hover:opacity-80 hover:no-underline hover:text-[#8B54F7]'>
                        Create new admin account
                    </Link>

                </div>
            </section>
        </section>
    )
}

export default SettingsBody