import axios from "./axios";
import { 
    call, 
    put, 
    takeLatest, 
    spawn 
} from "redux-saga/effects";
import { 
    clientErrorMessage, 
    delay 
} from "./reusable";
import { 
    clearAddStudentToBootcampSuccess, 
    clearCreateBootcampSuccess, 
    clearDeleteBootcampSuccess, 
    clearDeleteStudentFromBootcampSuccess, 
    clearPutEditSingleBootcampSuccess, 
    deleteBootcampFailure, 
    deleteBootcampLoading, 
    deleteBootcampSuccess, 
    deleteStudentFromBootcampFailure, 
    deleteStudentFromBootcampLoading, 
    deleteStudentFromBootcampSuccess, 
    getBootcampsAdminFailure, 
    getBootcampsAdminLoading, 
    getBootcampsAdminSuccess, 
    getSingleBootcampFailure, 
    getSingleBootcampLoading, 
    getSingleBootcampSuccess, 
    postCreateBootcampFailure, 
    postCreateBootcampLoading, 
    postCreateBootcampSuccess, 
    putAddStudentToBootcampFailure, 
    putAddStudentToBootcampLoading, 
    putAddStudentToBootcampSuccess, 
    putEditSingleBootcampFailure, 
    putEditSingleBootcampLoading, 
    putEditSingleBootcampSuccess 
} from "../action";
import { 
    DELETE_BOOTCAMP_REQUEST,
    DELETE_STUDENT_FROM_BOOTCAMP_REQUEST,
    GET_BOOTCAMPS_ADMIN_REQUEST, 
    GET_SINGLE_BOOTCAMPS_REQUEST, 
    POST_CREATE_BOOTCAMP_REQUEST, 
    PUT_EDIT_BOOTCAMP_REQUEST, 
    PUT_STUDENT_TO_BOOTCAMP_REQUEST 
} from "../types";

const ajaxDBCalls = {
    getBootcampsAdmin: async () => {
        const reponse = await axios({
            method: "GET",
            url: "bootcamp/admin"
        })
        return reponse
    },
    getSingleBootcamp: async ({ payload }) => {
        const response = await axios({
          method: "GET",
          url: `bootcamp/singlebootcampadmin/${payload}`,
        });
    
        return response;
    },
    putEditBootcamp: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `bootcamp/${payload.bootcampId}`,
            data: payload.bootcampData
        })

        return response
    },
    postCreateBootcamp: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `bootcamp/create`,
            data: payload.bootcampData
        })

        return response
    },
    putStudentToBootcamp: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `bootcamp/student/${payload.bootcampId}`,
            data: {
                email: payload.email
            }
        })

        return response
    },
    deleteBootcamp: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: `bootcamp/${payload}`
        })

        return response
    },
    deleteStudentFromBootcamp: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: `bootcamp/student/${payload.bootcampId}/${payload.studentId}`
        })

        return response
    },
}

// WORKERS
// get bootcamps
function* getBootcampsAdmin() {
    try {
        yield put(getBootcampsAdminLoading(true));

        const response = yield call(ajaxDBCalls.getBootcampsAdmin)

        yield put(getBootcampsAdminSuccess(response.data))
        yield put(getBootcampsAdminLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getBootcampsAdminFailure(errorMessage))
        yield put(getBootcampsAdminLoading(false))
        yield delay();
        yield put(getBootcampsAdminFailure(""))
    }
}

// get single bootcamp
function* getSingleBootcamp(payload) {
    try {
        yield put(getSingleBootcampLoading(true));
    
        const response = yield call(ajaxDBCalls.getSingleBootcamp, payload);
        yield put(getSingleBootcampSuccess(response.data));
        yield put(getSingleBootcampLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getSingleBootcampFailure(errorMessage));
        yield put(getSingleBootcampLoading(false));
        yield delay();
        yield put(getSingleBootcampFailure(""));
    }
}

// update bootcamp
function* putEditSingleBootcamp(payload) {
    try {
        yield put(putEditSingleBootcampLoading(true));
        const response = yield call(ajaxDBCalls.putEditBootcamp, payload);
        yield put(putEditSingleBootcampSuccess(response.data));
        yield put(putEditSingleBootcampLoading(false));
        yield delay();
        yield put(clearPutEditSingleBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(putEditSingleBootcampFailure(errorMessage));
        yield put(putEditSingleBootcampLoading(false));
        yield delay();
        yield put(putEditSingleBootcampFailure(""));
    }
}

// create bootcamp
function* postCreateBootcamp(payload) {
    try {
        yield put(postCreateBootcampLoading(true));
        const response = yield call(ajaxDBCalls.postCreateBootcamp, payload);
        yield put(postCreateBootcampSuccess(response.data));
        yield put(postCreateBootcampLoading(false));
        yield delay();
        yield put(clearCreateBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(postCreateBootcampFailure(errorMessage));
        yield put(postCreateBootcampLoading(false));
        yield delay();
        yield put(postCreateBootcampFailure(""));
    }
}

// add student to bootcamp
function* putStudentToBootcamp(payload) {
    try {
        yield put(putAddStudentToBootcampLoading(true));
        const response = yield call(ajaxDBCalls.putStudentToBootcamp, payload);
        yield put(putAddStudentToBootcampSuccess(response.data));
        yield put(putAddStudentToBootcampLoading(false));
        yield delay();
        yield put(clearAddStudentToBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(putAddStudentToBootcampFailure(errorMessage));
        yield put(putAddStudentToBootcampLoading(false));
        yield delay();
        yield put(putAddStudentToBootcampFailure(""));
    }
}

// delete bootcamp
function* deleteBootcamp(payload) {
    try {
        yield put(deleteBootcampLoading(true));
        const response = yield call(ajaxDBCalls.deleteBootcamp, payload);
        yield put(deleteBootcampSuccess(response.data));
        yield put(deleteBootcampLoading(false));
        yield delay();
        yield put(clearDeleteBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteBootcampFailure(errorMessage));
        yield put(deleteBootcampLoading(false));
        yield delay();
        yield put(deleteBootcampFailure(""));
    }
}

// delete student from bootcamp
function* deleteStudentFromBootcamp(payload) {
    try {
        yield put(deleteStudentFromBootcampLoading(true));
        const response = yield call(ajaxDBCalls.deleteStudentFromBootcamp, payload);
        yield put(deleteStudentFromBootcampSuccess(response.data));
        yield put(deleteStudentFromBootcampLoading(false));
        yield delay();
        yield put(clearDeleteStudentFromBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteStudentFromBootcampFailure(errorMessage));
        yield put(deleteStudentFromBootcampLoading(false));
        yield delay();
        yield put(deleteStudentFromBootcampFailure(""));
    }
}

// WATCHERS
function* getBootcampsAdminWatcher() {
    yield takeLatest(GET_BOOTCAMPS_ADMIN_REQUEST, getBootcampsAdmin)
}

function* getSingleBootcampWatcher() {
    yield takeLatest(GET_SINGLE_BOOTCAMPS_REQUEST, getSingleBootcamp)
}

function* putEditSingleBootcampWatcher() {
    yield takeLatest(PUT_EDIT_BOOTCAMP_REQUEST, putEditSingleBootcamp)
}

function* postCreateBootcampWatcher() {
    yield takeLatest(POST_CREATE_BOOTCAMP_REQUEST, postCreateBootcamp)
}

function* putStudentToBootcampWatcher() {
    yield takeLatest(PUT_STUDENT_TO_BOOTCAMP_REQUEST, putStudentToBootcamp)
}

function* deleteBootcampWatcher() {
    yield takeLatest(DELETE_BOOTCAMP_REQUEST, deleteBootcamp)
}

function* deleteStudentFromBootcampWatcher() {
    yield takeLatest(DELETE_STUDENT_FROM_BOOTCAMP_REQUEST, deleteStudentFromBootcamp)
}

// bootcamp saga
export default function* bootcampsSaga() {
    yield spawn(getBootcampsAdminWatcher);
    yield spawn(getSingleBootcampWatcher);
    yield spawn(putEditSingleBootcampWatcher);
    yield spawn(postCreateBootcampWatcher);
    yield spawn(putStudentToBootcampWatcher);
    yield spawn(deleteBootcampWatcher);
    yield spawn(deleteStudentFromBootcampWatcher);
}