import React, { useEffect, useState } from 'react'
import { FiCamera } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useLocation } from 'react-router-dom'
import { DatePicker } from 'rsuite'
import MyModal from '../../../../layouts/MyModal'
import BtnSm from '../../../Global/BtnSm'
import FileUploadPopup from '../../global/FileUploadPopup'
import ProfileAvatar from '../../global/ProfileAvatar'
import featuredImage from "../../../../assets/admindashboard/events/images/new-event-image.png"
import AddNewGuests from './AddNewGuests'
import { getSingleEventRequest, updateEventRequest } from '../../../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { FormError } from '../../../Global/Error'
import SubmitBtn from '../../../Global/SubmitBtn'
import EmptyDataState from '../../../Global/EmptyDataState'
import LoadingCircles from '../../../Global/LoadingCircles'

const BodySection = () => {

    // STATES
    // guests
    const [eventGuests, setGuests] = useState([])
    // image url state
    const [imageUrl, setImageUrl] = useState("")
    // modal state for saved account info
    const [uploadFileModalState, setUploadFileModalState] = useState(false)
    const { 
        singleEvent,
        updateEventLoading,
        updateEventSuccess,
        updateEventError ,
        singleEventLoading
    } = useSelector(state=>{
        const { 
            updateEventLoading,
            singleEventLoading
        } = state.loadingIndicator;
        const {
            success: { updateEvent: updateEventSuccess },
            errors: { updateEvent: updateEventError },
            } = state.ajaxStatuses;
        const { singleEvent } = state.eventsReducer
        return {
            singleEvent,
            updateEventLoading,
            updateEventSuccess,
            updateEventError,
            singleEventLoading
        }
    })

    
    // OBJECT INITIALIZATION
    // dispatch object
    const dispatch = useDispatch()
    // history object
    const history = useHistory()
    // location object
    const location = useLocation()
    // creating the path object variable
    const tempArrPath =  location.pathname.split("/");
    const eventId = tempArrPath[tempArrPath.length-1]
    // Formik value
    const formik = useFormik({
        initialValues: {
            name: "",
            imgurl: "",
            description: "",
            location: "",
            date: "",
            time: "",
        },
        onSubmit: (values) => {
            const finalObj = { ...values };
            finalObj.date = new Date(values.date).toISOString();
            finalObj.imgurl = imageUrl
            finalObj.time = moment(values.time, ["HH.mm"]).format("hh:mm a");

            // dispatch Action
            dispatch(
                updateEventRequest({
                    eventId,
                    eventData: finalObj
                })
            );
        },
        validationSchema: Yup.object({
            name: Yup.string().min(4).required(),
            description: Yup.string().min(7).required(),
            location: Yup.string().min(4).required(),
            date: Yup.date().required(),
            time: Yup.string().required(),
        }),
    });


    // HANDLERS
    // toggle saved account info
    const toggleUploadFileModal = () => {
        setUploadFileModalState(!uploadFileModalState)
    }


    // SIDE EFFECTS
    // fetch and populate event data
    useEffect(()=>{
        dispatch(getSingleEventRequest(eventId))
    }, [])
    useEffect(()=>{
        if(singleEvent && Object.keys(singleEvent).length!==0){
            formik.setValues((values)=>({
                ...values,
                name: singleEvent.name,
                description: singleEvent.description,
                location: singleEvent.location,
                date: singleEvent.date.split("T")[0],
                time: singleEvent.time, 
            }))
            setImageUrl(singleEvent.imgurl)
            setGuests(singleEvent.speakers)
        }
    }, [singleEvent])
    // check for error message
    useEffect(() => {
        if (updateEventError) {
            toast.error(updateEventError, {
                duration: 3000,
            });
        }
    }, [updateEventError]);
    // check for success message
    useEffect(() => {
        if (updateEventSuccess) {
            toast.success(updateEventSuccess, {
                duration: 3000,
            });
            history.replace(`/dashboard/events/${eventId}`);
        }
    }, [updateEventSuccess]);


    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div className='flex flex-col gap-7'>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Edit event
                    </p>

                    <p 
                    onClick={()=>history.goBack()}
                    className='flex items-center justify-start gap-3 cursor-pointer
                    '>
                        <IoIosArrowBack 
                        className="text-[#8B54F7]"
                        size={18} />
                        <span className='font-normal text-base text-gray-500'>
                            Back
                        </span>
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-16'>
                
                {/* container */}
                <div className='rounded-[10px] bg-white flex flex-col py-10 px-5 w-full h-full'>

                    {
                        singleEventLoading?
                        <LoadingCircles />:
                        singleEvent && Object.keys(singleEvent).length !== 0?
                        <>
                            <div className='bg-[#F8F9FC] p-5 rounded-[10px] w-full h-full grid grid-cols-2 gap-16'>

                                {/* event infor2 */}
                                <div className='w-full flex flex-col gap-12'>

                                    {/* event info form1 */}
                                    <form 
                                    onSubmit={(e)=>e.preventDefault()}
                                    className='flex flex-col gap-5 w-full'>

                                        {/* Event name input container */}
                                        <label className='flex flex-col gap-2'>

                                            {/* label text */}
                                            <div className='flex items-center justify-start'>
                                                <span className='font-semibold text-sm text-[#344054]'>
                                                    Event name
                                                </span>
                                            </div>

                                            {/* input field */}
                                            <input 
                                            type="text" 
                                            {...formik.getFieldProps("name")}
                                            className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                            placeholder='Enter event name here' />
                                            {formik.touched.name && formik.errors.name && (
                                                <FormError message={formik.errors.name} />
                                            )}
                                        </label>

                                        <label className='flex flex-col gap-2'>

                                            {/* label text */}
                                            <div className='flex items-center justify-start'>
                                                <span className='font-semibold text-sm text-[#344054]'>
                                                    About event
                                                </span>
                                            </div>

                                            {/* input field */}
                                            <textarea 
                                            {...formik.getFieldProps("description")}
                                            className='border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2 h-[350px]' 
                                            placeholder='About event' />
                                            {formik.touched.description && formik.errors.description && (
                                                <FormError message={formik.errors.description} />
                                            )}
                                        </label>
                                    </form>

                                    <AddNewGuests
                                    eventGuests={eventGuests}
                                    eventId={eventId}  />                            

                                    {/* featured image */}
                                    <div className="flex flex-col gap-3">
                                        <p className="text-[#344054] text-xl font-semibold">
                                        Featured Image
                                        </p>

                                        <div className="relative w-fit">
                                            <img
                                                src={imageUrl?imageUrl:featuredImage}
                                                className="w-[296px] h-[184px] rounded-[10px] object-cover "
                                                alt=""
                                            />

                                            <span
                                                onClick={toggleUploadFileModal}
                                                className="bg-white rounded-[50%] flex items-center justify-center w-[40px] h-[40px] absolute top-[10px] right-[10px] active:bg-gray-100 cursor-pointer"
                                            >
                                                <FiCamera color="#8B54F7" size={22} />
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                
                                {/* event infor2 */}
                                <div className='w-full'>

                                    {/* event infor form2 */}
                                    <form 
                                    onSubmit={(e)=>e.preventDefault()}
                                    className='flex flex-col gap-5 w-full'>
                                        
                                        {/* address */}
                                        <label className='flex flex-col gap-2'>

                                            {/* label text */}
                                            <div className='flex items-center justify-start'>
                                                <span className='font-semibold text-sm text-[#344054]'>
                                                    Event address
                                                </span>
                                            </div>

                                            {/* input field */}
                                            <textarea 
                                            {...formik.getFieldProps("location")}
                                            className='border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] resize-none py-2 h-[68px]' 
                                            placeholder='Event address' />
                                            {formik.touched.location && formik.errors.location && (
                                                <FormError message={formik.errors.location} />
                                            )}
                                        </label>

                                        {/* Date input container */}
                                        <label className='flex flex-col gap-2'>

                                            {/* label text */}
                                            <div className='flex items-center justify-start'>
                                                <span className='font-semibold text-sm text-[#344054]'>
                                                    Event date
                                                </span>
                                            </div>

                                            {/* input field */}
                                            <input 
                                            type="date" 
                                            {...formik.getFieldProps("date")}
                                            className='w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                                            placeholder='Start date' />
                                            {/* <DatePicker
                                            className='w-full'
                                            editable={false}
                                            {...formik.getFieldProps("date")}
                                            placement='bottomStart'
                                            placeholder="Start date"
                                            size='lg'
                                            /> */}
                                            {formik.touched.date && formik.errors.date && (
                                                <FormError message={formik.errors.date} />
                                            )}
                                        </label>

                                        {/* Time input container */}
                                        <label className='flex flex-col gap-2'>

                                            {/* label text */}
                                            <div className='flex items-center justify-start'>
                                                <span className='font-semibold text-sm text-[#344054]'>
                                                    Event time
                                                </span>
                                            </div>
                                            <input 
                                            type="time" 
                                            {...formik.getFieldProps("time")}
                                            pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                                            className='w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]'
                                            placeholder='Start time' />
                                            {/* <DatePicker
                                            format="hh:mm aa"
                                            placement='bottomStart'
                                            ranges={[
                                                {
                                                    label: 'Now',
                                                    value: new Date()
                                                }
                                            ]}
                                            className='w-full'
                                            showMeridian
                                            {...formik.getFieldProps("time")}
                                            editable={false}
                                            placeholder="Start time"
                                            size='lg'
                                            /> */}
                                            {formik.touched.time && formik.errors.time && (
                                                <FormError message={formik.errors.time} />
                                            )}
                                        </label>
                                    </form>
                                </div>
                            </div>

                            <div className="w-[40%] flex flex-col items-stretch justify-center mx-auto mt-5">
                                <SubmitBtn
                                loading={updateEventLoading}
                                onSubmit={()=>{
                                    if(!imageUrl) {
                                        toast.error("Please fill out the provided fields")
                                    } else {
                                        formik.handleSubmit()
                                    }
                                }}
                                title={"Update Event"} />
                            </div>
                        </>:
                        <EmptyDataState />
                    }
                </div>
            </section>

            <MyModal
            disableBtn={false}
            modalIsOpen={uploadFileModalState}
            toggleModal={toggleUploadFileModal}>
                <FileUploadPopup
                closeModal={toggleUploadFileModal}
                setImage={setImageUrl}  />
            </MyModal>
        </section>
    )
}

export default BodySection