import React from 'react'
import { FiZap } from 'react-icons/fi'

const DeletePopup = ({itemId, onDelete, closeModal}) => {
    return (
        <div className='bg-white flex flex-col gap-5 w-full p-6'>
            
            <div className='flex items-center justify-center h-[48px] w-[48px] bg-[#F8F9FC] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[50%]'>
                <span className='flex items-center justify-center bg-[#FEF1F2] h-[24px] w-[24px] rounded-[50%]'>
                    <FiZap size={12} color="#E02D3C" />
                </span>
            </div>

            <p className='text-[#111827] text-base font-semibold text-center'>
                Are you sure you want to delete?
            </p>

            {/* btn containers */}
            <div className='flex flex-col items-stretch w-full gap-4 mt-3'>

                <button 
                onClick={()=>{
                    onDelete(itemId)
                    closeModal()
                }}
                className='text-white font-semibold text-base rounded-[6px] h-[44px] bg-[#E02D3C] hover:opacity-80'>
                    Yes, delete
                </button>

                <button 
                onClick={closeModal}
                className='text-[#374151] font-medium text-base rounded-[6px] h-[44px] bg-white border border-[#D1D5DB] hover:bg-gray-50'>
                    No, don’t delete
                </button>

            </div>
        </div>
    )
}

export default DeletePopup