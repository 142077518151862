import { spawn } from "redux-saga/effects";
import bootcampsSaga from "./bootcamps";
import clubsSaga from "./clubs";
import coursesSaga from "./courses";
import eventsSaga from "./events";
import usersSaga from "./users";
import clubSubscriptionsSaga from "./clubSubscription";
import spacesSaga from "./spaces";
import bootcampStudentsSaga from "./bootcampStudents";
import getCritiqueEventParticipantsSaga from "./critiqueEvent";
import getDesignBootcampParticipantsSaga from "./designBootcamp";

export default function* rootSaga() {
    yield spawn(usersSaga);
    yield spawn(bootcampsSaga);
    yield spawn(coursesSaga);
    yield spawn(eventsSaga);
    yield spawn(clubsSaga);
    yield spawn(clubSubscriptionsSaga);
    yield spawn(spacesSaga);
    yield spawn(bootcampStudentsSaga);
    yield spawn(getCritiqueEventParticipantsSaga);
    yield spawn(getDesignBootcampParticipantsSaga);
}
