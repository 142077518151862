import { GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS } from "../types"

// REDUCER'S INITIAL STATE
const initialState = () => ({
    critiqueEventParticipants: [],
})

const critiqueEventReducer = (state = initialState(), {type, payload }) => {

    switch(type) {
        case GET_CRITIQUE_EVENT_PARTICIPANT_SUCCESS:
            return {
                ...state,
                critiqueEventParticipants: payload.cals
            };
            
        default:
            return state;
    }
}

export default critiqueEventReducer