import React from 'react'
import Accordion from '../../global/Accordion'

const ourItems = ["Doctype & Basic Layout", "Headings, Paragraphs & Typography", "Doctype & Basic Layout", "Headings, Paragraphs & Typography"]

const CourseOutlineAccordion = ({topics}) => {
    return (
        <div className='flex flex-col gap-5 justify-between bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] py-7 px-8 mt-8'>
                <p className='text-[#3f444d] text-xl font-semibold'>
                    Course outline
                </p>

                {
                    topics.map((item)=>(
                        <Accordion
                        key={item._id}
                        courseItemList={item.content.split("%")}
                        couseTitle={item.sub_topics} />
                    ))
                }                
        </div>
    )
}

export default CourseOutlineAccordion