import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { clearAddUserToEventSuccess, clearCreateEventSuccess, clearDeleteEventSuccess, clearDeleteSpeakerFromEventSuccess, clearPutSpeakerSuccess, clearUpdateEventSuccess, deleteEventFailure, deleteEventLoading, deleteEventSuccess, deleteSpeakerFromEventFailure, deleteSpeakerFromEventLoading, deleteSpeakerFromEventSuccess, getEventsAdminFailure, getEventsAdminLoading, getEventsAdminSuccess, getSingleEventFailure, getSingleEventLoading, getSingleEventSuccess, postCreateEventFailure, postCreateEventLoading, postCreateEventSuccess, putAddUserToEventFailure, putAddUserToEventLoading, putAddUserToEventSuccess, putSpeakerToEventFailure, putSpeakerToEventLoading, putSpeakerToEventSuccess, updateEventFailure, updateEventLoading, updateEventSuccess } from "../action";
import { DELETE_EVENT_REQUEST, DELETE_SPEAKER_FROM_EVENT_REQUEST, GET_EVENTS_ADMIN_REQUEST, GET_SINGLE_EVENT_REQUEST, POST_CREATE_EVENT_REQUEST, PUT_SPEAKER_TO_EVENT_REQUEST, PUT_USER_TO_EVENT_REQUEST, UPDATE_EVENT_REQUEST } from "../types";

const ajaxDBCalls = {
    // get events for admin
    getEvents: async () => {
        const reponse = await axios({
            method: "GET",
            url: "event/admin"
        })
        return reponse
    },
    // add user to event
    putUserToEvent: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `event/user/${payload.eventId}`,
            data: {
                email: payload.email
            }
        })

        return response
    },
    // get single event
    getSingleEvent: async ({payload}) => {
        const response = await axios({
            method: "GET",
            url: `event/singleevent/${payload}`
        })
        return response
    },
    // update event
    updateEvent: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `event/${payload.eventId}`,
            data: payload.eventData
        })

        return response
    },
    // create event
    createEvent: async ({payload}) => {
        const response = await axios({
            method: "POST",
            url: `event/create`,
            data: payload
        })

        return response
    },
    // delete event
    deleteEvent: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: `event/${payload}`
        })

        return response
    },
    // delete speaker from event
    deleteSpeakerFromEvent: async ({payload}) => {
        const response = await axios({
            method: "DELETE",
            url: `event/speaker/${payload.eventId}/${payload.speakerId}`
        })

        return response
    },
    // delete speaker from event
    addSpeakerToEvent: async ({payload}) => {
        const response = await axios({
            method: "PUT",
            url: `event/speaker/${payload.eventId}`,
            data: payload.speakerData
        })

        return response
    },
}

// WORKERS
// get events
function* getEvents() {
    try {
        yield put(getEventsAdminLoading(true));

        const response = yield call(ajaxDBCalls.getEvents)

        yield put(getEventsAdminSuccess(response.data))
        yield put(getEventsAdminLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getEventsAdminFailure(errorMessage))
        yield put(getEventsAdminLoading(false))
        yield delay();
        yield put(getEventsAdminFailure(""))
    }
}

// add user to event
function* putUserToEvent(payload) {
    try {
        yield put(putAddUserToEventLoading(true));
        const response = yield call(ajaxDBCalls.putUserToEvent, payload);
        yield put(putAddUserToEventSuccess(response.data));
        yield put(putAddUserToEventLoading(false));
        yield delay();
        yield put(clearAddUserToEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(putAddUserToEventFailure(errorMessage));
        yield put(putAddUserToEventLoading(false));
        yield delay();
        yield put(putAddUserToEventFailure(""));
    }
}

// get single event
function* getSingleEvent(payload) {
    try {
        yield put(getSingleEventLoading(true))

        const response = yield call(ajaxDBCalls.getSingleEvent, payload)

        yield put(getSingleEventSuccess(response.data))
        yield put(getSingleEventLoading(false))
    } catch (err) {
        let errorMessage = ""
        if(err.request) errorMessage = clientErrorMessage;

        if(err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getSingleEventFailure(errorMessage))
        yield put(getSingleEventLoading(false))
        yield delay()
        yield put(getSingleEventFailure(""))
    }
}

// update event
function* updateEvent(payload) {
    try {
        yield put(updateEventLoading(true));
        const response = yield call(ajaxDBCalls.updateEvent, payload);
        yield put(updateEventSuccess(response.data));
        yield put(updateEventLoading(false));
        yield delay();
        yield put(clearUpdateEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(updateEventFailure(errorMessage));
        yield put(updateEventLoading(false));
        yield delay();
        yield put(updateEventFailure(""));
    }
}

// create event
function* createEvent(payload) {
    try {
        yield put(postCreateEventLoading(true));
        const response = yield call(ajaxDBCalls.createEvent, payload);
        yield put(postCreateEventSuccess(response.data));
        yield put(postCreateEventLoading(false));
        yield delay();
        yield put(clearCreateEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(postCreateEventFailure(errorMessage));
        yield put(postCreateEventLoading(false));
        yield delay();
        yield put(postCreateEventFailure(""));
    }
}

// create event
function* deleteEvent(payload) {
    try {
        yield put(deleteEventLoading(true));
        const response = yield call(ajaxDBCalls.deleteEvent, payload);
        yield put(deleteEventSuccess(response.data));
        yield put(deleteEventLoading(false));
        yield delay();
        yield put(clearDeleteEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteEventFailure(errorMessage));
        yield put(deleteEventLoading(false));
        yield delay();
        yield put(deleteEventFailure(""));
    }
}

// delete speaker from event
function* deleteSpeakerFromEvent(payload) {
    try {
        yield put(deleteSpeakerFromEventLoading(true));
        const response = yield call(ajaxDBCalls.deleteSpeakerFromEvent, payload);
        yield put(deleteSpeakerFromEventSuccess(response.data));
        yield put(deleteSpeakerFromEventLoading(false));
        yield delay();
        yield put(clearDeleteSpeakerFromEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(deleteSpeakerFromEventFailure(errorMessage));
        yield put(deleteSpeakerFromEventLoading(false));
        yield delay();
        yield put(deleteSpeakerFromEventFailure(""));
    }
}

// add speaker to event
function* addSpeakerToEvent(payload) {
    try {
        yield put(putSpeakerToEventLoading(true));
        const response = yield call(ajaxDBCalls.addSpeakerToEvent, payload);
        yield put(putSpeakerToEventSuccess(response.data));
        yield put(putSpeakerToEventLoading(false));
        yield delay();
        yield put(clearPutSpeakerSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(putSpeakerToEventFailure(errorMessage));
        yield put(putSpeakerToEventLoading(false));
        yield delay();
        yield put(putSpeakerToEventFailure(""));
    }
}

// WATCHERS
function* getEventsWatcher() {
    yield takeLatest(GET_EVENTS_ADMIN_REQUEST, getEvents)
}

function* putUserToEventWatcher() {
    yield takeLatest(PUT_USER_TO_EVENT_REQUEST, putUserToEvent)
}

function* getSingleEventWatcher() {
    yield takeLatest(GET_SINGLE_EVENT_REQUEST, getSingleEvent)
}

function* updateEventWatcher() {
    yield takeLatest(UPDATE_EVENT_REQUEST, updateEvent)
}
function* createEventWatcher() {
    yield takeLatest(POST_CREATE_EVENT_REQUEST, createEvent)
}
function* deleteEventWatcher() {
    yield takeLatest(DELETE_EVENT_REQUEST, deleteEvent)
}
function* deleteSpeakerFromEventWatcher() {
    yield takeLatest(DELETE_SPEAKER_FROM_EVENT_REQUEST, deleteSpeakerFromEvent)
}
function* addSpeakerToEventWatcher() {
    yield takeLatest(PUT_SPEAKER_TO_EVENT_REQUEST, addSpeakerToEvent)
}

// events saga
export default function* eventsSaga() {
    yield spawn(getEventsWatcher);
    yield spawn(putUserToEventWatcher);
    yield spawn(getSingleEventWatcher);
    yield spawn(updateEventWatcher);
    yield spawn(createEventWatcher);
    yield spawn(deleteEventWatcher);
    yield spawn(deleteSpeakerFromEventWatcher);
    yield spawn(addSpeakerToEventWatcher);
}