import { useEffect } from 'react'
import ProfileAvatar from '../global/ProfileAvatar'
import AvailableBootcamps from '../Bootcamps/AvailableBootcamps'
import OpticsCard from './OpticsCard'
import { useDispatch, useSelector } from 'react-redux'
import { getBootcampsAdminRequest } from '../../../redux/action'

const BootcampsBody = () => {

    // dispatch object
    const dispatch = useDispatch()

    const { bootcamps } = useSelector(state=>{
        const { bootcamps } = state.bootcampsReducer
        return {
            bootcamps
        }
    })

    useEffect(()=>{
        dispatch(getBootcampsAdminRequest())
    }, [])

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>
            
            {/* header */}
            <header  className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        Bootcamps
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* body section */}
            <section className='container mx-auto w-full mt-12 mobile:px-4 flex flex-col gap-10'>
                

                {/* optics card */}
                <OpticsCard
                numberOfStudents={1000}
                availableBootcamps={4}
                availableCourses={3} />

                {/* Available Bootcamps */}
                <AvailableBootcamps
                availableBootcamps={bootcamps} />
            </section>
        </section>
    )
}

export default BootcampsBody