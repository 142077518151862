import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import Users from ".";
import EditUser from "./EditUser";

const UsersRouter = ({path}) => {
    
    return (
        <Switch>
            <Route 
            exact 
            path={path}
            component={Users} />

            <Route 
            exact 
            path={`${path}/:id`}
            component={EditUser} />

        </ Switch>
    )
}

export default UsersRouter