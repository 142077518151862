import React, { useEffect, useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'
import { IoIosArrowDown } from 'react-icons/io'
import { MdFilterList } from 'react-icons/md'
import _ from "lodash";
import ParticipantsTable from './ParticipantsTable'
import MenuList from '../Students/MenuList';

const TableModalView = ({ participantList, isCritique }) => {

    // STATES
    const [participantStateList, setParticipantsList] = useState([]);
    const [filterIsOpen, setFilterState] = useState(false);
    const [filterValue, setFilterValue] = useState(null);
    const filterList = ["Name", "Number"];
    const [filteredResult, setFilteredResult] = useState("");

    // HANDLERS
    const handleFilterMenuClick = (id) => {
        setFilterValue(id);
        setFilterState(false);
    };
    const handleOnChange = (e) => {
        setFilteredResult(e.target.value);
    };
    const sortUserList = (id) => {
        if (id === 1) {

        // sorting names using lodash
        const arr = [...participantStateList];
        const newArr = _.sortBy(arr, [(student) => student.firstname]);
        setParticipantsList(newArr);
        } else if (id === 2) {

        // sorting phone numbers using lodash
        const arr = [...participantStateList];
        const newArr = _.sortBy(arr, [(student) => student.mobile]);
        setParticipantsList(newArr);
        }
    };

    // SIDE EFFECTS
    useEffect(() => {
        setParticipantsList(participantList);
    }, [participantList]);
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> {
            document.body.style.overflow = 'unset';
        }
    }, []);

    return (
        <div className="flex flex-col gap-7 bg-gray-200 w-full h-full p-4">

            {/* search and filter */}
            <div className="flex gap-5 w-[83%]">

                {/* search bar */}
                <div className="bg-white rounded-[4px] flex items-center gap-2 py-3 px-3 w-full">

                    <HiOutlineSearch />
                    <input
                        value={filteredResult}
                        onChange={handleOnChange}
                        className="placeholder:font-normal placeholder:text-sm placeholder:text-[#202223] text-sm font-normal text-[#202223] outline-none w-full"
                        type="search"
                        placeholder="Search" />
                </div>

                {/* filter button */}
                <div className="bg-white rounded-[4px] flex items-center gap-2 py-3 px-4 min-w-[26%] relative">

                    <MdFilterList
                        onClick={() => setFilterState(!filterIsOpen)}
                        className={`cursor-pointer`}
                        size={36}
                    />

                    <div className="text-sm font-normal text-[#6D7175] w-full">
                        {filterValue ? filterList[filterValue - 1] : "Filter by"}
                    </div>

                    <IoIosArrowDown
                    onClick={() => setFilterState(!filterIsOpen)}
                    className={`cursor-pointer transition-transform ${
                    filterIsOpen ? "rotate-180" : "rotate-[360deg]"
                    }`}
                    size={36} />

                    {/* menu list */}
                    {filterIsOpen && participantList && participantList.length !== 0 ? (
                        <MenuList
                        sortList={sortUserList}
                        handleMenuClick={handleFilterMenuClick}
                        filterValue={filterValue} />
                    ) : null}
                </div>
            </div>

            {/* Table */}
            <ParticipantsTable
            isCritique={isCritique}
            searchedValue={filteredResult}
            participantsList={participantStateList} />
        </div>
    )
}

export default TableModalView